import { Product, ProductVariant } from '@commerce/types/product'
import { ChevronDown } from '@components/icons'
import { Button } from '@components/ui'
import ClickOutside from '@lib/click-outside/click-outside'
import { COMPONENTS } from '@lib/product'
import cn from 'classnames'
import { FC, Fragment, useEffect, useRef, useState } from 'react'
import s from './Addon.module.css'
import { SelectedVariantProps } from './AddonItem'

interface AddonItemOptionsProps {
  textTheme: string
  setVariant: React.Dispatch<React.SetStateAction<SelectedVariantProps | undefined>>
  selectedProduct: Product | undefined
  selectedVariant: SelectedVariantProps | undefined
  addon_unselected_cta: string
  handleAddToCart: any
  addToCartReady: any
  loading: boolean
  showSizeOptions: boolean
  preSelectVariantId?: string | null
  preSelectOption: boolean
}

const AddonItemSizeOnlyOptions: FC<AddonItemOptionsProps> = ({
  setVariant,
  selectedProduct,
  selectedVariant,
  textTheme,
  addon_unselected_cta,
  handleAddToCart,
  addToCartReady,
  loading,
  preSelectVariantId,
  showSizeOptions = true,
  preSelectOption,
}) => {
  const [variantSelectActive, setVariantSelectActive] = useState<boolean>(false)

  const [isVariantOptionsOverflowed, setIsVariantOptionsOverflowed] = useState(false)
  const variantOptionsRef = useRef<HTMLDivElement>(null)

  const handleVariantSelection = (variant: ProductVariant, quantity: number, displayLabel: string) => {
    setVariantSelectActive(false)
    setVariant({ variant, quantity, displayLabel })
  }

  const handleSelectSize = () => {
    if (selectedProduct) {
      setVariantSelectActive(!variantSelectActive)
    }
  }

  useEffect(() => {
    if (!selectedVariant && ((preSelectVariantId && preSelectOption) || !showSizeOptions)) {
      // pre select variant as per requested
      let variant = selectedProduct?.variants?.[0]
      if (preSelectVariantId && (preSelectOption || !showSizeOptions)) {
        variant = selectedProduct?.variants?.filter(
          (v) => v.id === `gid://shopify/ProductVariant/${preSelectVariantId}`
        )?.[0]
      }

      if (variant) {
        const sizeOption = variant?.options.filter((o) => o.displayName === 'size')?.[0]
        let optionLabel = sizeOption?.values?.[0]?.label || variant?.name || ''
        if (selectedProduct?.productType?.toLowerCase() === 'pillowcases') {
          optionLabel === 'Standard Size' ? (optionLabel = 'Standard Pillowcase') : ''
          optionLabel === 'King Size' ? (optionLabel = 'King Pillowcase') : ''
        }
        const multiplyBy =
          selectedProduct?.productType?.toLowerCase() === 'pillowcases' &&
          variant.name !== 'Standard Size / Single' &&
          variant.name !== 'King Size / Single'
            ? 2
            : 1
        const quantity = 1
        setVariant({
          variant,
          quantity: 1,
          displayLabel: `${optionLabel}${quantity && Number(quantity) * multiplyBy > 1 ? 's' : ''}`,
        })
      }
    }
  }, [preSelectVariantId, selectedVariant, selectedProduct, setVariant, showSizeOptions, preSelectOption])

  const observeOverflow = (element: HTMLElement, setIsOverflowed: (isOverflowed: boolean) => void) => {
    const checkOverflow = () => {
      const isElementOverflowed = element.scrollHeight > element.clientHeight
      setIsOverflowed(isElementOverflowed)
    }

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        checkOverflow()
      }
    })

    observer.observe(element)

    return () => {
      observer.unobserve(element)
    }
  }

  useEffect(() => {
    const variantOptionsElement = variantOptionsRef.current

    if (variantOptionsElement) {
      return observeOverflow(variantOptionsElement, setIsVariantOptionsOverflowed)
    }
  }, [])

  const sizeOptions = () => {
    return (
      <>
        {selectedProduct &&
          selectedProduct?.variants?.map((variant) => {
            if (!variant?.availableForSale) return <></>
            const label = `${variant?.name}`
            const staticConfig = COMPONENTS.filter((c) => c.type === selectedProduct?.productType?.toLowerCase())?.[0]
            const excludeList = staticConfig?.excludeQtyConversion || []
            const quantity = staticConfig?.qty || []
            const sizeOption = variant?.options.filter((o) => o.displayName === 'size')?.[0]
            let optionLabel = sizeOption?.values?.[0]?.label || variant?.name || ''
            if (selectedProduct?.productType?.toLowerCase() === 'pillowcases') {
              optionLabel === 'Standard Size' ? (optionLabel = 'Standard Pillowcase') : ''
              optionLabel === 'King Size' ? (optionLabel = 'King Pillowcase') : ''
            }
            const multiplyBy =
              selectedProduct?.productType?.toLowerCase() === 'pillowcases' &&
              variant.name !== 'Standard Size / Single' &&
              variant.name !== 'King Size / Single'
                ? 2
                : 1
            const displayLabel = (quantity: number) => {
              return `${optionLabel}${quantity && Number(quantity) * multiplyBy > 1 ? 's' : ''}`
            }

            return (
              <Fragment key={variant.id}>
                {quantity && Array.isArray(quantity) && quantity.length > 0 && !excludeList.includes(label) ? (
                  quantity.map((i) => {
                    return (
                      <button
                        key={`${variant.id}-${i}`}
                        onClick={() => handleVariantSelection(variant, i, displayLabel(i))}
                        className="p-8 hover:bg-white flex gap-5 items-center border-b border-lightestGrey"
                      >
                        <div className="body-small text-black text-left">{displayLabel(i)}</div>
                      </button>
                    )
                  })
                ) : (
                  <button
                    key={`${variant.id}-${displayLabel(1)}`}
                    onClick={() => handleVariantSelection(variant, 1, displayLabel(1))}
                    className="p-8 hover:bg-white flex gap-5 items-center border-b border-lightestGrey"
                  >
                    <div className="body-small text-black text-left">{displayLabel(1)}</div>
                  </button>
                )}
              </Fragment>
            )
          })}
      </>
    )
  }

  return (
    <div className="flex gap-8">
      {showSizeOptions && (
        <ClickOutside active={variantSelectActive} onClick={() => setVariantSelectActive(false)}>
          <div className={cn('flex flex-col flex-1 relative', { 'cursor-not-allowed': !selectedProduct })}>
            <button
              onClick={handleSelectSize}
              className={cn(
                'p-8  flex justify-between items-center border border-midGrey  cursor-pointer relative h-34',
                {
                  'bg-ecru cursor-pointer': variantSelectActive,
                  'cursor-not-allowed opacity-25': !selectedProduct,
                  'bg-ecru': selectedVariant && selectedVariant?.displayLabel,
                }
              )}
            >
              {selectedVariant && selectedVariant?.displayLabel ? (
                <div className="body-small text-black line-clamp-1 text-left">
                  {selectedVariant?.displayLabel?.trim()}
                </div>
              ) : (
                <div
                  className={cn('body-small ', {
                    'text-black': textTheme == 'dark' || variantSelectActive,
                    'text-white': textTheme == 'light' && !variantSelectActive,
                  })}
                >
                  Select size
                </div>
              )}
              <ChevronDown
                width={18}
                height={18}
                stroke={textTheme === 'light' && !selectedVariant?.displayLabel ? '#FFFFFF' : '#6A6A6A'}
              />
            </button>
            <div
              ref={variantOptionsRef}
              className={cn(
                'max-h-206 top-34 z-20 translate-y-18 absolute border border-t-0 border-midGrey bg-ecru w-full overflow-y-scroll',
                {
                  'flex flex-col': variantSelectActive,
                  hidden: !variantSelectActive,
                  'scrollbar-hide': !isVariantOptionsOverflowed,
                }
              )}
            >
              {sizeOptions()}
            </div>
          </div>
        </ClickOutside>
      )}
      <Button
        onClick={handleAddToCart}
        type="button"
        size="small"
        disabled={!addToCartReady}
        loading={loading}
        className={showSizeOptions ? s.sizeOnlybutton : s.button}
      >
        {addon_unselected_cta}
      </Button>
    </div>
  )
}

export default AddonItemSizeOnlyOptions
