import { CSSProperties, FC } from 'react'
import s from './Addon.module.css'

interface AddonLineItemErrorProps {
  error: string
  color: string
}

const AddonLineItemError: FC<AddonLineItemErrorProps> = ({ error, color }) => {
  return (
    <div
      className={s.addonLineItemError}
      style={{ '--addon-line-error-color': color || 'rgb(67, 66, 55)' } as CSSProperties}
    >
      {error}
    </div>
  )
}

export default AddonLineItemError
