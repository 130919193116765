const Location = ({ width = 20, height = 20, color = '#000', ...props }) => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 3.75c-2.206 0-4 1.682-4 3.75s1.794 3.75 4 3.75 4-1.682 4-3.75-1.794-3.75-4-3.75zM10 10c-1.47 0-2.667-1.121-2.667-2.5S8.53 5 10 5c1.47 0 2.667 1.121 2.667 2.5S11.47 10 10 10zm0-10C5.582 0 2 3.358 2 7.5c0 3.024 1.124 3.868 7.178 12.096.397.538 1.247.538 1.644 0C16.876 11.368 18 10.524 18 7.5 18 3.358 14.418 0 10 0zm0 18.513C4.196 10.644 3.333 10.019 3.333 7.5c0-1.67.694-3.239 1.953-4.42C6.546 1.9 8.219 1.25 10 1.25c1.78 0 3.455.65 4.714 1.83 1.26 1.181 1.953 2.75 1.953 4.42 0 2.52-.863 3.143-6.667 11.013z"
        fill={color}
      />
    </svg>
  )
}

export default Location
