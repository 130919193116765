import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { ReactNode } from 'react'

interface Props extends NextLinkProps {
  children: ReactNode
}

const Link: React.FC<Props> = ({ href, children, ...props }) => {
  return (
    <NextLink href={href} {...props} legacyBehavior>
      {children}
    </NextLink>
  );
}

export default Link
