const CartV2 = ({ color = '#000', ...props }) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
        <mask
          id="path-1-outside-1_8706_8702"
          maskUnits="userSpaceOnUse"
          x="0"
          y="1"
          width="20"
          height="20"
          fill="black"
        >
          <rect fill="white" y="1" width="20" height="18" />
          <path d="M18.2497 4.025H5.03999L4.77971 2.61943C4.71318 2.26028 4.40346 2 4.04256 2H1.375C1.16791 2 1 2.17 1 2.37969V2.63281C1 2.84249 1.16791 3.0125 1.375 3.0125H3.835L6.02021 14.8127C5.69702 15.1724 5.49999 15.6504 5.49999 16.175C5.49999 17.2933 6.39543 18.2 7.49999 18.2C8.60455 18.2 9.49999 17.2933 9.49999 16.175C9.50038 15.8194 9.40784 15.4701 9.23177 15.1625H13.7682C13.5922 15.4701 13.4996 15.8194 13.5 16.175C13.5 17.2933 14.3954 18.2 15.5 18.2C16.6046 18.2 17.5 17.2933 17.5 16.175C17.5 15.6057 17.2677 15.0916 16.8941 14.7237C16.8132 14.3997 16.5237 14.15 16.1665 14.15H6.91499L6.53999 12.125H16.8956C17.2509 12.125 17.5574 11.8726 17.6299 11.5205L18.984 4.93925C19.081 4.46768 18.7254 4.025 18.2497 4.025ZM8.49999 16.175C8.49999 16.7333 8.05139 17.1875 7.49999 17.1875C6.94858 17.1875 6.49999 16.7333 6.49999 16.175C6.49999 15.6167 6.94858 15.1625 7.49999 15.1625C8.05139 15.1625 8.49999 15.6167 8.49999 16.175ZM15.5 17.1875C14.9486 17.1875 14.5 16.7333 14.5 16.175C14.5 15.6167 14.9486 15.1625 15.5 15.1625C16.0514 15.1625 16.5 15.6167 16.5 16.175C16.5 16.7333 16.0514 17.1875 15.5 17.1875ZM16.6924 11.1125H6.35249L5.22749 5.0375H17.9423L16.6924 11.1125Z" />
        </mask>
        <path
          d="M18.2497 4.025H5.03999L4.77971 2.61943C4.71318 2.26028 4.40346 2 4.04256 2H1.375C1.16791 2 1 2.17 1 2.37969V2.63281C1 2.84249 1.16791 3.0125 1.375 3.0125H3.835L6.02021 14.8127C5.69702 15.1724 5.49999 15.6504 5.49999 16.175C5.49999 17.2933 6.39543 18.2 7.49999 18.2C8.60455 18.2 9.49999 17.2933 9.49999 16.175C9.50038 15.8194 9.40784 15.4701 9.23177 15.1625H13.7682C13.5922 15.4701 13.4996 15.8194 13.5 16.175C13.5 17.2933 14.3954 18.2 15.5 18.2C16.6046 18.2 17.5 17.2933 17.5 16.175C17.5 15.6057 17.2677 15.0916 16.8941 14.7237C16.8132 14.3997 16.5237 14.15 16.1665 14.15H6.91499L6.53999 12.125H16.8956C17.2509 12.125 17.5574 11.8726 17.6299 11.5205L18.984 4.93925C19.081 4.46768 18.7254 4.025 18.2497 4.025ZM8.49999 16.175C8.49999 16.7333 8.05139 17.1875 7.49999 17.1875C6.94858 17.1875 6.49999 16.7333 6.49999 16.175C6.49999 15.6167 6.94858 15.1625 7.49999 15.1625C8.05139 15.1625 8.49999 15.6167 8.49999 16.175ZM15.5 17.1875C14.9486 17.1875 14.5 16.7333 14.5 16.175C14.5 15.6167 14.9486 15.1625 15.5 15.1625C16.0514 15.1625 16.5 15.6167 16.5 16.175C16.5 16.7333 16.0514 17.1875 15.5 17.1875ZM16.6924 11.1125H6.35249L5.22749 5.0375H17.9423L16.6924 11.1125Z"
          fill={color}
        />
        <path
          d="M18.2497 4.025H5.03999L4.77971 2.61943C4.71318 2.26028 4.40346 2 4.04256 2H1.375C1.16791 2 1 2.17 1 2.37969V2.63281C1 2.84249 1.16791 3.0125 1.375 3.0125H3.835L6.02021 14.8127C5.69702 15.1724 5.49999 15.6504 5.49999 16.175C5.49999 17.2933 6.39543 18.2 7.49999 18.2C8.60455 18.2 9.49999 17.2933 9.49999 16.175C9.50038 15.8194 9.40784 15.4701 9.23177 15.1625H13.7682C13.5922 15.4701 13.4996 15.8194 13.5 16.175C13.5 17.2933 14.3954 18.2 15.5 18.2C16.6046 18.2 17.5 17.2933 17.5 16.175C17.5 15.6057 17.2677 15.0916 16.8941 14.7237C16.8132 14.3997 16.5237 14.15 16.1665 14.15H6.91499L6.53999 12.125H16.8956C17.2509 12.125 17.5574 11.8726 17.6299 11.5205L18.984 4.93925C19.081 4.46768 18.7254 4.025 18.2497 4.025ZM8.49999 16.175C8.49999 16.7333 8.05139 17.1875 7.49999 17.1875C6.94858 17.1875 6.49999 16.7333 6.49999 16.175C6.49999 15.6167 6.94858 15.1625 7.49999 15.1625C8.05139 15.1625 8.49999 15.6167 8.49999 16.175ZM15.5 17.1875C14.9486 17.1875 14.5 16.7333 14.5 16.175C14.5 15.6167 14.9486 15.1625 15.5 15.1625C16.0514 15.1625 16.5 15.6167 16.5 16.175C16.5 16.7333 16.0514 17.1875 15.5 17.1875ZM16.6924 11.1125H6.35249L5.22749 5.0375H17.9423L16.6924 11.1125Z"
          stroke={color}
          strokeWidth="0.5"
          mask="url(#path-1-outside-1_8706_8702)"
        />
      </svg>
    </>
  )
}

export default CartV2
