const Search = ({ color = '#000', ...props }) => {
  return (
    <>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_8538_19374)">
          <g clipPath="url(#clip1_8538_19374)">
            <path
              d="M13.5494 12.7844C14.5312 11.629 15.1248 10.1351 15.1248 8.5C15.1248 4.84034 12.1594 1.875 8.49976 1.875C4.8401 1.875 1.87476 4.84034 1.87476 8.5C1.87476 12.1597 4.8401 15.125 8.49976 15.125C10.1349 15.125 11.6288 14.5314 12.7841 13.5523V13.5938C12.7841 13.7284 12.8389 13.8525 12.9282 13.9458L12.9282 13.9458L12.9301 13.9478L16.9614 17.979C17.1571 18.1747 17.4737 18.1747 17.6694 17.979L17.9788 17.6696C18.1745 17.4739 18.1745 17.1573 17.9788 16.9616L13.9475 12.9304C13.8516 12.8344 13.7258 12.7844 13.5935 12.7844H13.5494ZM8.49976 13.875C5.52817 13.875 3.12476 11.4716 3.12476 8.5C3.12476 5.52841 5.52817 3.125 8.49976 3.125C11.4713 3.125 13.8748 5.52841 13.8748 8.5C13.8748 11.4716 11.4713 13.875 8.49976 13.875Z"
              fill={color}
              stroke={color}
              strokeWidth="0.25"
            />
          </g>
          <g clipPath="url(#clip2_8538_19374)">
            <path
              d="M13.5494 12.7844C14.5312 11.629 15.1248 10.1351 15.1248 8.5C15.1248 4.84034 12.1594 1.875 8.49976 1.875C4.8401 1.875 1.87476 4.84034 1.87476 8.5C1.87476 12.1597 4.8401 15.125 8.49976 15.125C10.1349 15.125 11.6288 14.5314 12.7841 13.5523V13.5938C12.7841 13.7284 12.8389 13.8525 12.9282 13.9458L12.9282 13.9458L12.9301 13.9478L16.9614 17.979C17.1571 18.1747 17.4737 18.1747 17.6694 17.979L17.9788 17.6696C18.1745 17.4739 18.1745 17.1573 17.9788 16.9616L13.9475 12.9304C13.8516 12.8344 13.7258 12.7844 13.5935 12.7844H13.5494ZM8.49976 13.875C5.52817 13.875 3.12476 11.4716 3.12476 8.5C3.12476 5.52841 5.52817 3.125 8.49976 3.125C11.4713 3.125 13.8748 5.52841 13.8748 8.5C13.8748 11.4716 11.4713 13.875 8.49976 13.875Z"
              fill={color}
              stroke={color}
              strokeWidth="0.25"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_8538_19374">
            <rect width="20" height="20" fill="white" />
          </clipPath>
          <clipPath id="clip1_8538_19374">
            <rect width="18" height="18" fill="white" transform="translate(1.00024 1)" />
          </clipPath>
          <clipPath id="clip2_8538_19374">
            <rect width="18" height="18" fill="white" transform="translate(1.00024 1)" />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}

export default Search
