import type { LineItem } from '@commerce/types/cart'
import { getCurrenceCodeByLocale } from '@lib/locale'
import { EcommerceData, Product } from '@lib/types/Elevar'
import { ElevarEvents, getUserPropertiesFromSessionStorage } from './elevarEvents'
import { getDataLayerProductIdByIds, getNumberPartFromId } from './general'
export const removeFromCartEvent = (
  lineItem: LineItem,
  position: number,
  locale?: string
) => {

  const products: Product[] =
    [{
      id: getDataLayerProductIdByIds(lineItem.productId, lineItem.variant?.id, locale),
      name: lineItem.name || "",
      brand: lineItem.range?.replace(/-/g, ' ')?.toUpperCase() || "",
      category: lineItem.type || "",
      variant: lineItem.variant?.name || "",
      price: lineItem.variant?.price?.toString() || "",
      quantity: lineItem.quantity?.toString() || "1",
      position: position,
      list: `/${locale}/collections/${lineItem.name?.split('-')?.[0]?.trim().toLowerCase().split(' ').join('-')}` || "",
      product_id: getNumberPartFromId(lineItem.productId),
      variant_id: getNumberPartFromId(lineItem.variant?.id),
      compare_at_price: lineItem.variant?.listPrice?.toString() || "0.0",
      image: lineItem.variant?.image?.url.replace(/https?:\/\//, '//') || "",
    }]
  const userProperties = getUserPropertiesFromSessionStorage()
  const ecommerceData: EcommerceData = {
    currencyCode: getCurrenceCodeByLocale(locale),
    remove: {
      actionField: {
        list: `/${locale}/collections/${lineItem.name?.split('-')?.[0]?.trim().toLowerCase().split(' ').join('-')}` || "",
      },
      products: products
    }
  }
  ElevarEvents('dl_remove_from_cart', userProperties, ecommerceData)
}