import { FC, useEffect, useState } from 'react'
import { Dispatch, SetStateAction } from 'react-instantsearch-core/node_modules/@types/react'

interface props {
  date: string
  text: string
  textColour: string
  setCountdownFinished: Dispatch<SetStateAction<boolean>>
}

const getCountDownString = (timeToEnd: number) => {
  const days = Math.floor(timeToEnd / (1000 * 60 * 60 * 24))
  const hours = Math.floor((timeToEnd % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((timeToEnd % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((timeToEnd % (1000 * 60)) / 1000)

  const minutesString = `${minutes < 10 ? `0${minutes}` : `${minutes}`}`
  const secondsString = `${seconds < 10 ? `0${seconds}` : `${seconds}`}`

  if (days >= 1) {
    if (minutes < 1) {
      return `${days > 1 ? `${days} days` : `${days} day`}${
        hours >= 1 ? (hours > 1 ? ` and ${hours} hours` : ` and ${hours} hour`) : ''
      }`
    }

    return `${days > 1 ? `${days} days` : `${days} day`}${
      hours >= 1 ? (hours > 1 ? `, ${hours} hours` : `, ${hours} hour`) : ''
    } and ${minutes} min`
  }

  if (days < 1 && hours >= 1) {
    return `${hours}:${minutesString}:${secondsString}`
  }

  if (hours < 1) return `${minutesString}:${secondsString}`
}

const CountdownTimer: FC<props> = ({ date, text, textColour, setCountdownFinished }) => {
  const [started, setStarted] = useState(false)
  const countDownDate = new Date(date).getTime()
  const now = new Date().getTime()
  const [timeToEnd, setTimeToEnd] = useState(countDownDate - now)
  const splitContent = text.split('[CD]')
  const [finalString, setFinalString] = useState(splitContent.join(getCountDownString(timeToEnd)))

  useEffect(() => {
    if (!started) {
      setFinalString(splitContent.join(getCountDownString(timeToEnd)))
      setStarted(true)
    }

    const interval = setInterval(() => {
      if (timeToEnd < 1) {
        clearInterval(interval)
        setCountdownFinished(true)
      } else {
        setTimeToEnd(countDownDate - now)
        setFinalString(splitContent.join(getCountDownString(timeToEnd)))
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [started, now, countDownDate, splitContent, timeToEnd, setCountdownFinished])

  return (
    <div
      className="text-center leading-20"
      style={{ color: textColour }}
      dangerouslySetInnerHTML={{ __html: finalString }}
    ></div>
  )
}

export default CountdownTimer