import Link from '@components/ui/Link'
import cn from 'classnames'
import { RANGE_ICON_PREFIX, RANGE_TAG_PREFIX } from 'lib/product'
import Image from "next/legacy/image"
import { useRouter } from 'next/router'
import { FC, useState } from 'react'

interface props {
  tags: string[]
  namedTags?: any
  range?: string | string[]
  link?: boolean
  className?: string
  iconHeight?: number
  iconWidth?: number
  showImage?: boolean
  textCase?: 'UpperCase' | 'Title'
}

// Note required any more
// const ALLOWED_ICONS = ['eden-cotton', 'eden-cotton-organic', 'eve-linen', 'leo-washed-cotton', 'eve-linen-recycled']

const ProductRange: FC<props> = ({
  tags,
  namedTags,
  range,
  link = false,
  className,
  iconHeight,
  iconWidth,
  showImage = true,
  textCase = 'UpperCase',
}) => {
  const [showImageState, setShowImageState] = useState(showImage)
  const trademarkSymbol = tags?.includes('symbol:tm')

  let productRange = ''
  if (range && Array.isArray(range)) {
    productRange = range[0]
  } else if (range) {
    productRange = range
  } else if (namedTags?.[RANGE_TAG_PREFIX.replace(/:/g, '')]) {
    productRange = namedTags[RANGE_TAG_PREFIX.replace(/:/g, '')]
  } else {
    productRange = tags?.filter((item) => item.startsWith(RANGE_TAG_PREFIX))?.[0]?.replace(RANGE_TAG_PREFIX, '') || ''
  }
  let icon = null
  if (range && Array.isArray(range)) {
    icon = range?.[0]?.toLowerCase()?.split(' ').join('-')
  } else if (range) {
    icon = range?.toLowerCase()?.split(' ').join('-')
  } else if (namedTags?.[RANGE_ICON_PREFIX.replace(/:/g, '')]) {
    icon = namedTags[RANGE_ICON_PREFIX.replace(/:/g, '')]
  } else {
    icon = tags?.filter((item) => item.startsWith(RANGE_ICON_PREFIX))?.[0]?.replace(RANGE_ICON_PREFIX, '') || ''
  }

  const handleImageLoadFailed = () => {
    setShowImageState(false)
  }

  const Range = (
    <div
      className={
        className ||
        cn('flex gap-8 body-bold text-black', { uppercase: textCase === 'UpperCase', capitalize: textCase === 'Title' })
      }
    >
      {!showImageState ? null : (
        <>
          {icon && (
            <Image src={`/range-icons/${icon}.svg`} alt="Range" height={iconHeight || 16} width={iconWidth || 16} onError={handleImageLoadFailed} />
          )}
        </>
      )}

      <div
        className={cn('', {
          'overflow-ellipsis overflow-hidden ...': productRange.replace(/-/g, ' ').length > 20,
        })}
      >
        {productRange.replace(/-/g, ' ')}<span>{(trademarkSymbol || namedTags?.symbol === 'tm') ? '™' : ''}</span>
      </div>
    </div>
  )

  if (link && range !== 'marloe-shearling') return <Link href={'/collections/' + range}>{Range}</Link>
  if (link && range === 'marloe-shearling') return <Link href={'/collections/limited-edition/' + range}>{Range}</Link>

  return Range
}

export default ProductRange
