import commerceConfig from '@commerce/config'
import { WYSIWYG } from '@components/ui'
import { useNavDataLayer } from '@lib/hooks/useNavDataLayer'
import { AccountAction, ChatAction, TopLevelNavigationItem } from '@lib/types/Navigation'
import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { RichTextBlock } from 'prismic-reactjs'
import { isEmpty } from 'ramda'
import { FC, Fragment } from 'react'
import MegaNavigation from './MegaNavigation'
interface Props {
  navItems?: TopLevelNavigationItem[]
  action?: AccountAction | ChatAction
  navigationCondensed?: boolean
  navElementColour?: string
  navigationHovered?: boolean
  className?: string
  navProps: {
    activeMegaTitle: string
    subMenuHoverIndex: number
    handleActiveSubNav: (a: number) => void
    handleActiveTitle: (nav_title: string) => void
    handleSubNavOut: (activeIndex: number) => void
    closeNavigation: (nav_title: string) => void
  }
}

const NavigationComponent: FC<Props> = ({
  navItems,
  navigationCondensed,
  navElementColour,
  navigationHovered,
  navProps,
  className,
}) => {
  const {
    config: { defaultAlt },
  } = commerceConfig
  const { pathname } = useRouter()
  const { updateNavDataLayer } = useNavDataLayer()
  const {
    activeMegaTitle,
    subMenuHoverIndex,
    handleActiveSubNav,
    handleActiveTitle,
    handleSubNavOut,
    closeNavigation,
  } = navProps
  const onHomePage = pathname === '/'
  const rootVariants = {
    open: {
      opacity: 1,
    },
    closed: {
      opacity: 0,
    },
  }
  return (
    <div className="col-span-1 flex items-end">
      <ul className={cn(`${className} flex items-end`)}>
        {navItems &&
          navItems?.map((navItem, index) => {
            const {
              title,
              link,
              highlight_title,
              highlight_colour,
              show_as_dropdown,
              dropdown_cta_title,
              dropdown_cta_image,
              dropdown_cta_text,
              body,
              sub_navigation,
            } = navItem
            const currentMegaHover = activeMegaTitle === title
            const key = `navItem-${title}-${index}`
            const isEmptyText = (text: RichTextBlock[] | null) => {
              const richTextContent = text && text[0]
              if (!richTextContent) return false
              if (
                typeof richTextContent !== 'object' ||
                richTextContent.type !== 'paragraph' ||
                richTextContent.text !== '' ||
                !Array.isArray(richTextContent.spans)
              ) {
                return false
              }
              return true
            }
            const isEmptyImage = isEmpty(dropdown_cta_image)
            const emptyDropDownContent = isEmptyText(dropdown_cta_text) && isEmptyImage && !dropdown_cta_title
            return (
              <Fragment key={key}>
                {show_as_dropdown ? (
                  <li
                    onMouseOver={() => handleActiveTitle(title!)}
                    onMouseLeave={() => closeNavigation(title!)}
                    style={
                      {
                        '--highlight-colour': highlight_title ? highlight_colour : '#6c704a',
                      } as React.CSSProperties
                    }
                    className={cn(`relative flex justify-center items-end`, {
                      active: currentMegaHover,
                    })}
                  >
                    <Link href={link || '/'} passHref>
                      <div
                        onClick={() => updateNavDataLayer(title!, title!, 'Desktop Menu', link!)}
                        style={{
                          color: onHomePage
                            ? navigationCondensed || navigationHovered
                              ? highlight_colour!
                              : '#FFFFFF'
                            : highlight_colour!,
                          backgroundColor: onHomePage
                            ? navigationCondensed || navigationHovered
                              ? ''
                              : highlight_colour!
                            : '',
                          borderRadius: '20px',
                        }}
                        className="font-g-bold text-12 leading-16 font-bold tracking-spacingBitWider pt-2 pb-3 px-12 mb-16 whitespace-nowrap uppercase"
                      >
                        {title?.split('/').map((titleString, index) => {
                          const key = `title_${index}_${titleString}`

                          return <Fragment key={key}>{titleString}</Fragment>
                        })}
                      </div>
                    </Link>
                    <AnimatePresence>
                      {activeMegaTitle === title && !emptyDropDownContent && (
                        <motion.div
                          variants={rootVariants}
                          initial={'closed'}
                          animate={'open'}
                          exit={'closed'}
                          transition={{ opacity: { duration: 0.2, ease: 'easeIn' } }}
                          className="w-200 min-h-204 absolute z-10 pt-24 px-20 pb-20 bg-white rounded-b-16 top-36 shadow-magical"
                        >
                          <div className="w-160 h-160 rounded-16 relative">
                            {dropdown_cta_image && dropdown_cta_image.url && (
                              <Image
                                src={dropdown_cta_image.url}
                                fill
                                alt={dropdown_cta_image.alt || defaultAlt}
                                placeholder="blur"
                                blurDataURL={`${dropdown_cta_image.url}&blur=500`}
                                sizes="100vw, 100vh"
                                style={{ objectFit: 'cover' }}
                                priority
                              />
                            )}
                            <div className="absolute text-white top-10 w-136 break-words left-12 font-g-medium text-16 leading-18 font-normal tracking-spacingBitTigher">
                              {dropdown_cta_title}
                            </div>
                          </div>
                          {dropdown_cta_text && !isEmptyText(dropdown_cta_text) && (
                            <div className=" text-almostBlack body-small-medium mt-12">
                              <WYSIWYG content={dropdown_cta_text} />
                            </div>
                          )}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </li>
                ) : (
                  <li
                    onMouseOver={() => handleActiveTitle(title!)}
                    onMouseLeave={() => closeNavigation(title!)}
                    style={
                      { '--highlight-colour': highlight_title ? highlight_colour : '#6c704a' } as React.CSSProperties
                    }
                  >
                    <Link href={link || '/'} passHref>
                      <div
                        style={{
                          color: onHomePage
                            ? navigationCondensed || navigationHovered
                              ? highlight_colour!
                              : '#FFFFFF'
                            : highlight_colour!,
                          backgroundColor: onHomePage
                            ? navigationCondensed || navigationHovered
                              ? ''
                              : highlight_colour!
                            : '',
                          borderRadius: '20px',
                        }}
                        onClick={() => updateNavDataLayer(title!, title!, 'Desktop Menu', link!)}
                        className={cn(
                          `font-g-bold text-12 leading-16 font-bold tracking-spacingBitWider normal relative navItem whitespace-nowrap uppercase`,
                          {
                            active: currentMegaHover,
                          }
                        )}
                      >
                        {title?.split('/').map((titleString, index) => {
                          const key = `title_${index}_${titleString}`

                          return <Fragment key={key}>{titleString}</Fragment>
                        })}
                      </div>
                    </Link>
                    <AnimatePresence>
                      {activeMegaTitle === title && (
                        <motion.div
                          variants={rootVariants}
                          initial={'closed'}
                          animate={'open'}
                          exit={'closed'}
                          transition={{ opacity: { duration: 0.2, ease: 'easeIn' } }}
                          className="absolute bg-ecru left-0 w-full z-10"
                        >
                          <MegaNavigation
                            navItems={body}
                            activeMegaTitle={activeMegaTitle}
                            subNavigation={sub_navigation}
                            subMenuHoverIndex={subMenuHoverIndex}
                            handleSubNavOut={handleSubNavOut}
                            handleActiveSubNav={handleActiveSubNav}
                          />
                        </motion.div>
                      )}
                    </AnimatePresence>
                    <div
                      className={`megaNavBg absolute bg-ecru ${
                        currentMegaHover && !show_as_dropdown ? 'megaNavBgShow' : ''
                      }`}
                    />
                  </li>
                )}
              </Fragment>
            )
          })}
      </ul>
      <style jsx>
        {`
          .megaNavBg {
            height: 256px;
            width: 100%;
            top: ${navigationCondensed ? '60px' : '78px'};
            left: 0;
            z-index: 0;
            display: none;
          }
          .megaNavBgShow {
            display: block;
          }
          .normal {
            color: ${navElementColour};
            padding: 2px 12px 3px 12px;
            border-radius: 20px;
            margin-bottom: 16px;
          }
          .navLeft li:first-child .navItem {
            margin-left: -12px;
          }
          .navLeft .active:after,
          .navLeft li .active:after {
            z-index: 15;
            position: absolute;
            bottom: 0px;
            content: '';
            height: 2px;
            width: calc(100% - 24px);
            background-color: var(--highlight-colour);
            display: block;
          }
          .navLeft li .active:after {
            z-index: 15;
            position: absolute;
            bottom: -18px;
            content: '';
            height: 2px;
            width: calc(100% - 24px);
            background-color: var(--highlight-colour);
            display: block;
          }
          .navLeft li .active {
            color: var(--highlight-colour);
          }
          .navLeft li:first-child .active:after {
            width: calc(100% - 24px);
            left: 12;
          }
        `}
      </style>
    </div>
  )
}

export default NavigationComponent
