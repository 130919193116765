import commerceConfig from '@commerce/config'
import { Button } from '@components/ui'
import { useNavDataLayer } from '@lib/hooks/useNavDataLayer'
import Image from 'next/image'
import Link from 'next/link'
import { FC } from 'react'
interface Props {
  navItem: any
  slice_type: string
  activeMegaTitle: string
  subNavTitle?: string | null
  groupTitle: string | null
}

const MobileNavItem: FC<Props> = ({ navItem, slice_type, subNavTitle, activeMegaTitle, groupTitle }) => {
  const {
    config: { defaultAlt },
  } = commerceConfig
  const { updateNavDataLayer } = useNavDataLayer()
  return (
    <>
      {slice_type === 'small_tiles_group' && (
        <>
          {navItem?.image && navItem?.image?.url && (
            <>
              {navItem?.link && (
                <div className="smallTile">
                  <Link passHref href={navItem.link}>
                    <div
                      onClick={() =>
                        updateNavDataLayer(
                          navItem.title,
                          groupTitle!,
                          `${activeMegaTitle}${subNavTitle ? ` - ${subNavTitle}` : ''}`,
                          navItem.link
                        )
                      }
                      className="rounded-16 relative overflow-hidden w-full h-full px-12 py-10"
                    >
                      <Image
                        src={navItem.image.url}
                        fill
                        alt={navItem.image.alt || defaultAlt}
                        placeholder="blur"
                        sizes="100vw,100vh"
                        blurDataURL={`${navItem.image.url}&blur=500`}
                        style={{ objectFit: 'cover' }}
                        priority
                      />
                      {navItem?.title && (
                        <div className="relative w-full text-white break-words font-g-medium font-normal text-16 leading-18 tracking-spacingBitTigher">
                          {navItem.title}
                        </div>
                      )}
                    </div>
                  </Link>
                </div>
              )}
            </>
          )}
        </>
      )}
      {slice_type === 'medium_tiles_group' && (
        <>
          {navItem?.image && navItem?.image?.url && (
            <>
              {navItem?.link && (
                <div className="mediumTile">
                  <Link passHref href={navItem.link}>
                    <div
                      onClick={() =>
                        updateNavDataLayer(
                          navItem.title,
                          groupTitle!,
                          `${activeMegaTitle}${subNavTitle ? ` - ${subNavTitle}` : ''}`,
                          navItem.link
                        )
                      }
                      className="rounded-16 relative overflow-hidden w-full h-full px-12 py-10"
                    >
                      <Image
                        src={navItem.image.url}
                        fill
                        alt={navItem.image.alt || defaultAlt}
                        placeholder="blur"
                        blurDataURL={`${navItem.image.url}&blur=500`}
                        sizes="100vw,100vh"
                        style={{ objectFit: 'cover' }}
                        priority
                      />
                      {navItem?.title && (
                        <div className="relative w-full text-white break-words font-g-medium font-normal text-16 leading-18 tracking-spacingBitTigher">
                          {navItem.title}
                        </div>
                      )}
                    </div>
                  </Link>
                </div>
              )}
            </>
          )}
        </>
      )}
      {slice_type === 'text_links_group' && (
        <div
          onClick={() =>
            updateNavDataLayer(
              navItem.title,
              groupTitle!,
              `${activeMegaTitle}${subNavTitle ? ` - ${subNavTitle}` : ''}`,
              navItem.link
            )
          }
          className="pt-6 pb-8 textLink"
        >
          {navItem?.link && (
            <Link href={navItem.link} passHref>
              <div className="flex gap-6 items-center">
                {navItem.icon && navItem.icon.url && (
                  <div className="w-13 h-13 relative">
                    <Image
                      fill
                      src={navItem.icon?.url}
                      alt={navItem.icon?.alt || defaultAlt}
                      placeholder="blur"
                      blurDataURL={`${navItem.icon.url}&blur=500`}
                      sizes="100vw,100vh"
                      style={{ objectFit: 'cover' }}
                    />
                  </div>
                )}
                <div className="font-g-medium text-16 leading-24 text-black tracking-spacingBitTigher font-normal">
                  {navItem.title}
                </div>
              </div>
            </Link>
          )}
        </div>
      )}
      {slice_type === 'button_links_group' && (
        <Button
          size="small"
          variant="secondary"
          onClick={() =>
            updateNavDataLayer(
              navItem.title,
              groupTitle!,
              `${activeMegaTitle}${subNavTitle ? ` - ${subNavTitle}` : ''}`,
              navItem.link
            )
          }
          className="whitespace-nowrap"
        >
          {navItem?.link && (
            <Link href={navItem.link} passHref>
              {navItem.title}
            </Link>
          )}
        </Button>
      )}
      {slice_type === 'mobile_featured_tiles_group' && (
        <>
          {navItem?.image && navItem?.image?.url && (
            <>
              {navItem?.link_url && (
                <div
                  onClick={() =>
                    updateNavDataLayer(
                      navItem.link_title ? navItem.link_title : 'Featured Tile',
                      groupTitle ? groupTitle : `Featured Tiles`,
                      `${activeMegaTitle} - Featured Tiles`,
                      navItem.link_url
                    )
                  }
                  className="mediumTile"
                >
                  <Link passHref href={navItem.link_url}>
                    <div className="rounded-16 relative overflow-hidden w-full h-full px-12 py-10">
                      <Image
                        src={navItem.image.url}
                        fill
                        alt={navItem.image.alt || defaultAlt}
                        placeholder="blur"
                        blurDataURL={`${navItem.image.url}&blur=500`}
                        sizes="100vw,100vh"
                        style={{ objectFit: 'cover' }}
                        priority
                      />
                      {navItem?.link_title && (
                        <div className="relative w-full text-white break-words font-g-medium font-normal text-16 leading-18 tracking-spacingBitTigher">
                          {navItem.link_title}
                        </div>
                      )}
                    </div>
                  </Link>
                </div>
              )}
            </>
          )}
        </>
      )}
      <style jsx>
        {`
          .smallTile {
            flex: 0 0 calc(50% - 6px);
            aspect-ratio: 16 / 10;
            z-index: 0;
            position:relative:
          }
          .mediumTile {
            flex: 0 0 calc(50% - 6px);
            aspect-ratio: 1/ 1;
            z-index: 0;
            position:relative:
          }
          .textLink {
            flex: 0 0 calc(50% - 6px);
            z-index: 0;
          }
        `}
      </style>
    </>
  )
}

export default MobileNavItem
