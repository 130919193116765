import cn from 'classnames'
import { FC } from 'react'
import s from './SpendAndSave.module.css'

interface SpendAndSaveMessageProps {
  message: string
  heading_text_colour?: string
}

export const SpendAndSaveMessage: FC<SpendAndSaveMessageProps> = ({ message, heading_text_colour }) => {
  return (
    <div className={cn(s.message)} style={{ color: heading_text_colour || '#000000' }}>
      <div dangerouslySetInnerHTML={{ __html: message }}></div>
    </div>
  )
}
