import { getProduct } from '@commerce/api/operations'
import { Cart, LineItem } from '@commerce/types/cart'
import { Product } from '@commerce/types/product'
import { Addon } from '@lib/types/MiniCart'
export const addonProperty = '_addon'

/**
 * The Singleton class defines the `getInstance` method that lets clients access
 * the unique singleton instance.
 */
class AddonUtils {
  private static instance: AddonUtils
  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() { }

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): AddonUtils {
    if (!AddonUtils.instance) {
      AddonUtils.instance = new AddonUtils()
    }

    return AddonUtils.instance
  }

  // public getSelectedVariant(addon: Addon): ProductVariant | undefined {
  //   let selectedVariant = addon.products?.[0]?.variants[0]
  //   if (addon.products?.length === 1) {
  //     return selectedVariant
  //   }
  //   if (addon?.selectedVariant) {
  //     return addon.selectedVariant
  //   }
  //   return undefined
  // }
}

// export const getSelectedVariant: (addon: Addon) => ProductVariant | undefined = (addon) => {
//   let selectedVariant = addon.products?.[0]?.variants[0]
//   if (addon.products?.length === 1) {
//     return selectedVariant
//   }
//   if (addon?.selectedVariant) {
//     return addon.selectedVariant
//   }
//   return undefined
// }

// Check if the addon product, regardless of the variant, has already been added to the cart independenlty if it was added as an addon or manually
export const getAddonSimilarProductAlreadyInCart: (addon: Addon, cart: Cart) => LineItem | null = (addon, cart) => {
  const isAlreadyInCart = cart?.lineItems.find((lineItem) => {
    let hasAddonProperty = false
    if (lineItem.customAttributes && lineItem.customAttributes?.length > 0) {
      hasAddonProperty = lineItem.customAttributes.find((attribute) => {
        return attribute.key === addonProperty && attribute.value === addon.order_tracking_code
      })
        ? true
        : false
    }
    if (addon.products?.find((product) => product.handle === lineItem.path) && !hasAddonProperty) {
      return true
    }
    return false
  })
  return isAlreadyInCart || null
}

export const isAddonItem: (lineItem: LineItem) => boolean = (lineItem) => {
  let isAddon = false
  if (lineItem.customAttributes) {
    const hasAddonProperty = lineItem.customAttributes.find((attribute) => {
      return attribute.key === addonProperty
    })
    isAddon = hasAddonProperty ? true : false
  }
  return isAddon
}

export const getAddonCartLineItem: (addon: Addon, cart: Cart | null) => LineItem | null = (addon, cart) => {
  const isAlreadyInCart = cart?.lineItems.find((lineItem) => {
    if (lineItem.customAttributes && lineItem.customAttributes?.length > 0) {
      const hasAddonProperty = lineItem.customAttributes.find((attribute) => {
        return attribute.key === addonProperty && attribute.value === addon.order_tracking_code
      })
      if (
        hasAddonProperty &&
        addon.products?.find((product) => product.variants.find((variant) => variant.id === lineItem.variantId))
      ) {
        return true
      }
    }
    return false
  })
  return isAlreadyInCart || null
}

export const isAddonDismissed: (addon: Addon, locale: string | null) => boolean = (addon, locale) => {
  const addonDismissSeesionkey = `addon_${btoa(`${locale}_${addon.index}_${addon.order_tracking_code}`)}_dismiss`
  const isAddonDismissed = window.sessionStorage.getItem(addonDismissSeesionkey)
  if (isAddonDismissed) {
    return JSON.parse(isAddonDismissed)
  }
  return false
}

export const getAddonProduct = async (slug: string, locale: string, addonsInventoryLocations: string) => {
  const data = await getProduct(locale, slug)
  let addon_product: Product = data.product as Product
  // Note: addonsInvetory setting is removed from prismic
  // if (data.product && addonsInventoryLocations !== '') {
  //   try {
  //     const addonsInventoryLocationsArray = JSON.parse(addonsInventoryLocations)
  //     if (addonsInventoryLocationsArray.length > 0) {
  //       addon_product = {
  //         ...addon_product,
  //         variants: addon_product.variants.map((variant) => {
  //           return {
  //             ...variant,
  //             // Note: not sure why there is seperate check
  //             // availableForSale: normalizeAvailability(
  //             //   variant.availableForSale || false,
  //             //   variant.storeAvailability,
  //             //   addonsInventoryLocationsArray
  //             // ),
  //           }
  //         }),
  //       }
  //     }
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }
  return addon_product
}

export default AddonUtils.getInstance()
