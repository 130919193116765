import { CSSProperties, FC } from 'react'
import s from './Addon.module.css'

interface AddonErrorProps {
  error: string
  textColour: string
  bgColour: string
}

const AddonError: FC<AddonErrorProps> = ({ error, textColour, bgColour }) => {
  return (
    <div
      className={s.addonError}
      style={
        {
          '--addon-error-text-color': textColour || 'rgb(0, 0, 0)',
          '--addon-error-bg-color': bgColour || 'rgb(242, 235, 223)',
        } as CSSProperties
      }
    >
      {error}
    </div>
  )
}

export default AddonError
