import commerceConfig from '@commerce/config'
import { Logo, StickyLogo } from '@components/icons'
import { useUI } from '@components/ui'
import ClickOutside from '@lib/click-outside'
import { useNavigation } from '@lib/hooks/useNavigation'
import { AccountAction, ChatAction, MainNavigationData } from '@lib/types/Navigation'
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import cn from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, useEffect, useRef, useState } from 'react'
import SearchBar from '../SearchBar'
import NavigationComponent from './NavigationComponent'
import UserNavigation from './UserNavigation'

interface Props {
  navData: MainNavigationData
}

const NavigationV2: FC<Props> = ({ navData }) => {
  const defaultDSBTopPosition = 78
  const defautlScrolledSBTopPosition = 60

  const { locale, events } = useRouter()

  const [showSearchBar, setShowSearchBar] = useState(false)
  const [searchIndex, setSearchIndex] = useState<string>('')
  const [desktopSearchBarTopPosition, setDesktopSearchBarTopPosition] = useState(defaultDSBTopPosition)

  const ref = useRef() as React.MutableRefObject<HTMLDivElement>

  const { top_level_navigation, account_action, chat_action } = navData || {}
  const { openSearch, closeSearch, arPopupView } = useUI()
  const {
    config: { defaultAlt },
  } = commerceConfig
  const {
    navigationCondensed,
    navigationHovered,
    handleNavigationHover,
    handleNavigationHoverOut,
    navBarHeight,
    anBarHeight,
    navBarBackgroundColour,
    navElementColour,
    activeMegaTitle,
    subMenuHoverIndex,
    setSubMenuHoverIndex,
    setActiveMegaTitle,
    handleActiveTitle,
    handleActiveSubNav,
    handleSubNavOut,
    closeNavigation,
  } = useNavigation()

  useEffect(() => {
    const handleRouteChange = () => {
      setActiveMegaTitle('')
      setSubMenuHoverIndex(0)
    }
    events.on('routeChangeStart', handleRouteChange)
    return () => {
      events.off('routeChangeStart', handleRouteChange)
    }
  }, [events, setActiveMegaTitle, setSubMenuHoverIndex])

  const handleShowSearchBar = () => {
    setSearchIndex('Search')
    handleActiveTitle('Search')
    if (navigationCondensed) {
      const navBar: Element = document.getElementsByClassName('navigationHeader')?.[0]
      const navBarClientTop: number = (navBar && navBar.getBoundingClientRect()?.top) || 0
      setDesktopSearchBarTopPosition(defautlScrolledSBTopPosition + navBarClientTop)
    }
    if (!navigationCondensed) {
      setDesktopSearchBarTopPosition(defaultDSBTopPosition)
    }
    setShowSearchBar(true)
    openSearch()
    const sidebar = ref.current
    disableBodyScroll(sidebar, { reserveScrollBarGap: true })
    window.setTimeout(function () {
      document?.getElementById('searchBoxDesktopId')?.focus()
    }, 100)
  }
  const handleCloseSearchBar = () => {
    closeNavigation(searchIndex)
    const sidebar = ref.current
    if (showSearchBar && sidebar) {
      enableBodyScroll(sidebar)
      clearAllBodyScrollLocks()
      setShowSearchBar(false)
      closeSearch()
    }
  }
  return (
    <>
      <div
        onMouseOver={handleNavigationHover}
        onMouseLeave={handleNavigationHoverOut}
        className={cn(`navigationHeader hidden laptop:block sticky top-0 w-full transition-all duration-200 ease-in`, {
          'z-50': !arPopupView,
        })}
      >
        <div className="hidden laptop:wrapper laptop:grid w-full h-full grid-cols-3">
          <NavigationComponent
            navItems={top_level_navigation}
            navigationCondensed={navigationCondensed}
            navElementColour={navElementColour}
            navigationHovered={navigationHovered}
            className="navLeft"
            navProps={{
              activeMegaTitle,
              subMenuHoverIndex,
              handleActiveSubNav,
              handleActiveTitle,
              handleSubNavOut,
              closeNavigation,
            }}
          />
          <div className="flex justify-center items-center">
            {!navigationCondensed ? (
              <Link aria-label={`${defaultAlt}`} href={`/${locale || ''}`} passHref>
                <div className="flex col-span-1 justify-center relative top-2">
                  <Logo color={navElementColour} />
                </div>
              </Link>
            ) : (
              <Link aria-label={`${defaultAlt}`} href={`/${locale || ''}`} passHref>
                <div className="flex col-span-1 justify-center">
                  <StickyLogo type="desktop" />
                </div>
              </Link>
            )}
          </div>
          <UserNavigation
            handleShowSearchBar={handleShowSearchBar}
            handleActiveTitle={handleActiveTitle}
            navigationCondensed={navigationCondensed}
            activeMegaTitle={activeMegaTitle}
            accountAction={account_action as AccountAction}
            chatAction={chat_action as ChatAction}
            navElementColour={navElementColour}
          />
        </div>
        <div
          className={cn('searchContainer searchContainerDesktop inset-0 w-full h-screen', {
            searchContainerShow: showSearchBar,
            'fixed ': navigationCondensed,
            'absolute ': !navigationCondensed,
          })}
          style={{ top: `${desktopSearchBarTopPosition}px` }}
          ref={ref}
        >
          <ClickOutside active={showSearchBar} onClick={handleCloseSearchBar}>
            <div className="bg-lightBg" onMouseOver={() => handleActiveTitle('Search')}>
              {showSearchBar && (
                <SearchBar
                  handleCloseSearchBar={handleCloseSearchBar}
                  showSearchBar={showSearchBar}
                  iconType="desktop"
                />
              )}
            </div>
          </ClickOutside>
          <div className="bg-black bg-opacity-50 h-full w-full overlay"></div>
        </div>
      </div>

      <style jsx>
        {`
          .navigationHeader {
            height: ${navBarHeight};
            background-color: ${navBarBackgroundColour};
          }
          .navigationContainer {
            height: ${navBarHeight};
          }
          .searchContainer {
            z-index: 40;
            visibility: hidden;
            opacity: 0;
            transition: all 0.3s ease-in-out;
          }
          .searchContainerShow {
            visibility: visible;
            opacity: 1;
          }
        `}
      </style>
    </>
  )
}
export default NavigationV2
