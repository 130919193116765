import { RedCross, RightCaret } from '@components/icons'
import { Button } from '@components/ui'
import { newsletterSubscriptionComplete } from '@lib/events/newsletterSubscriptionComplete'
import { linkResolver } from '@lib/prismic'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import { FC, useState } from 'react'

export interface NewsletterFormContent {
  newsletter_popup_heading?: string
  newsletter_popup_supporting_text?: RichTextBlock[]
  newsletter_popup_invalid_email_message?: string
  newsletter_popup_general_error_message?: string
  newsletter_popup_button_text_mobile?: string
  newsletter_popup_button_text_desktop?: string
  newsletter_popup_close?: string
  newsletter_popup_successful_heading?: string
  newsletter_popup_successful_supporting_text?: RichTextBlock[]
  newsletter_popup_successful_cta_text?: string
}
interface props {
  onClose: () => void
  onSuccess: () => void
  listID: string
  content?: NewsletterFormContent
}

const NewsletterPopupForm: FC<props> = ({ onClose, onSuccess, listID, content }) => {
  const { locale } = useRouter()
  const [email, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(true)
  const [success, setSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)

  const handleOnChange = (e: React.SyntheticEvent<EventTarget>) => {
    const value = (e.target as HTMLInputElement).value
    setEmail(value)
  }

  const handleValidation = () => {
    if (email && email.length > 0) {
      if (!email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
        setValidEmail(false)
      } else {
        setValidEmail(true)
      }
    }
  }

  const handleSubmit = async () => {
    if (email && email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
      setValidEmail(true)
      try {
        const response = await fetch('/api/klaviyo/subscribe', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, listID: listID, locale, source: 'popup' }),
        })
        const result = await response.json()
        if (result === 1) {
          setSuccess(true)
          newsletterSubscriptionComplete(email)
          onSuccess()
        } else {
          setErrorMessage(true)
        }
      } catch (err) {
        console.error(err)
      }
    } else {
      setValidEmail(false)
    }
  }

  return (
    <div className="bg-sage rounded-t-16" onMouseMove={handleValidation}>
      <div className="wrapper pt-24 laptop:pb-28 pb-12">
        {!success && (
          <>
            <div className="flex justify-center text-center">
              {content?.newsletter_popup_heading !== '' && (
                <h5 className="heading-5 text-white">{content?.newsletter_popup_heading}</h5>
              )}
            </div>
            <div className="mt-20 laptop:mt-32 flex tablet:justify-center gap-8">
              <div className="relative flex-grow tablet:flex-grow-0">
                <input
                  className={cn(
                    'bg-sageLight h-48 rounded-2 px-16 bg-transparent focus:outline-none body text-black min-w-200 w-full tablet:w-auto tablet:min-w-400',
                    {
                      'border border-errorRed': !validEmail && email,
                      'py-12': email.length === 0,
                      'pt-19 pb-5': email.length > 0,
                    }
                  )}
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleOnChange}
                />

                {!errorMessage &&
                  (validEmail || email.length === 0) &&
                  content?.newsletter_popup_supporting_text &&
                  content.newsletter_popup_supporting_text?.length > 0 && (
                    <div className="body text-white mt-14">
                      <RichText render={content.newsletter_popup_supporting_text} linkResolver={linkResolver} />
                    </div>
                  )}
                {email.length > 0 && !validEmail && (
                  <>
                    <RedCross className="absolute top-15 right-15" />
                    <div className="body mt-14">
                      {content?.newsletter_popup_invalid_email_message || 'Enter a valid email address'}
                    </div>
                  </>
                )}
                {email.length > 0 && <div className="absolute left-16 top-3 text-charcoal">Email</div>}
                {errorMessage && (
                  <div className="body mt-4">
                    {content?.newsletter_popup_general_error_message || 'Some error occurred. Please try again later.'}
                  </div>
                )}
              </div>
              <div className="flex flex-col laptop:flex-row laptop:gap-14 laptop:gap-24">
                <div className="laptop:hidden">
                  <Button
                    variant={validEmail ? 'primary' : 'login'}
                    disabled={!validEmail}
                    onClick={handleSubmit}
                    className="min-w-120"
                    width="100%"
                  >
                    {content?.newsletter_popup_button_text_mobile || 'Sign Up'}
                  </Button>
                </div>
                <div className="hidden laptop:block">
                  <Button
                    variant={validEmail ? 'primary' : 'login'}
                    disabled={!validEmail}
                    width="189px"
                    onClick={handleSubmit}
                  >
                    {content?.newsletter_popup_button_text_desktop || 'Sign Up'}
                  </Button>
                </div>
                <button
                  className="h-48 body-link text-white flex items-center gap-6 self-center ml-6 laptop:ml-0 laptop:self-auto"
                  onClick={onClose}
                >
                  {content?.newsletter_popup_close || 'Maybe later'}
                  <RightCaret />
                </button>
              </div>
            </div>
          </>
        )}
        {success && (
          <>
            <div className="flex flex-col justify-center text-center">
              <h5 className="heading-5 text-white">
                {content?.newsletter_popup_successful_heading || 'Perfect! Check your inbox for unique discount code.'}
              </h5>
              {content?.newsletter_popup_successful_supporting_text &&
                content.newsletter_popup_successful_supporting_text?.length > 0 && (
                  <div className="flex justify-center laptop:gap-4 gap-8">
                    <div className="relative">
                      <div className="body text-white mt-14">
                        <RichText
                          render={content.newsletter_popup_successful_supporting_text}
                          linkResolver={linkResolver}
                        />
                      </div>
                    </div>
                  </div>
                )}
            </div>
            <div className="mt-20 laptop:mt-32 flex justify-center">
              <Button variant="primary" onClick={onClose}>
                {content?.newsletter_popup_successful_cta_text || 'Continue Shopping'}
              </Button>
            </div>
          </>
        )}
      </div>

      <style jsx>
        {`
          ::placeholder {
            color: #6a6a6a;
          }
        `}
      </style>
    </div>
  )
}

export default NewsletterPopupForm
