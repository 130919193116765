const Share = ({ ...props }) => {
  return (
    <svg width="14.6" height="19.3" viewBox="0 0 14.6 19.3" fill="black" xmlns="http://www.w3.org/2000/svg" {...props}>
      <polygon points="6.7,2.1 6.7,12.5 7.8,12.5 7.8,2.1 10.2,4.5 11,3.8 7.3,0 3.5,3.8 4.3,4.5 	" />
      <path d="M12.9,5.8H9v1.1h3.9c0.3,0,0.6,0.2,0.6,0.6v10.1c0,0.3-0.2,0.6-0.6,0.6H1.7c-0.3,0-0.6-0.2-0.6-0.6V7.5c0-0.3,0.2-0.6,0.6-0.6h3.9V5.8H1.7C0.7,5.8,0,6.6,0,7.5v10.1c0,1,0.7,1.7,1.7,1.7h11.2c1,0,1.7-0.7,1.7-1.7V7.5C14.6,6.6,13.8,5.8,12.9,5.8z" />
    </svg>
  )
}

export default Share
