import useDebounce from '@lib/hooks/useDebounce'
import { FC, useEffect, useState } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'

interface SearchBoxProps {
  currentRefinement: string
  refine: (searchTerm: string) => void
  inputHeight: string
  inputFontSize: string
  inputId: string
  onSubmit: (e: any) => void
  onChange: (e: string) => void
  onReset: () => void
}

const SearchBox: FC<SearchBoxProps> = ({
  currentRefinement,
  refine,
  inputId,
  inputHeight,
  inputFontSize,
  onSubmit,
  onChange,
  onReset,
}) => {
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchTerm = useDebounce(searchValue, 400)

  const handleChange = (e: any) => {
    setSearchValue(e.currentTarget.value)
    onChange(e)
  }

  const handleReset = () => {
    refine('')
    setSearchValue('')
    onReset()
  }

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
      refine(debouncedSearchTerm)
    }
  }, [debouncedSearchTerm, refine])

  return (
    <>
      <div className="ais-SearchBox">
        <form noValidate action="" role="search" className="ais-SearchBox-form" onSubmit={onSubmit}>
          <input
            type="search"
            value={searchValue}
            placeholder="Search..."
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            maxLength={100}
            onChange={handleChange}
            id={inputId}
          />
          {searchValue.length > 0 && (
            <button onClick={handleReset} type="button">
              <div className="absolute right-56 top-25 h-full body-small underline tablet:right-64 tablet:top-32">
                Clear
              </div>
            </button>
          )}
        </form>
      </div>
      <style jsx>
        {`
          .ais-SearchBox {
            margin: 0 auto;
          }
          .ais-SearchBox-form {
            display: flex;
            align-items: center;
            width: 100%;
          }
          .ais-SearchBox-form input[type='search'] {
            display: block;
            width: 100%;
            height: ${inputHeight};
            background: #f7f3ed !important;
            outline: none;
            font-size: ${inputFontSize};
            line-height: 16px;
            color: black;
          }
          .ais-SearchBox-form input[type='search'] ::placeholder {
            color: #afb1b4;
            opacity: 1;
          }

          .ais-SearchBox-form button[type='reset']:focus {
            outline: none;
          }

          @media (max-width: 425px) {
            .ais-SearchBox-form input[type='search'] {
              height: 72px;
            }
          }
        `}
      </style>
    </>
  )
}

const CustomSearchBox = connectSearchBox(SearchBox)

export default CustomSearchBox
