import { slice } from '@lib/prismic'
import { FC } from 'react'
import MobileNavItem from './MobileNavItem'
interface Props {
  navItems: slice[]
  activeMegaTitle: string
}

const MobileFeaturedTiles: FC<Props> = ({ navItems, activeMegaTitle }) => {
  if (navItems.length == 0) return <></>
  return (
    <div className="py-20 bg-ecru wrapper overflow-hidden">
      <div className="flex flex-col gap-20">
        {navItems &&
          navItems.map((item, index) => {
            const { primary, items, slice_type } = item || {}
            const { group_title } = primary || {}
            return (
              <div className="flex flex-col gap-8" key={index}>
                {group_title && (
                  <div className=" text-newSage font-g-medium font-bold text-12 leading-16 uppercase tracking-spacingBitWider">
                    {group_title}
                  </div>
                )}
                {items && (
                  <div className="scrollbar-hide featuredTileContainer">
                    {items.map((item: any, index: number) => {
                      return (
                        <MobileNavItem
                          navItem={item}
                          slice_type={slice_type!}
                          key={index}
                          activeMegaTitle={activeMegaTitle}
                          groupTitle={group_title}
                        />
                      )
                    })}
                  </div>
                )}
              </div>
            )
          })}
      </div>
      <style jsx>
        {`
          .featuredTileContainer {
            gap: 12px;
            display: flex;
            flex-wrap: nowrap;
            overflow-x: scroll;
            width: 100vw;
          }
          .featuredTileContainer:last-child {
            padding-right: 40px;
          }
        `}
      </style>
    </div>
  )
}

export default MobileFeaturedTiles
