const Swatch = ({ withBorder = false, variant = 'small', storke = '#DEDEDE', className = '', ...props }) => {
  const outerProps: any = {}
  if (props?.width) outerProps['width'] = props.width
  if (props?.height) outerProps['width'] = props.height

  if (withBorder) {
    return (
      <svg
        className={className}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...outerProps}
      >
        <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" fill="white" stroke="#DEDEDE" {...props} />
      </svg>
    )
  }

  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...outerProps}
    >
      <rect width="18" height="18" rx="9" {...props} />
    </svg>
  )
}
export default Swatch
