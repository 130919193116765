import { useMutation } from '@apollo/client'
import type { Cart } from '@commerce/types/cart'
import checkoutLineItemRemoveMutation, {
  checkoutRemoveLineItemsVars,
} from '@commerce/utils/mutations/checkout-line-item-remove'
import { Lock } from '@components/icons'
import { Button } from '@components/ui'
import { getIdFromGraphQlId } from '@lib/general'
import processLocale from '@lib/locale'
import { useRouter } from 'next/router'
import { FC, ReactNode, useEffect, useState } from 'react'

interface props {
  cart: Cart | null
  locale: string
  total: string
  updateCredit: (value: number) => void
  children?: ReactNode
}

interface LoopReturnsGetResponse {
  cart: string[]
}

export interface LoopReturnsParams {
  loop_return_id?: string // UUID that is unique per order lookup
  loop_currency?: string // the currency the original order was made in
  loop_total?: number // total credit after tax in cents
  loop_base?: number // total credit without tax
  loop_credit?: number // amount of bonus credit
  loop_domain?: string // the domain the user came from
  loop_subdomain?: string // Loop's subdomain for you
  loop_redirect_url?: string // redirect link back to the app
  loop_customer_name?: string // name of customer on the original order
}

interface LoopReturnsCart {
  token: string
  loop_redirect_id: string
}

interface LoopReturnsCreateCartResponse {
  token: string
  data: {
    cart: number[]
  }
  errors?: any
  redirect_to: string
}

interface LoopReturnsUpdateCartResponse {
  token: string
  data: {
    cart: number[]
  }
  redirect_to: string
}

const LOCAL_STORAGE_ON_STORE_PARAMS = `loopOnstoreParams`
const LOCAL_STORAGE_ON_STORE_CART = `loopOnStoreCart`

const emptyCart: (cart: Cart | null, locale: string | undefined, removeItems: any) => Promise<any> = (
  cart,
  locale,
  removeItems
) => {
  if (cart) {
    const items =
      cart?.lineItems.map((item) => {
        return item.id
      }) || []
    if (items.length > 0) {
      return removeItems({
        variables: checkoutRemoveLineItemsVars({ locale, itemIds: items }),
        context: { locale },
      })
    }
  }
  return Promise.resolve()
}

const redirectToLoopReturns: (
  cart: Cart | null,
  locale: string | undefined,
  removeItems: any,
  token: string,
  redirect_to: string
) => void = (cart, locale, removeItems, token, redirect_to) => {
  // Remove local storage values
  localStorage.removeItem(`${LOCAL_STORAGE_ON_STORE_PARAMS}${locale == 'en' ? 'en-au' : locale}`)
  // Remove items in cart. This is what Loop returns recommends in their documentation, however this is not a good idea as Loop Returns has poor cart integration.
  // If we empty the cart, the properties data in the line items (like the pre-orders) will not appear again once the customer comes back to the store.
  // emptyCart(cart, locale, removeItems)

  //Redirect to loop returns
  if (undefined !== redirect_to) {
    window.location.href = `${redirect_to}/#/cart/v2/${token}`
  } else {
    window.location.href = `${redirect_to}/#/cart/v2/${token}`
  }
}

const ClearLoopReturns: (cart: Cart | null, locale: string | undefined, removeItems: any) => void = (
  cart,
  locale,
  removeItems
) => {
  localStorage.removeItem(`${LOCAL_STORAGE_ON_STORE_PARAMS}${locale == 'en' ? 'en-au' : locale}`)
  localStorage.removeItem(`${LOCAL_STORAGE_ON_STORE_CART}${locale == 'en' ? 'en-au' : locale}`)
  emptyCart(cart, locale, removeItems)
}

const CreateLoopReturnsCart: (
  locale: string,
  cartVariantIds: number[],
  loopReturnId?: string
) => Promise<LoopReturnsCreateCartResponse> = (locale, cartVariantIds, loopReturnId = '') => {
  return fetch(`/api/loop-returns/on-store-create-cart`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ locale, cart: cartVariantIds }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.token) {
        localStorage.setItem(
          `${LOCAL_STORAGE_ON_STORE_CART}${locale == 'en' ? 'en-au' : locale}`,
          JSON.stringify({
            token: data.token,
            return_id: loopReturnId,
          })
        )
      }
      return data
    })
    .catch((err) => {
      console.error(err)
    })
}

const UpdateLoopReturnsCart: (
  locale: string,
  cartVariantIds: number[],
  token: string
) => Promise<LoopReturnsUpdateCartResponse> = (locale, cartVariantIds, token) => {
  return fetch('/api/loop-returns/on-store-update-cart', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      locale,
      token,
      cart: cartVariantIds,
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data
    })
    .catch((err) => {
      console.error(err)
    })
}

const LoopReturns: FC<props> = ({ children, cart, total, updateCredit }) => {
  const [savedParams, setSavedParams] = useState<LoopReturnsParams>({})
  const { locale } = useRouter()
  const currentLocale = processLocale(locale)

  const [removeItems] = useMutation(checkoutLineItemRemoveMutation, {
    onCompleted() {},
    onError(error) {
      console.error(error)
    },
  })

  useEffect(() => {
    const localStorageParams = localStorage.getItem(
      `${LOCAL_STORAGE_ON_STORE_PARAMS}${locale == 'en' ? 'en-au' : locale}`
    )
    const localStorageOnStoreCart = localStorage.getItem(
      `${LOCAL_STORAGE_ON_STORE_CART}${locale == 'en' ? 'en-au' : locale}`
    )
    let paramsObject: LoopReturnsParams = {}
    paramsObject = localStorageParams ? JSON.parse(localStorageParams) : {}
    if (window.location.search.includes('loop_total')) {
      const params = new URLSearchParams(window.location.search)
      // Remove any non-loop related query params, then convert the query string
      // to an object of key/value pairs to make it easier to work with
      paramsObject = [...params.entries()]
        .filter(([key]) => key.startsWith('loop'))
        .reduce((acc, [key, value]) => {
          return { ...acc, [key]: value }
        }, {})
      localStorage.setItem(
        `${LOCAL_STORAGE_ON_STORE_PARAMS}${locale == 'en' ? 'en-au' : locale}`,
        JSON.stringify(paramsObject)
      )
      // Remove query params from the URL
      setSavedParams(paramsObject)
      const onStoreObject: LoopReturnsCart = localStorageOnStoreCart
        ? JSON.parse(localStorageOnStoreCart)
        : { token: null, loop_redirect_id: null }
      //If we had an old cart token saved, but the redirect_id doesn't match it then we delete the old localStorage data.
      if (onStoreObject.loop_redirect_id !== paramsObject.loop_return_id) {
        localStorage.removeItem(`${LOCAL_STORAGE_ON_STORE_CART}${locale == 'en' ? 'en-au' : locale}`)
      }
      window.history.replaceState({}, '', `${window.location.origin}${window.location.pathname}`)
    } else {
      setSavedParams(paramsObject)
    }
    if (paramsObject?.loop_total) {
      updateCredit(Number(paramsObject.loop_total) / 100)
    } else if (paramsObject?.loop_base) {
      updateCredit(Number(paramsObject.loop_base) / 100)
    } else {
      updateCredit(0)
    }

    const loopReturnsCart =
      localStorage.getItem(`${LOCAL_STORAGE_ON_STORE_CART}${locale == 'en' ? 'en-au' : locale}`) || ''
    if (loopReturnsCart !== '') {
      const loopReturnsCartData: { token: string } = JSON.parse(loopReturnsCart)
      // Get Cart
      fetch('/api/loop-returns/on-store-get-cart', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          locale: currentLocale,
          token: loopReturnsCartData.token,
        }),
      })
        .then((res) => res.json())
        .then((data: any) => {
          if (data.cart.length === 0) {
            ClearLoopReturns(cart, currentLocale, removeItems)
          }
        })
        .catch((err) => {
          localStorage.removeItem(`${LOCAL_STORAGE_ON_STORE_CART}${locale == 'en' ? 'en-au' : locale}`)
          console.error(err)
        })
    }
  }, [updateCredit, cart, currentLocale, locale, removeItems])

  const handleClick: (e: React.MouseEvent) => void = (e) => {
    const loopReturnsCart =
      localStorage.getItem(`${LOCAL_STORAGE_ON_STORE_CART}${locale == 'en' ? 'en-au' : locale}`) || ''
    const cartVariantIds: number[] =
      cart?.lineItems.reduce((acum, lineItem) => {
        const variantId = Number(getIdFromGraphQlId(lineItem.variantId, 'gid://shopify/ProductVariant/'))
        const variantIds = Array(lineItem.quantity).fill(variantId)
        acum = [...acum, ...variantIds]
        return acum
      }, [] as number[]) || []
    if (!loopReturnsCart || (loopReturnsCart == '' && savedParams)) {
      //Create a cart in Loop Returns
      CreateLoopReturnsCart(currentLocale, cartVariantIds, savedParams?.loop_return_id || '').then((data) => {
        if (data?.errors) {
          console.error('Errors', data.errors)
        } else {
          redirectToLoopReturns(cart, currentLocale, removeItems, data.token, data.redirect_to)
        }
      })
    } else {
      // Update cart in loop returns
      const loopReturnsCartObject: LoopReturnsCart = JSON.parse(loopReturnsCart)
      UpdateLoopReturnsCart(currentLocale, cartVariantIds, loopReturnsCartObject.token).then((data) => {
        redirectToLoopReturns(cart, currentLocale, removeItems, loopReturnsCartObject.token, data.redirect_to)
      })
    }
  }

  return (
    <>
      {savedParams?.loop_base !== undefined ? (
        <Button Component="button" onClick={handleClick} width="100%">
          <div className="tracking-8 flex">
            <Lock className="mr-8" />
            <span>Checkout</span>
            <span className="px-4">-</span>
            <span> {total}</span>
          </div>
        </Button>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

export default LoopReturns
