import cn from 'classnames'
import s from './FeatureBar.module.css'

interface FeatureBarProps {
  className?: string
  title: string
  description?: string
  hide?: boolean
  action?: React.ReactNode
}

const FeatureBar: React.FC<FeatureBarProps> = ({ title, description, className, action, hide }) => {
  const rootClassName = cn(
    s.root,
    {
      transform: true,
      'translate-y-0 opacity-100': !hide,
      'translate-y-full opacity-0': hide,
    },
    className,
    'body-bold'
  )
  return (
    <div className={rootClassName}>
      <span className="block tablet:inline">{title}</span>
      <span className="block mb-6 tablet:inline tablet:mb-0 tablet:ml-2">{description}</span>
      {action && action}
    </div>
  )
}

export default FeatureBar
