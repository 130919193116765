import { checkoutDetailsFragment } from '../fragements'
import { gql } from '@apollo/client'
import getCheckoutId from '../get-checkout-id'

const checkoutLineItemUpdateMutation = /* GraphQL */ gql`
  mutation checkoutLineItemUpdate($checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkoutUserErrors {
        code
        field
        message
      }
      checkout {
        ...checkoutDetails
      }
    }
  }

  ${checkoutDetailsFragment}
`

type MutationVars = {
  locale: string | undefined
  id: string
  quantity: number
}

export const checkoutUpdateLineItemVars = ({ locale, id, quantity }: MutationVars) => ({
  checkoutId: getCheckoutId(locale),
  lineItems: [
    {
      id,
      quantity,
    },
  ],
})
export default checkoutLineItemUpdateMutation
