const RedCross = ({ className = '', ...props }) => {
  return (
    <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props} className={className}>
      <circle cx={9} cy={9} r={9} fill="#BC3636" />
      <path d="M6.3 6.3l5.4 5.4M6.3 11.7l5.4-5.4" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default RedCross
