import { EVENT_DEBUG, eventLog, infoLog } from './general'

declare global {
  interface Window {
    dataLayer: any
    ElevarDataLayer: any
  }
}

export const newsletterSubscriptionComplete = (email: string) => {
  if (EVENT_DEBUG && !window?.dataLayer) {
    infoLog('NewsletterSubscribeComplete: No Data Layer found.')
  }

  if (window?.dataLayer) {
    const detail = {
      event: 'newsletterSubscribeComplete',
      eventStatus: 'Successful',
    }

    window?.dataLayer?.push(detail)
    if (EVENT_DEBUG) eventLog(`View Page 'gtm.NewsletterSubscribeComplete (custom)`, { detail })
  }
  window.ElevarDataLayer.push({
    event: "dl_subscribe",
    lead_type: "email",
    user_properties: {
      customer_email: email,
    }
  });

}
