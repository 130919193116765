import { FetchPolicy } from '@apollo/client'
import client from '@commerce/client'
import { getCurrencyCountryCode, getProductQuery } from '@commerce/utils'
import { normalizeProduct } from '@commerce/utils/normalize'
import { Product } from '../../schema'

const getProduct = async (locale: string | undefined, slug: string, fetchPolicy?: FetchPolicy) => {
  let queryOptions = {
    query: getProductQuery,
    variables: { slug, country: String(getCurrencyCountryCode(locale)) },
    context: { locale },
  }

  const {
    data: { productByHandle },
  } = await client.query(fetchPolicy ? { ...queryOptions, fetchPolicy } : queryOptions)

  return {
    ...(productByHandle && { product: normalizeProduct(productByHandle as Product) }),
  }
}

export default getProduct
