import { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import { DispatchContext } from '@lib/types/general'
import { countDownTimer, allowedTimeZones } from '@lib/dispatchMessage'
import cn from 'classnames'

interface propsType {
  context: DispatchContext
  styles?: string
}

const DispatchMessage = ({ context, styles = '' }: propsType) => {
  const [countDownMessage, setCountDownMessage] = useState(context.countDownMessage)

  useEffect(() => {
    renderMessage()
    const interval = setInterval(() => {
      renderMessage()
    }, 1000)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderMessage = () => {
    const melbourneTime = DateTime.now().setZone(context.countDownTimeZone)
    const usersTime = DateTime.local()
    const timeNow = new Date(String(melbourneTime))
    const countDownStartTime = new Date(
      String(melbourneTime.set({ hour: parseInt(context.countDownStartTime), minute: 0, second: 0 }))
    )
    const countDownEndTime = new Date(
      String(melbourneTime.set({ hour: parseInt(context.countDownEndTime), minute: 0, second: 0 }))
    )
    const currentDay = melbourneTime.weekday
    const currentHour = timeNow.getHours()
    const fridayEndTime = new Date(String(melbourneTime.set({ hour: 15, minute: 0, second: 0 })))

    const timer = currentHour >= countDownStartTime.getHours() ? countDownTimer(countDownEndTime) : null
    if (
      context.countDown &&
      timer &&
      context.days[currentDay] &&
      currentHour >= countDownStartTime.getHours() &&
      currentHour < countDownEndTime.getHours()
    ) {
      setCountDownMessage(
        context.countDownMessage.replace(
          '[CD]',
          `<span class="dispatch-timer" style="display: inline-block; width: 33px">${timer}</span>`
        )
      )
    } else if (currentDay > 5 && countDownMessage !== context.weekendMessage) {
      setCountDownMessage(context.weekendMessage)
    } else if (
      currentDay === 5 &&
      countDownMessage !== context.weekendMessage &&
      fridayEndTime <= new Date(String(usersTime))
    ) {
      setCountDownMessage(context.weekendMessage)
    } else if (countDownMessage !== context.weekdayMessage) {
      setCountDownMessage(
        context.weekdayMessage.replace(
          '[ENDTIME]',
          DateTime.fromISO(countDownEndTime.toISOString()).toFormat('ha').toLowerCase()
        )
      )
    }

    const matchedZone = allowedTimeZones.find((timezone) => timezone === usersTime.zoneName)

    if (!matchedZone) {
      setCountDownMessage(context.weekendMessage)
    }
  }

  return <div className={cn(`body-small ${styles}`)} dangerouslySetInnerHTML={{ __html: `${countDownMessage}` }}></div>
}

export default DispatchMessage
