const CaretLeft = ({ ...props }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_8538_108181)">
        <path
          d="M6.52999 10.4699C6.52999 10.2199 6.62999 9.95989 6.81999 9.76989L11.76 4.76989C12.15 4.37989 12.78 4.36989 13.17 4.76989C13.56 5.15989 13.57 5.78989 13.17 6.17989L8.91999 10.4799L13.18 14.7699C13.57 15.1599 13.56 15.7999 13.18 16.1799C12.79 16.5699 12.15 16.5599 11.77 16.1799L6.82999 11.1799C6.63999 10.9899 6.53999 10.7299 6.53999 10.4799L6.52999 10.4699Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_8538_108181">
          <rect width="12" height="6.94" fill="white" transform="translate(13.4697 4.46973) rotate(90)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CaretLeft
