import cn from 'classnames'
import usePrice from '@commerce/product/use-price'

interface Props {
  prefix: boolean
  className?: string
  price: number
  listPrice?: number
  currencyCode?: string
  fullscreen?: boolean
}

const Price = ({ price, listPrice, currencyCode, prefix, className, fullscreen }: Props) => {
  const { price: formattedPrice } = usePrice({ amount: price!, currencyCode: currencyCode! })
  const { price: formattedListPrice } = usePrice({ amount: listPrice!, currencyCode: currencyCode! })

  return (
    <div className="flex items-center">
      {listPrice && listPrice > price ? (
        <div className={cn(className, { 'mr-8': !fullscreen, 'mr-20': fullscreen })}>
          <del>{formattedListPrice}</del>
        </div>
      ) : null}

      <div className={cn(className)}>
        {prefix === true ? <span>From&nbsp;</span> : null}
        <span
          className={cn('', {
            'text-sale': listPrice && listPrice > price,
          })}
        >
          {price > 0 ? formattedPrice : !price && !listPrice ? formattedPrice : 'Free'}
        </span>
      </div>
    </div>
  )
}

export default Price
