import { checkoutDetailsFragment } from '../fragements'
import { gql } from '@apollo/client'

const checkoutCreateMutation = /* GraphQL */ gql`
  mutation checkoutCreate($input: CheckoutCreateInput = {}, $country: CountryCode!) @inContext(country: $country) {
    checkoutCreate(input: $input) {
      checkoutUserErrors {
        code
        field
        message
      }
      checkout {
        ...checkoutDetails
      }
    }
  }

  ${checkoutDetailsFragment}
`
export default checkoutCreateMutation
