/* eslint-disable react/jsx-key */
import { AutoCompleteProductCard } from '@components/common'
import { productClick } from '@lib/events/productClick'
import { getConfigByLocale } from '@lib/locale'
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import cn from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC, useEffect, useRef } from 'react'
import { Index, connectAutoComplete } from 'react-instantsearch-dom'

// autocomplete used in desktop
const AutoComplete: FC<any> = ({ hits, currentRefinement, onClickSearchTerm, showSearchBar }) => {
  const { locale } = useRouter()
  const currencyCode = getConfigByLocale(locale, 'defaultCurrencyCode')
  const algoliaIndex = getConfigByLocale(locale, 'algoliaProductIndex')

  const scrollRef = useRef() as React.MutableRefObject<HTMLDivElement>
  useEffect(() => {
    const element = scrollRef.current
    if (showSearchBar && element) {
      disableBodyScroll(element, { reserveScrollBarGap: true })
    }

    return () => {
      if (element) enableBodyScroll(element)
      clearAllBodyScrollLocks()
    }
  }, [showSearchBar])

  return (
    <div
      className={cn('top-84 w-full left-0 bg-lightBg z-50 pt-0', {
        'pb-24 laptop:pt-8': currentRefinement && currentRefinement.length >= 3,
      })}
    >
      <Index indexName={algoliaIndex}>
        <div
          ref={scrollRef}
          className="block laptop:grid grid-cols-2 gap-x-205 gap-y-12 overflow-y-scroll max-h-348 laptop:max-h-auto laptop:overflow-y-hidden"
        >
          {currentRefinement &&
            currentRefinement.length >= 3 &&
            hits?.map((hit: any) => {
              const handleClick = () => productClick(hit, 'searchresult', locale)
              return (
                <div onClick={handleClick} key={hit.id}>
                  <AutoCompleteProductCard hit={hit} currencyCode={currencyCode} />
                </div>
              )
            })}
          {currentRefinement && currentRefinement.length >= 3 && hits && hits?.length ? (
            <div
              role="button"
              tabIndex={0}
              onClick={onClickSearchTerm}
              onKeyPress={onClickSearchTerm}
              className="algolia_search_autocomplete__view_all_products paragraph_style__secondary_regular"
            >
              <Link href={`/search?q=${currentRefinement}`} passHref className="body underline">
                view all results
              </Link>
            </div>
          ) : (
            ''
          )}
          {!hits?.length && currentRefinement.length >= 3 && (
            <div className="body text-charcoal">No results found, try a different search.</div>
          )}
        </div>
      </Index>
    </div>
  )
}

const AlgoliaSearchAutocomplete = connectAutoComplete(AutoComplete)
export default AlgoliaSearchAutocomplete
