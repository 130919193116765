import type { Cart } from '@commerce/types/cart'
import { MiniCart } from '@lib/types/MiniCart'
import cn from 'classnames'
import { FC } from 'react'
import s from './SpendAndSave.module.css'
import { SpendAndSaveMessage } from './SpendAndSaveMessage'
import { SpendAndSaveProgressBar, Tier, Value } from './SpendAndSaveProgressBar'

interface SpendAndSaveWidgetProps {
  message: string
  cart: Cart
  excludeProductTypes: string
  tiers: string
  subMessage: string
  miniCart: MiniCart
}

const getFinalMessage: (
  message: string,
  cartTotal: number,
  remainingValue: number,
  discountValue: number,
  currentDisountValue: number
) => string = (message, cartTotal, remainingValue, discountValue, currentDisountValue) => {
  if (remainingValue == 0) return `You've received $${currentDisountValue} off your order`
  if (message !== '') {
    if (cartTotal === 0) {
      return message
        .replace('#{remaining}', String(remainingValue))
        .replace('#{discount}', String(discountValue))
        .replace('more', '')
    } else {
      return message.replace('#{remaining}', String(remainingValue)).replace('#{discount}', String(discountValue))
    }
  }
  return ''
}

const getDisplayTiers = (tiers: Tier[], cartTotal: number): Value[] => {
  let result: Value[] = []
  let currentTierIndex = -1

  tiers.sort((a, b) => a.from - b.from) // Sort tiers by 'from' value

  for (let i = 0; i < tiers.length; i++) {
    const currentTier = tiers[i]
    const nextTier = tiers[i + 1]

    const isCurrent = cartTotal >= currentTier.from && (!nextTier || cartTotal < nextTier.from)

    const value: Value = {
      amount: currentTier.from,
      discount: currentTier.baseDiscount,
      isCurrent,
      isNext: false,
      index: i,
      isLast: !nextTier,
    }

    if (isCurrent) {
      currentTierIndex = i
    }

    result.push(value)
  }

  if (currentTierIndex === -1 && tiers.length > 0) {
    result[0].isNext = true
  } else if (currentTierIndex >= 0 && currentTierIndex < tiers.length - 1) {
    result[currentTierIndex + 1].isNext = true
  } else if (currentTierIndex === tiers.length - 1) {
    result[currentTierIndex].isNext = false
  }

  return result
}

export const SpendAndSaveWidget: FC<SpendAndSaveWidgetProps> = ({
  message = '',
  excludeProductTypes,
  tiers,
  cart,
  subMessage = '',
  miniCart,
}) => {
  let exclude_products: string[] = []
  let tiersArray: Tier[] = []
  if (tiers === undefined) {
    return <></>
  }

  if (excludeProductTypes !== '') {
    try {
      exclude_products = JSON.parse(excludeProductTypes)
      exclude_products = exclude_products.map((type) => type.toLocaleLowerCase())
    } catch (e) {
      console.error('Wrong Spend and Save Exclude Product Types value')
    }
  }

  if (tiers !== '') {
    try {
      tiersArray = JSON.parse(tiers)
    } catch (e) {
      console.error('Wrong Spend and Save Tiers value')
    }
  }

  if (tiersArray.length == 0) {
    return <></>
  }

  const cartTotal = cart?.lineItems.reduce((acum, item) => {
    if (exclude_products.length > 0) {
      if (exclude_products.includes(item.type.toLocaleLowerCase())) {
        return acum
      }
    }
    acum += item.variant.price * item.quantity
    return acum
  }, 0)

  // Detect what is the current tier the customer is in.
  const newValues = getDisplayTiers(tiersArray, cartTotal)
  const nextTier = newValues.find((value) => value.isNext)
  const currentTier = newValues.find((value) => value.isCurrent)
  const remainingValue = nextTier?.amount ? nextTier.amount - cartTotal : 0
  const finalMessage = getFinalMessage(
    message,
    cartTotal,
    remainingValue,
    nextTier?.discount || 0,
    currentTier?.discount || 0
  )

  return (
    <>
      <div
        className={cn(s.component)}
        style={
          {
            '--spasv-bg': miniCart?.panel_background_colour,
            borderTop: `1px solid ${miniCart?.panel_border_colour}`,
            borderBottom: `1px solid ${miniCart?.panel_border_colour}`,
          } as React.CSSProperties
        }
      >
        <SpendAndSaveMessage message={finalMessage} heading_text_colour={miniCart?.heading_text_colour} />
        {subMessage !== '' ? (
          <div
            className={cn(s.subMessage)}
            style={{ color: miniCart?.sub_message_and_price_text_colour || '--spasv--sub-color' }}
          >
            {subMessage}
          </div>
        ) : (
          <></>
        )}
        <SpendAndSaveProgressBar values={newValues} cartTotal={cartTotal} miniCart={miniCart} />
      </div>
    </>
  )
}
