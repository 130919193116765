import { useMutation } from '@apollo/client'
import { SelectedOption } from '@commerce/schema'
import type { LineItem } from '@commerce/types/cart'
import {
  checkoutLineItemRemoveMutation,
  checkoutLineItemUpdateMutation,
  checkoutRemoveLineItemsVars,
  checkoutUpdateLineItemVars,
} from '@commerce/utils'
import Price from '@components/common/Price'
import { Cross } from '@components/icons'
import { Button } from '@components/ui'
import Quantity from '@components/ui/Quantity'
import { useUI } from '@components/ui/context'
import { removeFromCartEvent } from '@lib/events/removeFromCart'
import { updateCartQuantityEvent } from '@lib/events/updateCartQuantity'
import cn from 'classnames'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'
import AddonLineItemError from '../Addon/AddonLineItemError'
import s from './CartItem.module.css'

type ItemOption = {
  name: string
  nameId: number
  value: string
  valueId: number
}

interface AddonError {
  color: string
  error: string | null
}

const CartItem = ({
  position,
  item,
  isFocused,
  nextItemId,
  variant = 'default',
  currencyCode,
  onRemoved,
  onUpdated,
  addonError,
  ...rest
}: {
  position: number
  variant?: 'default' | 'display'
  item: LineItem
  nextItemId: string
  isFocused: boolean
  currencyCode: string
  onRemoved?: (focus: string) => void
  onUpdated?: (focus: string) => void
  addonError?: AddonError[] | null
}) => {
  // TODO: remove local removing and add loading state at the parent level
  const { locale } = useRouter()
  const { closeSidebarIfPresent } = useUI()
  const [updating, setUpdating] = useState(false)
  const [removing, setRemoving] = useState(false)
  const [focus, setFocus] = useState('')
  const [quantity, setQuantity] = useState<number>(item.quantity)
  const [finalPrice, setFinalPrice] = useState(item.variant.price * item.quantity)
  const [listPrice, setListPrice] = useState(item.variant.listPrice * item.quantity)
  const [removeItem] = useMutation(checkoutLineItemRemoveMutation, {
    onCompleted() {
      setRemoving(false)
      setUpdating(false)
      if (typeof onRemoved === 'function') {
        onRemoved(focus)
      }
      if (typeof onUpdated === 'function') {
        onUpdated(focus)
      }
    },
    onError() {
      setRemoving(false)
    },
  })
  // const removeItem = useRemoveItem()
  const [updateItem] = useMutation(checkoutLineItemUpdateMutation, {
    onCompleted() {
      setRemoving(false)
      setUpdating(false)
      if (typeof onUpdated === 'function') {
        onUpdated(focus)
      }
    },
    onError() {
      setRemoving(false)
    },
  })

  const handleChange: (e: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>, id: string) => void = async (
    { target },
    id
  ) => {
    const value = (target as HTMLInputElement).value
    setQuantity(Number(value))
    setUpdating(true)
    setFocus(Number(value) > 0 ? id : nextItemId)
    updateItem({
      variables: checkoutUpdateLineItemVars({ locale, id: item.id, quantity: Number(value) }),
      context: { locale },
    })
  }

  const increaseQuantity = async (n = 1) => {
    const val = Number(quantity) + n
    setQuantity(val)
    setUpdating(true)
    updateItem({
      variables: checkoutUpdateLineItemVars({ locale, id: item.id, quantity: val }),
      context: { locale },
    })
    updateCartQuantityEvent(item, val, currencyCode)
  }

  const handleRemove = async () => {
    setRemoving(true)
    setUpdating(true)
    setFocus(nextItemId)
    removeItem({
      variables: checkoutRemoveLineItemsVars({ locale, itemIds: [item.id] }),
      context: { locale },
    })
    removeFromCartEvent(item, position, locale)
  }

  const options = item.options

  useEffect(() => {
    // Reset the quantity state if the item quantity changes
    if (item.quantity !== Number(quantity)) {
      setQuantity(item.quantity)
    }
    if (item.discounts.length > 0) {
      let price = item.quantity * item.variant.price
      let discounts = 0
      item.discounts.map((discount) => {
        price -= discount.allocatedAmount
        discounts += discount.allocatedAmount
      })
      setFinalPrice(price)
      if (!item.variant.listPrice) {
        setListPrice(item.variant.price * item.quantity)
      } else {
        let compareAtPrice = item.variant.listPrice * item.quantity
        let originalDiscount = compareAtPrice - item.quantity * item.variant.price
        let totalDiscount = originalDiscount + discounts
        // If an item has a compare at price value, we reduce the discounts to that value so the total savings matches any handmade calculations with the visuals in the mini cart
        setListPrice(price + totalDiscount)
      }
    }
    // TODO: currently not including quantity in deps is intended, but we should
    // do this differently as it could break easily
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.quantity])

  return (
    <li
      key={item.id}
      className={cn(s.root, {
        'opacity-50 pointer-events-none': removing,
      })}
      {...rest}
    >
      <div className="flex flex-row">
        <div className="w-72 h-72 bg-primary relative cursor-pointer z-0">
          <Link href={`/product/${item.path}`} passHref className="block" onClick={() => closeSidebarIfPresent()}>
            <Image
              className={s.productImage}
              width={72}
              height={72}
              src={item.variant.image!.url}
              alt={item.variant.image!.altText || 'CartItem'}
              layout="fixed"
              quality="100"
            />
          </Link>
        </div>
        <div className="pl-12 flex flex-col justify-start items-stretch flex-grow">
          <div className="pr-24 relative">
            <Link href={`/product/${item.path}`} passHref legacyBehavior>
              <span className={s.productName} onClick={() => closeSidebarIfPresent()}>
                {item.name}
              </span>
            </Link>
            {options && options.length > 0 && (
              <div className="flex items-center">
                <div key={`${item.id}-options`} className={cn(s.productDesc)}>
                  <span>{options.map((option: SelectedOption, i: number) => option.value).join(' / ')}</span>
                </div>
              </div>
            )}
            {variant === 'display' && <div className="text-14 tracking-wider">{quantity}x</div>}
            {/* Hide custom attributes */}
            {item.customAttributes
              ?.filter((attribute) => !attribute.key.startsWith('_'))
              ?.map((attribute, index) => {
                return (
                  <div key={index} className="font-g-bold text-12 leading-20">
                    <span className="body-bold-small text-charcoal">
                      {attribute.key}
                      {!attribute.key.includes(':') && ':'}&nbsp;
                    </span>
                    <span className="body-bold-small text-charcoal">{attribute.value}</span>
                  </div>
                )
              })}
            <Button className={cn(s.remove)} variant="textLink" onClick={handleRemove}>
              <Cross className="block w-12 h-12 fill-current" />
            </Button>
          </div>
          <div className={cn(s.quantityPrice)}>
            {variant === 'default' && (
              <Quantity
                id={item.id}
                isUpdating={updating}
                value={quantity}
                handleChange={handleChange}
                isFocused={isFocused || focus == item.id}
                increase={() => increaseQuantity(1)}
                decrease={() => increaseQuantity(-1)}
              />
            )}
            <Price price={finalPrice} listPrice={listPrice} currencyCode={currencyCode} prefix={false} />
          </div>
          {addonError && addonError.length > 0 && (
            <div className="mt-6">
              {addonError.map((addonError, index) => {
                return <AddonLineItemError key={index} error={addonError.error!} color={addonError.color} />
              })}
            </div>
          )}
        </div>
      </div>
    </li>
  )
}

export default CartItem
