import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const COOKIE_NAME = 'accept_cookies'

export const useAcceptCookies = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(true)
  const router = useRouter()

  useEffect(() => {
    const handleAcceptCookiesOnChange = (url: string, options: any) => {
      if (options.shallow === false) {
        acceptCookies()
      }
    }
    router.events.on('routeChangeStart', handleAcceptCookiesOnChange)

    return () => {
      router.events.off('routeChangeStart', handleAcceptCookiesOnChange)
    }
  }, [router.events])

  useEffect(() => {
    if (!Cookies.get(COOKIE_NAME)) {
      setAcceptedCookies(false)
    }
  }, [])

  const acceptCookies = () => {
    setAcceptedCookies(true)
    Cookies.set(COOKIE_NAME, 'accepted', { expires: 365 })
  }

  return {
    acceptedCookies,
    onAcceptCookies: acceptCookies,
  }
}
