import Cookies, { CookieAttributes } from 'js-cookie'
import commerceConfig from '@commerce/config'
import processLocale from '@lib/locale'
import { customerAccessTokenVar } from '@commerce/client'

export const getCustomerToken = (locale: string | undefined) => {
  const {
    config: { context },
  } = commerceConfig
  const currentLocale = processLocale(locale)
  const currentContext = context?.[currentLocale]

  return Cookies.get(currentContext.customerTokenCookieName)
}

export const setCustomerToken = (locale: string | undefined, token: string | null, options?: CookieAttributes) => {
  const {
    config: { shopifyCookieExpiry, context },
  } = commerceConfig
  const currentLocale = processLocale(locale)
  const currentContext = context?.[currentLocale]

  if (!token) {
    Cookies.remove(currentContext.customerTokenCookieName)
    customerAccessTokenVar('');
  } else {
    Cookies.set(
      currentContext.customerTokenCookieName,
      token,
      options ?? {
        expires: shopifyCookieExpiry,
      }
    )
    customerAccessTokenVar(token)
  }
}
