import Cookies from 'js-cookie'
import commerceConfig from '@commerce/config'
import processLocale from '@lib/locale';

const setCheckoutId = (locale: string | undefined, checkoutId: string) => {
  const { config: { shopifyCookieExpiry, context } } = commerceConfig;
  const currentLocale = processLocale(locale);
  const currentContext = context?.[currentLocale];

  const options = { expires: shopifyCookieExpiry }
  Cookies.set(currentContext?.checkoutIdCookieName, checkoutId, options)
}

export default setCheckoutId
