import type { Product } from '@commerce/types/product'
import { normalizeBedBuilderProduct } from '@commerce/utils'
import { BedBuilderProduct } from '@components/builder/declarations/types'
import { getCurrenceCodeByLocale } from '@lib/locale'
import { getMetafieldsValueByKey } from '@lib/product'
import { EcommerceData, Product as ElevarProduct } from '@lib/types/Elevar'
import { ElevarEvents, getUserPropertiesFromSessionStorage } from './elevarEvents'
import {
  EVENT_DEBUG,
  eventLog,
  getDataLayerProductIdBedBuilderForShopify,
  getDataLayerProductIdForShopify,
  getNumberPartFromId,
  infoLog,
} from './general'

declare global {
  interface Window {
    dataLayer: any
  }
}

const getVariantsFromLineItems = (
  lineItems: { variantId: string | number; quantity: number }[],
  products: Product[]
) => {
  if (lineItems?.length) {
    const variants = lineItems.map((l) => {
      let match = null
      products.forEach((product) => {
        product.variants.forEach((v) => {
          if (v.id === l.variantId) {
            match = { ...product, selectedVariant: v }
          }
        })
      })
      if (match) return match
    })
    return variants
  }
}
const getPositionFromProductsList = (variantId: string, products: Product[]) => {

  const productsList = products
    ?.map((product) => {
      return product?.variants?.map((v) => v.id)
    }).flat()
  return productsList.indexOf(variantId)
}

export const addToCartEvent = (
  lineItems: { variantId: string | number; quantity: number }[],
  product: Product,
  componentProducts: Product[],
  locale?: string
) => {
  const selectedProducts = getVariantsFromLineItems(lineItems, [product, ...componentProducts])
  if (EVENT_DEBUG && !window?.dataLayer) {
    infoLog('Add To Cart: No Data Layer found.')
  }
  if (window?.dataLayer) {
    const detail = {
      event: 'ecommerce',
      ecommerceEvent: 'Add To Cart',
      ecommerceEventLabel: lineItems.length, //qty
      google_tag_params: {
        ecomm_pagetype: 'cart',
        ecomm_prodid: selectedProducts?.map((s: any) => getDataLayerProductIdForShopify(s, s.selectedVariant, locale)), // array of all product IDs
        ecomm_category: selectedProducts?.map((s: any) => s.productType), // array of all product categories
        ecomm_totalvalue: selectedProducts
          ?.map((s: any) => s.selectedVariant.price)
          .reduce((prev, next) => prev + next), // total value
      },
      ecommerce: {
        currencyCode: product.price.currencyCode,
        add: {
          products: selectedProducts?.map((s: any) => {
            return {
              name: s.name,
              id: getDataLayerProductIdForShopify(s, s.selectedVariant, locale),
              brand: s.range.replace(/-/g, ' ').toUpperCase(),
              price: s.selectedVariant.price,
              dimension2: product?.productType?.toLowerCase()?.includes('bundle') ? 'Bundle' : 'Product', // Single Product, Bundle or Complete The Look
              dimension3: s.colourway, // Product Colour (comma separated if more than one)
              dimension4: s.colour, // Colour Category
              dimension5: s.selectedVariant?.options?.[0]?.values?.[0]?.label, // Size
              dimension6: s?.fabric?.join(','),
              dimension7:
                Number(s.selectedVariant?.listPrice) > Number(s.selectedVariant?.price) ? 'Sale' : 'Full Price', // Price Category - 'Full Price', 'Sale' etc.
              quantity: 1,
            };
          }),
        },
      },
    }
    window?.dataLayer?.push(detail)
    if (EVENT_DEBUG) eventLog(`Add To Cart 'gtm.ecommerce.AddToCart (custom)`, { detail })
  }

  const products: ElevarProduct[] = selectedProducts?.map((s: any, index) => {
    const ctlComponents = getMetafieldsValueByKey(product?.metafields, 'complete_the_look_components', true)
    const bundleComponents = getMetafieldsValueByKey(product?.metafields, 'bundle_components', true)
    const ctlHandles = ctlComponents.map((c: any) => c.handle)
    const bundleHandles = bundleComponents.map((b: any) => b.handle)
    const elevarMainProduct = [product]
    const elevarBundleProducts = componentProducts?.filter((p) => p?.slug && bundleHandles.includes(p.slug))
    const elevarCtlProducts = componentProducts?.filter((p) => p?.slug && ctlHandles.includes(p.slug))
    const elevarProducts = elevarMainProduct?.concat(elevarBundleProducts)?.concat(elevarCtlProducts).map((product, index) => ({
      ...product,
      position: index,
    }))
    const position = getPositionFromProductsList(s.selectedVariant.id, elevarProducts)
    return {
      id: getDataLayerProductIdForShopify(s, s.selectedVariant, locale),
      name: s.name || "",
      brand: s.range?.replace(/-/g, ' ')?.toUpperCase() || "",
      category: s.productType?.toString() || "",
      variant: s.selectedVariant?.name || "",
      price: s.selectedVariant?.price?.toString() || "",
      quantity: lineItems.find((item) => item.variantId == s.selectedVariant?.id)?.quantity?.toString() || '1',
      position: position || index,
      list: `/${locale}/collections/${s.range}`,
      product_id: getNumberPartFromId(s.id),
      variant_id: getNumberPartFromId(s.selectedVariant?.id),
      compare_at_price: typeof (s.selectedVariant?.listPrice) === 'number' && !isNaN(s.selectedVariant?.listPrice) ? s.selectedVariant?.listPrice.toString() : '0.0',
      image: s.selectedVariant?.image?.src.replace(/https?:\/\//, '//') || "",
      inventory: "",
      dimension2: product?.productType?.toLowerCase()?.includes('bundle') ? 'Bundle' : 'Product', // Single Product, Bundle or Complete The Look
      dimension3: s.colourway, // Product Colour (comma separated if more than one)
      dimension4: s.colour, // Colour Category
      dimension5: s.selectedVariant?.name || "", // Size
      dimension6: s?.fabric?.join(','),
      dimension7:
        Number(s.selectedVariant?.listPrice) > Number(s.selectedVariant?.price) ? 'Sale' : 'Full Price', // Price Category - 'Full Price', 'Sale' etc.

    };
  }) || []
  const userProperties = getUserPropertiesFromSessionStorage()
  const ecommerceData: EcommerceData = {
    currencyCode: getCurrenceCodeByLocale(locale),
    add: {
      actionField: {
        list: `/${locale}/collections/${product.range}`
      },
      products: products
    }
  }
  ElevarEvents('dl_add_to_cart', userProperties, ecommerceData)
}

export const addToCartBedBuilderEvent = (products: BedBuilderProduct[], currencyCode: string, locale?: string) => {
  if (EVENT_DEBUG && !window?.dataLayer) {
    infoLog('Add To Cart: No Data Layer found.')
  }
  const detail = {
    event: 'ecommerce',
    ecommerceEvent: 'Add To Cart',
    ecommerceEventLabel: products.length, //qty
    google_tag_params: {
      ecomm_pagetype: 'cart',
      ecomm_prodid: products?.map((product) =>
        getDataLayerProductIdBedBuilderForShopify(product, product.variants[0], locale)
      ), // array of all product IDs
      ecomm_category: products?.map((product) => product.productType), // array of all product categories
      ecomm_totalvalue: products?.map((product) => product.variants[0].price).reduce((prev, next) => prev + next), // total value
    },
    ecommerce: {
      currencyCode: currencyCode,
      add: {
        products: products?.map((product) => {
          const normalizeProduct = normalizeBedBuilderProduct(product)
          return {
            name: product.title,
            id: getDataLayerProductIdBedBuilderForShopify(product, product.variants[0], locale),
            brand: normalizeProduct.range.replace(/-/g, ' ').toUpperCase(),
            price: normalizeProduct.price,
            dimension2: normalizeProduct?.productType?.toLowerCase() === 'bundle' ? 'Bundle' : 'Product', // Single Product, Bundle or Complete The Look
            dimension3: normalizeProduct.colourway, // Product Colour (comma separated if more than one)
            dimension4: normalizeProduct.colour, // Colour Category
            dimension5: normalizeProduct.variants[0].option1, // Size
            dimension6: normalizeProduct?.fabric?.join(','),
            dimension7:
              Number(normalizeProduct.variants[0]?.listPrice) > Number(normalizeProduct.variants[0]?.price)
                ? 'Sale'
                : 'Full Price', // Price Category - 'Full Price', 'Sale' etc.
            quantity: 1,
          };
        }),
      },
    },
  }
  if (window?.dataLayer) {
    window?.dataLayer?.push(detail)
    if (EVENT_DEBUG) eventLog(`Add To Cart 'gtm.ecommerce.AddToCart (custom)`, { detail })
  } else {
    if (EVENT_DEBUG) eventLog(`Data layer details not triggered 'gtm.ecommerce.AddToCart (custom)`, { detail })
  }

  const elevarProducts: ElevarProduct[] = products?.map((product, index) => {
    const normalizeProduct = normalizeBedBuilderProduct(product)
    return {
      id: getDataLayerProductIdBedBuilderForShopify(product, product.variants[0], locale),
      name: product.title || "",
      brand: normalizeProduct?.range?.replace(/-/g, ' ')?.toUpperCase() || "",
      category: products?.map((product) => product.productType)?.toString() || "",
      variant: normalizeProduct?.variants[0]?.option1 || "",
      price: product.variants?.[0]?.price?.toString() || normalizeProduct.variants?.[0]?.price?.toString() || "",
      quantity: "1",
      position: index + 1,
      list: `/${locale}/collections/${normalizeProduct?.range}` || "",
      product_id: getNumberPartFromId(product.id?.toString()),
      variant_id: getNumberPartFromId(product.variants?.[0].id?.toString()) || getNumberPartFromId(normalizeProduct.variants?.[0].id?.toString()),
      compare_at_price: product.variants?.[0]?.compareAtPrice?.toString() || normalizeProduct.variants?.[0]?.compareAtPrice?.toString() || '0.0',
      image: product.featuredImage?.replace(/https?:\/\//, '//') || normalizeProduct.images?.[0]?.url?.replace(/https?:\/\//, '//'),
      dimension2: normalizeProduct?.productType?.toLowerCase() === 'bundle' ? 'Bundle' : 'Product', // Single Product, Bundle or Complete The Look
      dimension3: normalizeProduct.colourway, // Product Colour (comma separated if more than one)
      dimension4: normalizeProduct.colour, // Colour Category
      dimension5: normalizeProduct.variants[0].option1, // Size
      dimension6: normalizeProduct?.fabric?.join(','),
      dimension7:
        Number(normalizeProduct.variants[0]?.listPrice) > Number(normalizeProduct.variants[0]?.price)
          ? 'Sale'
          : 'Full Price', // Price Category - 'Full Price', 'Sale' etc.

    };
  })
  const userProperties = getUserPropertiesFromSessionStorage()
  const ecommerceData: EcommerceData = {
    currencyCode: getCurrenceCodeByLocale(locale),
    add: {
      actionField: {
        list: location.pathname
      },
      products: elevarProducts
    }
  }
  ElevarEvents('dl_add_to_cart', userProperties, ecommerceData)
}

export const addToCartAddonEvent = (
  lineItems: {
    variantId: string | number; quantity: number; customAttributes: {
      key: string;
      value: string;
    }[]
  }[],
  product: Product,
  locale?: string
) => {
  const selectedProducts = getVariantsFromLineItems(lineItems, [product])
  if (EVENT_DEBUG && !window?.dataLayer) {
    infoLog('Add To Cart: No Data Layer found.')
  }

  if (window?.dataLayer) {
    const detail = {
      event: 'ecommerce',
      ecommerceEvent: 'Add To Cart',
      ecommerceEventLabel: lineItems.length, //qty
      google_tag_params: {
        ecomm_pagetype: 'cart',
        ecomm_prodid: selectedProducts?.map((s: any) => getDataLayerProductIdForShopify(s, s.selectedVariant.variant, locale)), // array of all product IDs
        ecomm_category: selectedProducts?.map((s: any) => s.productType), // array of all product categories
        ecomm_totalvalue: selectedProducts
          ?.map((s: any) => s.selectedVariant.price)
          .reduce((prev, next) => prev + next), // total value
      },
      ecommerce: {
        currencyCode: product.price.currencyCode,
        add: {
          products: selectedProducts?.map((s: any) => {
            return {
              name: s.name,
              id: getDataLayerProductIdForShopify(s, s.selectedVariant, locale),
              brand: s.range.replace(/-/g, ' ').toUpperCase(),
              price: s.selectedVariant.price,
              dimension2: product?.productType?.toLowerCase()?.includes('bundle') ? 'Bundle' : 'Product', // Single Product, Bundle or Complete The Look
              dimension3: s.colourway, // Product Colour (comma separated if more than one)
              dimension4: s.colour, // Colour Category
              dimension5: s.selectedVariant?.options?.[0]?.values?.[0]?.label, // Size
              dimension6: s?.fabric?.join(','),
              dimension7:
                Number(s.selectedVariant?.listPrice) > Number(s.selectedVariant?.price) ? 'Sale' : 'Full Price', // Price Category - 'Full Price', 'Sale' etc.
              quantity: 1,
            };
          }),
        },
      },
    }
    window?.dataLayer?.push(detail)
    if (EVENT_DEBUG) eventLog(`Add To Cart 'gtm.ecommerce.AddToCart (custom)`, { detail })
  }
  const products: ElevarProduct[] = selectedProducts?.map((s: any, index) => {
    return {
      id: getDataLayerProductIdForShopify(s, s.selectedVariant, locale),
      name: s.name || "",
      brand: s.range?.replace(/-/g, ' ')?.toUpperCase() || "",
      category: s.productType?.toString() || "",
      variant: s.selectedVariant?.name || "",
      price: s.selectedVariant?.price?.toString() || "",
      quantity: '1',
      position: index + 1,
      list: `/${locale}/collections/${s.range}`,
      product_id: getNumberPartFromId(s.id),
      variant_id: getNumberPartFromId(s.selectedVariant?.id),
      compare_at_price: typeof (s.selectedVariant?.listPrice) === 'number' && !isNaN(s.selectedVariant?.listPrice) ? s.selectedVariant?.listPrice.toString() : '0.0',
      image: s.selectedVariant?.image?.src.replace(/https?:\/\//, '//') || "",
      inventory: "",
      dimension2: product?.productType?.toLowerCase()?.includes('bundle') ? 'Bundle' : 'Product', // Single Product, Bundle or Complete The Look
      dimension3: s.colourway, // Product Colour (comma separated if more than one)
      dimension4: s.colour, // Colour Category
      dimension5: s.selectedVariant?.name || "", // Size
      dimension6: s?.fabric?.join(','),
      dimension7:
        Number(s.selectedVariant?.listPrice) > Number(s.selectedVariant?.price) ? 'Sale' : 'Full Price', // Price Category - 'Full Price', 'Sale' etc.
    };
  }) || []
  const userProperties = getUserPropertiesFromSessionStorage()
  const ecommerceData: EcommerceData = {
    currencyCode: getCurrenceCodeByLocale(locale),
    add: {
      actionField: {
        list: `/${locale}/collections/${product.range}`
      },
      products: products
    }
  }
  ElevarEvents('dl_add_to_cart', userProperties, ecommerceData)
}