import { NewsletterPopupForm } from '@components/common'
import SlideInSidebar from '@components/ui/SlideInSidebar'
import { FC, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import { useUI } from '@components/ui/context'
import { NewsletterFormContent } from '@components/common/NewsletterPopupForm/NewsletterPopupForm'

const NEWSLETTER_POPUP_COOKIE = 'neverShowNewsletterPopup'

interface Props {
  listID: string
  timeout: number
  content: NewsletterFormContent
}

const getUpdatedCookieValue = () => {
  const newsletterCookieJSON = Cookies.get(NEWSLETTER_POPUP_COOKIE)
  if (undefined !== newsletterCookieJSON && newsletterCookieJSON !== 'undefined' && newsletterCookieJSON !== 'true') {
    return JSON.parse(newsletterCookieJSON)
  } else {
    return {
      set: 'false',
      pages: 0,
    }
  }
}

const NewsletterPopup: FC<Props> = ({ listID, content, timeout = 15000 }) => {
  const router = useRouter()
  const [showPopup, setShowPopup] = useState(false)
  const [pagesNavigated, setPagesNavigated] = useState(0)
  const {
    displaySidebar,
    displayModal,
    displayDropdown,
    displaySearch,
    displayMobileNav,
    displayMegaMenu,
    displayFilters,
    displayHelpMenu,
    formActive,
  } = useUI()
  const conditionsMet =
    displaySidebar ||
    displayModal ||
    displayDropdown ||
    displaySearch ||
    displayMobileNav ||
    displayMegaMenu ||
    displayFilters ||
    displayHelpMenu ||
    formActive
      ? false
      : true

  const handleOnClose = () => {
    const updatedCookie = getUpdatedCookieValue()
    if (updatedCookie.set === 'false') {
      updatedCookie.set = 'true'
      Cookies.set(NEWSLETTER_POPUP_COOKIE, JSON.stringify(updatedCookie), { expires: 365 })
    }
    setShowPopup(false)
  }

  const handleOnSuccess = () => {
    const updatedCookie = getUpdatedCookieValue()
    if (updatedCookie.set === 'false') {
      updatedCookie.set = 'true'
      Cookies.set(NEWSLETTER_POPUP_COOKIE, JSON.stringify(updatedCookie), { expires: 365 })
    }
  }

  const handleRouteChange = () => {
    const updatedCookie = getUpdatedCookieValue()
    updatedCookie.pages = (updatedCookie?.pages || 0) + 1
    setPagesNavigated(updatedCookie.pages)
    if (updatedCookie.pages < 4) {
      Cookies.set(NEWSLETTER_POPUP_COOKIE, JSON.stringify(updatedCookie), { expires: 365 })
    }
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      clearTimeout(timer.current)
    }
  }, [router.events])

  const timer = useRef(setTimeout(() => {}, 0))
  useEffect(() => {
    const cookieValue = getUpdatedCookieValue()
    clearTimeout(timer.current)
    if (pagesNavigated >= 3 && cookieValue.set !== 'true' && conditionsMet) {
      timer.current = setTimeout(() => {
        setShowPopup(true)
      }, timeout)
    }

    return () => {
      clearTimeout(timer.current)
    }
  }, [pagesNavigated, conditionsMet, timeout])

  return (
    <SlideInSidebar
      display={showPopup}
      onClose={handleOnClose}
      overlay={false}
      applyScrollLock={false}
      closeButton={false}
      popup={true}
    >
      <NewsletterPopupForm listID={listID} onClose={handleOnClose} onSuccess={handleOnSuccess} content={content} />
    </SlideInSidebar>
  )
}

export default NewsletterPopup
