import AutoComplete from '@components/algolia/AutoComplete'
import CustomSearchBox from '@components/algolia/CustomSearchBox'
import { Close, Search } from '@components/icons'
import SearchClient from '@lib/algolia'
import { getConfigByLocale } from '@lib/locale'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import { Configure, InstantSearch } from 'react-instantsearch-dom'

interface Props {
  handleCloseSearchBar: () => void
  iconType: string
  showSearchBar: boolean
}

const SearchBar: FC<Props> = ({ handleCloseSearchBar, iconType, showSearchBar }) => {
  const router = useRouter()
  const { locale, events } = useRouter()
  const algoliaIndex = getConfigByLocale(locale, 'algoliaProductIndex')
  useEffect(() => {
    document?.getElementById('searchBoxId')?.setAttribute('autofocus', 'true')
  }, [])

  const inputFontSize = iconType === 'desktop' ? '24px' : '20px'
  const inputHeight = iconType === 'desktop' ? '84px' : '72px'

  const [searchValue, setSearchValue] = useState('')

  const handleSearchValue = (e: any) => {
    setSearchValue(e.currentTarget.value)
  }
  const handleReset = () => {
    setSearchValue('')
  }
  const handleSubmit = (e: any) => {
    e.preventDefault()
    const q = searchValue
    router.push(`/search?q=${q}`)

    handleReset()
  }

  useEffect(() => {
    handleReset()
  }, [router])
  useEffect(() => {
    const handleRouteChange = () => {
      handleCloseSearchBar()
    }
    events.on('routeChangeStart', handleRouteChange)
    return () => {
      events.off('routeChangeStart', handleRouteChange)
    }
  }, [events, handleCloseSearchBar])
  return (
    <>
      <div className="bg-lightBg ">
        <div className="relative wrapper">
          <InstantSearch indexName={algoliaIndex} searchClient={SearchClient}>
            <Configure filters="NOT named_tags.search:hidden" hitsPerPage={6} />
            <CustomSearchBox
              onSubmit={handleSubmit}
              onChange={handleSearchValue}
              onReset={handleReset}
              inputId={iconType === 'desktop' ? 'searchBoxDesktopId' : 'searchBoxId'}
              inputHeight={inputHeight}
              inputFontSize={inputFontSize}
            />

            <AutoComplete showSearchBar={showSearchBar} />
          </InstantSearch>
          {searchValue.length === 0 && (
            <>
              {iconType === 'desktop' && (
                <div className="absolute right-68 top-35  tablet:right-64">
                  <Search color="#AFB1B4" />
                </div>
              )}
              {iconType === 'mobile' && (
                <div className="absolute right-60 top-26 flex items-center pl-10 tablet:pl-0 tablet:right-64">
                  <Search color="#AFB1B4" />
                </div>
              )}
            </>
          )}

          <button
            className="block tablet:hidden absolute right-15 top-14 bg-lightBg pl-10 h-44 justify-center tablet:right-0 tablet:top-22 tablet:w-64 laptop:pl-16"
            onClick={handleCloseSearchBar}
          >
            <Close />
          </button>
          <button
            className="hidden tablet:flex absolute right-15 bg-lightBg pl-10 h-44 justify-center tablet:right-28 tablet:top-34 laptop:pl-16"
            onClick={handleCloseSearchBar}
          >
            <Close />
          </button>
        </div>
      </div>
    </>
  )
}

export default SearchBar
