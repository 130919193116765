import { Price } from '@components/common'
import ProductRange from '@components/product/ProductRange'
import { getProductPath } from '@lib/getProductProps'
import processLocale from '@lib/locale'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC } from 'react'

interface Props {
  hit: any
  currencyCode: string
}

const AutoCompleteProductCard: FC<Props> = ({ hit, currencyCode }) => {
  const productTitle = hit?.title?.split('-')?.slice(1)?.toString()?.trim()?.replace(/,/g, '-')
  const { locale } = useRouter()
  const currentLocale = processLocale(locale)
  let minLocalePrice: any = null
  try {
    const metaPrices = JSON.parse(hit?.meta?.my_fields?.prices)
    const localePrices = metaPrices?.[currentLocale]
    const smallestPrice = Math.min(...Object.keys(localePrices).map((p) => localePrices[p].price))
    const smallestPriceSku = Object.keys(localePrices).filter((p) => localePrices[p].price === smallestPrice)?.[0]
    minLocalePrice = localePrices?.[smallestPriceSku]
  } catch (e) {
    //console.log('error', hit?.meta?.my_fields?.prices)
  }

  const productPath = getProductPath(hit.handle, hit.product_type)

  return (
    <Link href={productPath} passHref key={hit.id}>
      <div
        className="ais-Hits-item hover:bg-ecru cursor-pointer h-75 mb-12 laptop:mb-0 max-w-394 grid grid-cols-10 "
        data-sku={hit.sku}
      >
        <div className="col-span-2 w-75 h-75 productImage">
          <Image
            src={hit?.image || '/product-img-placeholder.svg'}
            alt="Picture of search product"
            width={75}
            height={75}
          />
        </div>

        <div className="col-span-8 pt-8 pl-12">
          <div className="w-full flex justify-between pr-0 tablet:pr-16 ">
            <div>
              <div className="pr-10 overflow-ellipsis overflow-hidden ...">
                <ProductRange
                  tags={hit?.tags || []}
                  namedTags={hit?.named_tags}
                  range={hit?.named_tags?.range}
                  className="capitalize body-bold-small flex gap-8 "
                  iconHeight={10}
                  iconWidth={10}
                />
              </div>
              <div className="body-small">{productTitle || hit?.title}</div>
            </div>

            <div>
              <Price
                price={minLocalePrice ? minLocalePrice?.price : hit?.variants_min_price || hit?.price}
                listPrice={minLocalePrice ? minLocalePrice?.compare_at_price : hit?.compare_at_price}
                prefix={true}
                currencyCode={currencyCode}
                className="body-small"
              />
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default AutoCompleteProductCard
