import { ScrollingAnnouncementBar } from '@lib/types/Navigation'
import cn from 'classnames'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import AnnouncementItem from './AnnouncementItem'
interface Props {
  bannerProps: ScrollingAnnouncementBar
}

const TranslatingAnnouncementBar: FC<Props> = ({ bannerProps }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [maxHeight, setMaxHeight] = useState<number | null>(null)
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const itemRefs = useRef<(HTMLDivElement | null)[]>([])

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % bannerProps?.announcement_items.length)
    }, 5000)
  }

  const calculateMaxHeight = useCallback(() => {
    itemRefs.current.forEach((ref) => {
      if (ref) {
        ref.style.display = 'block'
        ref.style.visibility = 'hidden' // hide from view, but still rendered
        ref.style.position = 'absolute' // to stack them, so height is individual and not cumulative
      }
    })

    const tallestHeight = itemRefs.current.reduce(
      (max, ref) => (ref && ref.clientHeight > max ? ref.clientHeight : max),
      0
    )

    // Revert styles back after calculation
    itemRefs.current.forEach((ref) => {
      if (ref) {
        ref.style.display = ''
        ref.style.visibility = ''
        ref.style.position = ''
      }
    })

    return tallestHeight
  }, [])

  const handleMouseEnter = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current)
    }
  }

  const handleMouseLeave = () => {
    startTimer()
  }

  const handleResize = useCallback(() => {
    setMaxHeight(calculateMaxHeight())
  }, [])

  useEffect(() => {
    setMaxHeight(calculateMaxHeight())
  })

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  useEffect(() => {
    startTimer()
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }
    }
  }, [bannerProps?.announcement_items.length])

  if (bannerProps?.announcement_items?.length === 0) return <></>

  return (
    <div
      style={{ height: maxHeight ? `${maxHeight}px` : 'auto' }}
      className="w-full min-h-36 laptop:min-h-34 mx-auto scrollingAnnouncementBar justify-center flex-col flex items-center z-50 relative font-g-regular text-12 leading-20"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {bannerProps?.announcement_items?.map((item, index) => {
        return (
          <div
            ref={(el) => (itemRefs.current[index] = el)}
            className={cn('wrapper text-center justify-center flex items-center laptop:py-7 py-8', {
              fadeIn: currentIndex === index,
              hidden: currentIndex !== index,
            })}
            key={index}
          >
            <AnnouncementItem announcementItem={item} text_colour={bannerProps?.text_colour} />
          </div>
        )
      })}
      <style jsx>{`
        .scrollingAnnouncementBar {
          background: ${bannerProps?.background_colour};
          color: ${bannerProps?.text_colour};
        }
        .fadeIn {
          animation: fadeIn 1s forwards;
        }
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      `}</style>
    </div>
  )
}

export default TranslatingAnnouncementBar
