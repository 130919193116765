import { MiniCart } from '@lib/types/MiniCart'
import cn from 'classnames'
import { FC, useMemo } from 'react'
import s from './SpendAndSave.module.css'

interface SpendAndSaveProgressBarProps {
  cartTotal: number
  values: Value[]
  miniCart: MiniCart
}
export interface Value {
  amount: number
  discount: number
  isCurrent: boolean
  isNext: boolean
  index: number
  isLast: boolean
}

export interface Tier {
  from: number
  baseDiscount: number
}

export const SpendAndSaveProgressBar: FC<SpendAndSaveProgressBarProps> = ({ values, cartTotal, miniCart }) => {
  const endValue = values?.[values.length - 1]?.amount
  const percentage = useMemo(() => {
    if (cartTotal < endValue) {
      return `${(cartTotal / endValue) * 100}%`
    }
    return '100%'
  }, [cartTotal, endValue])
  const currentTierIndex = values.findIndex((value) => cartTotal < value.amount)

  if (!values.length) return null

  const ProgressValues = () =>
    values.map((value, index) => {
      const isBeforeCurrent = index < currentTierIndex
      return (
        <div
          title={`Cart value ${cartTotal}`}
          className={cn(s.progressBarValue)}
          style={
            {
              '--spasv-value-position': `${(value.amount / endValue) * 100}%`,
              backgroundColor: miniCart?.bar_divider_colour,
            } as React.CSSProperties
          }
          key={`spasv-value-${index}`}
        >
          <span
            className={cn(s.progressBarAmount)}
            style={{
              color: isBeforeCurrent ? miniCart?.bar_fill_colour : miniCart?.sub_message_and_price_text_colour,
            }}
          >{`$${value.amount}`}</span>
          <span
            className={cn(s.progressBarDiscount)}
            style={{
              color: isBeforeCurrent ? miniCart?.bar_fill_colour : miniCart?.sub_message_and_price_text_colour,
            }}
          >{`$${value.discount} off`}</span>
        </div>
      )
    })

  return (
    <div className={cn(s.progressBarContainer)}>
      <div className={cn(s.progressBarValuesContainer)} style={{ backgroundColor: miniCart?.bar_background_colour }}>
        <div className={cn(s.progressBarDot)} style={{ backgroundColor: miniCart?.bar_fill_colour }} />

        {cartTotal > 0 && (
          <div
            className={cn(s.progressBarProgress)}
            style={{
              width: `${percentage}`,
              maxWidth: '100%',
              backgroundColor: miniCart?.bar_fill_colour,
            }}
          ></div>
        )}
        {ProgressValues()}
      </div>
    </div>
  )
}
