import type { LineItem } from '@commerce/types/cart'
import { eventLog, EVENT_DEBUG, infoLog } from './general'

declare global {
  interface Window {
    dataLayer: any
  }
}

export const updateCartQuantityEvent = (item: LineItem, qty: number, currencyCode: string) => {
  if (EVENT_DEBUG && !window?.dataLayer) {
    infoLog('Add To Cart: No Data Layer found.')
  }

  if (window?.dataLayer) {
    const detail = {
      event: 'ecommerce',
      ecommerceEvent: 'Add To Cart',
      ecommerceEventLabel: qty, //qty
      google_tag_params: {
        ecomm_pagetype: 'cart',
        ecomm_prodid: [item.productId], // array of all product IDs
        ecomm_category: [], // array of all product categories
        ecomm_totalvalue: item.variant.price, // total value
      },
      ecommerce: {
        currencyCode,
        add: {
          products: [
            {
              name: item.name,
              id: item.variant.sku,
              brand: '',
              price: Number(item.variant.price),
              dimension2: '', // Single Product, Bundle or Complete The Look
              dimension3: '', // Product Colour (comma separated if more than one)
              dimension4: '', // Colour Category
              dimension5: item.options?.find((o) => o.name === 'Size')?.value, // Size
              dimension6: '', // Fabric
              dimension7: Number(item.variant.listPrice) > Number(item.variant.price) ? 'Sale' : 'Full Price', // Price Category - 'Full Price', 'Sale' etc.
              quantity: qty,
            },
          ],
        },
      },
    }
    window?.dataLayer?.push(detail)
    if (EVENT_DEBUG) eventLog(`Add To Cart 'gtm.ecommerce.AddToCart (custom)`, { detail })
  }
}
