import commerceConfig from '@commerce/config'
import { useNavDataLayer } from '@lib/hooks/useNavDataLayer'
import { motion } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import { FC, useState } from 'react'
interface Props {
  navItem: any
  subNavTitle?: string | null
  activeMegaTitle: string
}

const MegaNavigationGroup: FC<Props> = ({ navItem, subNavTitle, activeMegaTitle }) => {
  const {
    config: { defaultAlt },
  } = commerceConfig
  const { primary, items } = navItem || {}
  const { group_title, span_two_columns, featured } = primary || {}
  const { updateNavDataLayer } = useNavDataLayer()
  const [hoveredItemIndex, setHoveredItemIndex] = useState(-1)
  return (
    <div className={`flex flex-col gap-12`}>
      <div className="text-newSage font-g-bold font-bold text-12 leading-16 uppercase tracking-spacingBitWider">
        {group_title}
      </div>
      {featured ? (
        <div className={`grid gap-12 ${items?.length >= 3 ? 'grid-cols-3' : 'grid-cols-2'}`}>
          {items.map((item: any, index: number) => {
            const { title, link, image } = item || {}
            return (
              <div className="col-span-1" key={index}>
                {link && (
                  <Link href={link || '/'} passHref>
                    <div
                      onClick={() =>
                        updateNavDataLayer(
                          title!,
                          group_title,
                          `${activeMegaTitle}${subNavTitle ? ` - ${subNavTitle}` : ''}`,
                          link
                        )
                      }
                      className="relative"
                    >
                      {image && image?.url && (
                        <div className="rounded-16 overflow-hidden">
                          <motion.div
                            className="relative w-160 h-160"
                            whileHover={{ scale: 1.15 }}
                            transition={{ duration: 0.4 }}
                          >
                            <Image
                              fill
                              src={image.url}
                              alt={image?.alt || defaultAlt}
                              sizes="100vw, 100vh"
                              placeholder="blur"
                              blurDataURL={`${image.url}&blur=500`}
                              style={{ objectFit: 'cover' }}
                              priority
                            />
                          </motion.div>
                        </div>
                      )}
                      <div className="absolute w-136 top-10 left-12 text-white break-words font-g-medium font-normal text-16 leading-18 tracking-spacingBitTigher">
                        {title}
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            )
          })}
        </div>
      ) : (
        <div className={`flex gap-6 ${span_two_columns ? 'flex-wrap' : 'flex-col'}`}>
          {items.map((item: any, index: number) => {
            const { title, link, icon, hover_icon } = item || {}
            const isHovered = hoveredItemIndex === index
            return (
              <div className={`${span_two_columns ? 'spanTwoColumns' : ''}`} key={index}>
                {link && (
                  <Link href={link || '/'} passHref>
                    <div
                      onClick={() =>
                        updateNavDataLayer(
                          title!,
                          group_title,
                          `${activeMegaTitle}${subNavTitle ? ` - ${subNavTitle}` : ''}`,
                          link
                        )
                      }
                      onMouseOver={() => setHoveredItemIndex(index)}
                      onMouseOut={() => setHoveredItemIndex(-1)}
                      className="flex gap-6 flex-1 w-full items-center hover:text-hoverGrey text-black"
                    >
                      {icon && icon?.url && (
                        <div className="w-13 h-13 relative">
                          <Image
                            fill
                            src={isHovered ? hover_icon?.url || icon.url : icon.url}
                            alt={icon?.alt || hover_icon?.alt || defaultAlt}
                            sizes="100vw, 100vh"
                            placeholder="blur"
                            blurDataURL={`${icon.url}&blur=500`}
                            style={{ objectFit: 'fill' }}
                          />
                        </div>
                      )}
                      <div className=" font-g-regular font-normal text-12 leading-20 ">{title}</div>
                    </div>
                  </Link>
                )}
              </div>
            )
          })}
        </div>
      )}

      <style jsx>
        {`
          .spanTwoColumns {
            flex-basis: calc(50% - 10px);
          }
        `}
      </style>
    </div>
  )
}

export default MegaNavigationGroup
