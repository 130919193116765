import Price from '@components/common/Price'
import cn from 'classnames'
import { FC } from 'react'
import s from './LoopReturns.module.css'

interface props {
  credit: number
  currencyCode: string
}

const LoopReturnsCreditBar: FC<props> = ({ credit, currencyCode }) => {
  if (credit === 0) {
    return <></>
  }
  return (
    <div className={cn(s.creditBar)}>
      <div className="inline-block mr-5">
        <Price className="font-g-bold" price={credit} currencyCode={currencyCode} prefix={false} />
      </div>
      <span>credit is ready for you to spend and will be applied at checkout</span>
    </div>
  )
}

export default LoopReturnsCreditBar
