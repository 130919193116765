import usePrice from '@commerce/product/use-price'
import { Button } from '@components/ui'
import processLocale, { getConfigByLocale } from '@lib/locale'
import cn from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import { SheetSocietySymbol } from '../../icons'
import { LoopReturnsParams } from './LoopReturns'
import s from './LoopReturns.module.css'

interface props {}

const LOCAL_STORAGE_ON_STORE_PARAMS = `loopOnstoreParams`

const LoopReturnsModal: FC<props> = () => {
  const [open, setOpen] = useState(false)
  const [credit, setCredit] = useState(0)
  const { locale } = useRouter()
  const currentLocale = processLocale(locale)
  const currencyCode = getConfigByLocale(currentLocale, 'defaultCurrencyCode')

  const { price: total } = usePrice({
    amount: Number(credit),
    currencyCode: currencyCode,
  })

  useEffect(() => {
    const localStorageParams = localStorage.getItem(
      `${LOCAL_STORAGE_ON_STORE_PARAMS}${locale == 'en' ? 'en-au' : locale}`
    )
    let paramsObject: LoopReturnsParams = {}
    paramsObject = localStorageParams ? JSON.parse(localStorageParams) : {}
    if (window.location.search.includes('loop_total')) {
      const params = new URLSearchParams(window.location.search)
      const total = Number(params.get('loop_total')) / 100
      setCredit(total)
      setOpen(true)
    }
  }, [locale])

  const closePopup = () => {
    setOpen(false)
  }

  if (!open) {
    return <></>
  }
  return (
    <div className="inset-0 fixed flex tablet:items-center items-end justify-center bg-black bg-opacity-50 z-50 mobile ">
      <div className={cn(s.popupContent, 'text-center bg-white py-40 px-60 rounded-t-16 tablet:rounded-none')}>
        <div className="text-24 leading-36 mb-8">There's credit in your cart</div>
        <div className="text-14 leading-24 mb-16">
          It’ll stay there until you make a purchase.
          <br />
          Happy shopping.
        </div>
        <div className="pt-20 px-20 pb-24 mb-20 flex flex-col justify-center items-center bg-warmWhite rounded-12">
          <SheetSocietySymbol className="block mb-12" />
          <div className="mb-4 text-32 leading-44 text-center">{total}</div>
          <div className="text-12 leading-16 tracking-wider uppercase font-charcoal">Credit in your cart</div>
        </div>
        <Button variant="primary" className="w-full mb-28" onClick={closePopup}>
          Start Browsing
        </Button>
        <div className="text-14 leading-24">
          Having trouble?{' '}
          <Link href={`/help/contact-us`} passHref className="body underline">
            Contact us
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LoopReturnsModal
