import { ChevronDown } from '@components/icons'
import ClickOutside from '@lib/click-outside'
import { disableBodyScroll } from 'body-scroll-lock'
import cn from 'classnames'
import { useEffect, useRef, useState } from 'react'
import s from './DropDown.module.css'

interface option {
  name: string
  value: string
}

interface Props {
  options: option[]
  extraTitle?: string
  arialLabel?: string
  className?: string
  disabled?: boolean
  selectedOption: option
  handleOptionSelection: (selected: option) => void
  applyScrollLock?: boolean
}
const DropDown = ({
  options,
  selectedOption,
  extraTitle,
  arialLabel,
  className,
  handleOptionSelection,
  disabled = false,
  applyScrollLock = false,
}: Props) => {
  const ref = useRef() as React.MutableRefObject<HTMLUListElement>
  const [display, setDisplay] = useState(false)
  const [scrollLock, setScrollLock] = useState(false)

  // note: applying scroll lock for mobile slide in
  // applying scroll lock on parent (sidebar) does not work in ios (iphone safari)
  // as scrolling required on the list of countries not on sidebar
  useEffect(() => {
    const sidebar = ref.current

    if (display && applyScrollLock && !scrollLock) {
      disableBodyScroll(sidebar, {
        reserveScrollBarGap: true,
        allowTouchMove: (el) => {
          while (el && el !== document.body) {
            if (el.getAttribute('data-cart-content') !== null) {
              return true
            }

            el = el.parentElement as HTMLElement
          }
        },
      })
      setScrollLock(true)
    }

    // Note: let parent component slideinsidebar to clear scroll lock
    // so it does not allow scrolling till sidebar close down
    // if (!display && scrollLock) {
    // enableBodyScroll(sidebar)
    // clearAllBodyScrollLocks()
    // setScrollLock(false)
    // }

    return () => {
      setScrollLock(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [display])

  const handleClickOutSide = () => setDisplay(false)
  const handleButtonClick = () => {
    setDisplay(!display)
  }
  if (options.length === 0) return <></>
  return (
    <>
      <ClickOutside active={display} onClick={handleClickOutSide}>
        <div className={cn('dropdown relative', className)}>
          <button
            className={cn('selected h-48 border rounded-2 w-full py-6 pl-16 flex justify-between items-center', {
              'border-charcoal': !disabled,
              ' border-midGrey cursor-not-allowed ': disabled,
            })}
            onClick={handleButtonClick}
            arial-label={arialLabel || 'Dropdown'}
            disabled={disabled}
          >
            <div className="flex body flex-col ">
              {extraTitle && (
                <span
                  className={cn('body-small flex justify-start', {
                    'text-charcoal': !disabled,
                    ' text-doveGrey': disabled,
                  })}
                >
                  {extraTitle}
                </span>
              )}
              <span className={cn('flex justify-start', { 'text-black': !disabled, ' text-doveGrey': disabled })}>
                {selectedOption?.name}
              </span>
            </div>

            <div className="flex items-center">
              <span className="flex items-center h-20 border-l border-lightGrey py-12 pl-8 pr-8 ">
                <ChevronDown width={20} height={20} className={cn(s.icon, { [s.active]: display })} />
              </span>
            </div>
          </button>
          <ul
            ref={ref}
            className={cn(
              'options absolute w-full z-50 h-144 tablet:h-auto overflow-y-scroll border-b border-lightGrey',
              {
                'flex flex-col': display,
                hidden: !display,
              }
            )}
          >
            {options
              .filter((o) => o.name !== selectedOption?.name)
              .map((option, index) => {
                const handleSelection = () => {
                  handleOptionSelection(option)
                  setDisplay(false)
                }
                return (
                  <li
                    key={`${option.value}-${index}`}
                    onClick={handleSelection}
                    className="cursor-pointer body w-full border border-lightGrey bg-white pl-16 p-8 border-t-0"
                  >
                    {option.name}
                  </li>
                )
              })}
          </ul>
        </div>
      </ClickOutside>
      <style jsx>
        {`
          .dropdown {
            width: 394px;
          }
          .options li:last-child {
            border-bottom: 0px;
          }
          @media (max-width: 325px) {
            .dropdown {
              width: 300px;
            }
          }
        `}
      </style>
    </>
  )
}

export default DropDown
