import commerceConfig from '@commerce/config'
import { CaretDown } from '@components/icons'
import { handleChat } from '@lib/gorgias'
import processLocale, { LOCALES_MAP } from '@lib/locale'
import { MobileExtraLink } from '@lib/types/Navigation'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC } from 'react'
interface Props {
  mobile_extra_links: [] | MobileExtraLink[]
  handleCloseNav: () => void
  showCountrySelector?: () => void
  countryFlags?: {
    [key: string]: any
  }[]
}

const MobileExtraLinks: FC<Props> = ({ mobile_extra_links, handleCloseNav, countryFlags, showCountrySelector }) => {
  const {
    config: { defaultAlt },
  } = commerceConfig
  const { locale } = useRouter()
  const currentLocale = processLocale(locale)
  const toggleGorgiasChat = () => {
    handleChat()
    handleCloseNav()
  }
  const currentCountry = countryFlags?.find((country) => country.locale === locale)
  return (
    <div className="">
      <div className="wrapper bg-lightBg">
        {mobile_extra_links &&
          mobile_extra_links.map((linkItem: MobileExtraLink, index: number) => {
            const { mobile_extra_link_text, mobile_extra_link_url, mobile_extra_link_icon } = linkItem
            return (
              <div key={index}>
                {mobile_extra_link_url && (
                  <Link href={mobile_extra_link_url} passHref>
                    <div className=" bg-lightBg  border-b border-white py-12 flex justify-between">
                      <div className="text-16 leading-18 font-g-medium font-normal tracking-spacingBitTigher">
                        {mobile_extra_link_text}
                      </div>

                      {mobile_extra_link_icon && mobile_extra_link_icon.url && (
                        <Image
                          src={mobile_extra_link_icon.url}
                          width={20}
                          height={20}
                          alt={mobile_extra_link_icon.alt || defaultAlt}
                        />
                      )}
                    </div>
                  </Link>
                )}
                {/* {mobile_extra_link_url && mobile_extra_link_url == '/:chat' && (
                  <div
                    onClick={toggleGorgiasChat}
                    className=" bg-lightBg  border-b border-white py-12 flex justify-between"
                  >
                    <div className="text-16 leading-18 font-g-medium font-normal tracking-spacingBitTigher">
                      {mobile_extra_link_text}
                    </div>
                    {mobile_extra_link_icon && mobile_extra_link_icon.url && (
                      <Image
                        src={mobile_extra_link_icon.url}
                        width={20}
                        height={20}
                        alt={mobile_extra_link_icon.alt || defaultAlt}
                      />
                    )}
                  </div>
                )} */}
              </div>
            )
          })}
        <button className="w-full" onClick={showCountrySelector}>
          <div className="bg-lightBg pt-12 pb-28 flex justify-between items-center w-full">
            {currentCountry && (
              <div className="flex gap-6 items-center">
                <div className="w-30 h-22 relative">
                  <Image src={currentCountry?.flag?.url} alt="Country Flag" fill />
                </div>
                <div className=" font-g-regular font-normal text-16 leading-24 tracking-spacingBitTigher text-black">
                  You're on our&nbsp;
                  <u>{LOCALES_MAP[currentLocale.toLowerCase()]?.name} store</u>
                </div>
              </div>
            )}
            <CaretDown width={20} height={20} color="#000" />
          </div>
        </button>
      </div>
    </div>
  )
}

export default MobileExtraLinks
