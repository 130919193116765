import { debounce } from "lodash"

declare global {
  interface Window {
    GorgiasChat: any
  }
}

export const toggleChat = debounce(() => {
  const chatContainer: any = document.querySelector('#gorgias-chat-container')
  const chatButtonIframe = document.getElementById('chat-button')
  if (window?.GorgiasChat && chatContainer && chatButtonIframe) {
    chatButtonIframe.style.display = 'none'
    chatContainer.style.display = 'block'
    window.GorgiasChat?.on('widget:closed', function () {
      chatContainer.style.display = 'none'
    })
    if (window?.GorgiasChat.isOpen()) {
      window?.GorgiasChat.close()
      chatContainer.style.display = 'none'
    } else {
      window?.GorgiasChat.open()
    }
  }
}, 10)

export const handleChatMouseOver = (activeChat: boolean, setActiveChat: (arg: boolean) => void) => {
  if (!activeChat) {
    setActiveChat(true)
  }
}

let callAmount = 0
let timeOut = setTimeout(() => { })
export const handleChat = () => {
  const chatContainer: any = document.querySelector('#gorgias-chat-container')
  if (window?.GorgiasChat && chatContainer) {
    toggleChat()
  } else {
    if (callAmount < 3) {
      timeOut = setTimeout(() => {
        handleChat()
      }, 1000)
      callAmount += 1
    }

    if (callAmount >= 3) {
      clearTimeout(timeOut)
      callAmount = 0
    }
  }

}
