/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from '@components/ui'
import SlideInSidebar from '@components/ui/SlideInSidebar'
import { useAnnouncementCookies } from '@lib/hooks/useAnnouncementCookies'
import useWindowDimensions from '@lib/hooks/useWindowDimension'
import { getLocaleByCountryCode } from '@lib/locale'
import cn from 'classnames'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import CountrySelector from '../CountrySelector'

const CountrySuggestion = () => {
  // TODO : detect the geo location country
  const { locale } = useRouter()
  const [detectedLocale, setDetectedLocale] = useState<string | null>(null)
  const [type, setType] = useState('modal')
  const { width } = useWindowDimensions()
  const { acceptedCookies, hideAnnouncement } = useAnnouncementCookies({
    cookieName: 'CountrySuggestion',
    expires: 7,
  })
  const customerSelection = Cookies.get('NEXT_LOCALE')
  const getCountry = () => {
    const geoCountryCode = Cookies.get('COUNTRY_CODE')

    // if customer already selected country then we do not
    // want to bother with suggestions again.
    // Further logic can be added : if customerSelection does not match the current locale
    // then we can show the suggestion again. Howerver it could be over kill so
    // at this point once customer closes suggestion we do not show them again in the same session
    if (geoCountryCode) {
      const newLocale = getLocaleByCountryCode(geoCountryCode)
      setDetectedLocale(newLocale)
    }
  }

  useEffect(() => {
    const customerSelection = Cookies.get('NEXT_LOCALE')
    if (!acceptedCookies && !customerSelection) {
      getCountry()
    }
  }, [acceptedCookies])

  useEffect(() => {
    const newType = width > 1024 ? 'modal' : 'sidebar'
    if (!detectedLocale) {
      setType(newType)
    }
  }, [width, type])

  const hideSuggestion = () => {
    setDetectedLocale(null)
    hideAnnouncement()
  }

  if (acceptedCookies || customerSelection || locale === detectedLocale) return <></>

  return (
    <>
      {type === 'modal' && detectedLocale && (
        <Modal onClose={hideSuggestion}>
          <CountrySelector onClose={hideSuggestion} suggestion={detectedLocale} />
        </Modal>
      )}
      {type === 'sidebar' && (
        <SlideInSidebar
          onClose={hideSuggestion}
          display={detectedLocale ? true : false}
          orientation="bottom"
          className={cn({
            'translate-y-0': detectedLocale,
            'translate-y-full': !detectedLocale,
          })}
          closeButton={true}
        >
          {detectedLocale && <CountrySelector onClose={hideSuggestion} suggestion={detectedLocale} />}
        </SlideInSidebar>
      )}
    </>
  )
}
export default CountrySuggestion
