import Cookies from 'js-cookie'
import commerceConfig from '@commerce/config'
import processLocale from '@lib/locale';

const getCheckoutId = (locale: string | undefined) => {
  const { config: { context } } = commerceConfig;
  const currentLocale = processLocale(locale);
  const currentContext = context?.[currentLocale];

  return Cookies.get(currentContext.checkoutIdCookieName)
}

export default getCheckoutId
