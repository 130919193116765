import { gql } from '@apollo/client'
import { metafieldListForGraphQl } from '@lib/product'
const getProductQuery = /* GraphQL */ gql`
  query getProductBySlug($slug: String!, $country: CountryCode!) @inContext(country: $country) {
    productByHandle(handle: $slug) {
      id
      handle
      availableForSale
      title
      tags
      productType
      vendor
      description
      tags
      descriptionHtml
      options {
        id
        name
        values
      }
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      compareAtPriceRange {
        minVariantPrice {
          amount
          currencyCode
        }
      }
      tags
      variants(first: 250) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            title
            sku
            availableForSale
            requiresShipping
            weight
            weightUnit
            image {
              altText
              src
            }
            selectedOptions {
              name
              value
            }
            price {
              amount
              currencyCode
            }
            compareAtPrice {
              amount
              currencyCode
            }
            storeAvailability(first: 10) {
              edges {
                node {
                  available
                  location {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
      images(first: 250) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            url(transform: { maxWidth: 1080 })
            altText
            width
            height
          }
        }
      }
      metafields(identifiers: ${metafieldListForGraphQl}) {
        key
        value
        namespace
      }
      media(first: 2) {
        edges {
          node {
            mediaContentType
            alt
            ... on Video {
              sources {
                url
                mimeType
                format
                height
                width
              }
            }
          }
        }
      }
    }
  }
`

export default getProductQuery
