import type { Cart } from '@commerce/types/cart'
import { ChevronLeft, Email, Facebook, LinkIcon, Share } from '@components/icons'
import { Button } from '@components/ui'
import { getIdFromGraphQlId } from '@lib/general'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import s from './ShareMyCart.module.css'

interface props {
  cart: Cart | null
  title?: string
  //Body in the email message
  body?: string
  utmSource?: string
  utmMedium?: string
  utmCampaign?: string
  open: boolean
  onClose: (open: boolean) => void
}
// declare global {
//   interface Navigator {
//     canShare?: any
//   }
// }

const ShareMyCart: FC<props> = ({
  cart,
  title = '',
  body = '',
  utmSource = 'website',
  utmMedium = 'cart',
  utmCampaign = 'sharemycart',
  open = false,
  onClose,
}) => {
  const [copied, setCopied] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const { locale } = useRouter()

  useEffect(() => {
    setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))
  }, [])

  if (!cart) {
    return <></>
  }

  const cartItems: string[] = cart.lineItems.map((item, index) => {
    const properties =
      item.customAttributes?.map((attribute) => {
        return `${attribute.key}:${attribute.value}`
      }) || []
    if (typeof item.variantId !== 'undefined' && item.quantity)
      return `smc=${getIdFromGraphQlId(item.variantId, 'gid://shopify/ProductVariant/')}|${
        item.quantity
      }|${properties.join('||')}`
    return ''
  })

  let UTM_DATA = ''
  if (utmSource && utmMedium && utmCampaign) {
    UTM_DATA = `utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&`
  }

  const shareURL = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}/${locale}` : `/${locale}`
  }?${UTM_DATA}${cartItems.join('&')}`

  const openSharer = () => {
    onClose(true)
  }

  const closeSharer = () => {
    onClose(false)
  }

  const shareOnFacebook = () => {
    window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + shareURL,
      'pop',
      'width=600, height=400, scrollbars=no'
    )
  }

  const shareViaEmail: (e: React.MouseEvent) => void = (e) => {
    if (
      (typeof window.navigator.share !== undefined ||
        ('canShare' in window.navigator &&
          window.navigator.canShare({
            title,
            url: shareURL,
          } as ShareData))) &&
      isMobile
    ) {
      e.preventDefault()
      //NOTE: We are not passing the text: body value in the share as this causes Safari to not show a preview of the url.
      navigator
        .share({
          title,
          url: shareURL,
        } as ShareData)
        .then(() => {
          //Here we can show a message saying thanks for sharing
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }

  const copyLink = () => {
    navigator.clipboard
      .writeText(shareURL)
      .then(
        function () {
          setCopied(true)
          setTimeout(() => {
            setCopied(false)
          }, 2000)
        },
        function (err) {
          console.error('Async: Could not copy text: ', err)
        }
      )
      .catch((error) => console.error(error))
  }

  if (cart.lineItems.length === 0) {
    return <></>
  }
  return (
    <>
      <Button className="absolute top-24 right-20 h-28" noPadding onClick={openSharer} variant="textLink">
        <Share className="mr-8" />
        <span>Share my cart</span>
      </Button>
      <section className={cn(s.shareMyCart, { [s.shareMyCartOpen]: open })}>
        <header className="flex px-20 py-24 items-end justify-between">
          <div className="m-0">
            <span className="font-g-regular text-20 leading-28 mr-8">Share my cart via...</span>
          </div>
          <Button noPadding className="absolute top-24 right-20 h-28" onClick={closeSharer} variant="textLink">
            <ChevronLeft className="mr-8" />
            Back to cart
          </Button>
        </header>
        <div className="p-20 flex flex-col">
          <Button variant="secondary" onClick={shareOnFacebook} className="mb-12">
            Facebook <Facebook className="ml-8" />
          </Button>
          <Button
            Component="a"
            href={`mailto:?subject=${title}&body=${body}%0D%0A${encodeURIComponent(shareURL)}`}
            variant="secondary"
            onClick={shareViaEmail}
            className="mb-12"
          >
            Email <Email className="ml-8" />
          </Button>
          <Button variant="secondary" className={cn({ copied: copied })} onClick={copyLink}>
            {copied ? 'Copied' : 'Copy Link'} <LinkIcon className="ml-8" />
          </Button>
        </div>
      </section>
    </>
  )
}

export default ShareMyCart
