import { GorgiasChatButton } from '@components/icons'
import { useUI } from '@components/ui'
import ClickOutside from '@lib/click-outside'
import { handleChat } from '@lib/gorgias'
import cn from 'classnames'
import { useState } from 'react'
import { useWindowSize } from 'usehooks-ts'
const LiveChatButton = () => {
  const { width } = useWindowSize()
  const { displayModal, displaySidebar, displayMobileNav } = useUI()
  const [chatWindowOpened, setChatWindowOpened] = useState(false)
  const isMobile = width < 768
  const handleChatButtonClick = () => {
    handleChat()
    if (window.GorgiasChat) {
      setChatWindowOpened(!window.GorgiasChat.isOpen())
    }
  }
  if (displayModal || displaySidebar || displayMobileNav) return <></>
  return (
    <>
      <ClickOutside active={chatWindowOpened && !isMobile} onClick={handleChatButtonClick}>
        <button
          aria-label="Chat"
          onClick={handleChatButtonClick}
          id="gorgias_custom_chat_button"
          className={cn('fixed gorgias_chat_button', {
            'bottom-8 right-12': isMobile,
            'bottom-10 right-20': !isMobile,
          })}
        >
          <GorgiasChatButton mobile={isMobile} opened={chatWindowOpened} />
        </button>
      </ClickOutside>
      <style jsx>
        {`
          .gorgias_chat_button {
            z-index: 99;
          }
        `}
      </style>
    </>
  )
}

export default LiveChatButton
