import { FetchPolicy } from '@apollo/client'
import client from '@commerce/client'
import { getAllColourSiblingsProductsForAddonsQuery, getCurrencyCountryCode } from '@commerce/utils'
import { normalizeProduct } from '@commerce/utils/normalize'
import { Product } from '../../schema'

const getColourSiblingsForAddons = async (tag: string | string[], locale: string | undefined, fetchPolicy?: FetchPolicy) => {
  if (!tag || tag === '' || tag.length == 0) return { products: [] }
  if (typeof tag === 'object') {
    // If tag is an array of strings, iterate through each tag
    const productsPromises = (tag as string[]).map(async (singleTag) => {
      const queryOptions = {
        query: getAllColourSiblingsProductsForAddonsQuery,
        variables: { query: `tag:'{{${singleTag}}}'`, country: getCurrencyCountryCode(locale) },
        context: { locale },
      }
      const { data } = await client.query(fetchPolicy ? { ...queryOptions, fetchPolicy } : queryOptions)
      return data.products.edges.map(({ node }: any) => normalizeProduct(node as Product))
    })
    const allProducts = await Promise.all(productsPromises)
    const flattenedProducts = allProducts.flat()
    return { products: flattenedProducts }
  }

  if (typeof tag == 'string') {
    let queryOptions = {
      query: getAllColourSiblingsProductsForAddonsQuery,
      variables: { query: `tag:'{{${tag}}}'`, country: getCurrencyCountryCode(locale) },
      context: { locale },
    }

    const { data } = await client.query(fetchPolicy ? { ...queryOptions, fetchPolicy } : queryOptions)

    return {
      products: data.products.edges.map(({ node }: any) => {
        return normalizeProduct(node as Product)
      }),
    }
  }

}

export default getColourSiblingsForAddons
