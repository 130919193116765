import { slice } from '@lib/prismic'
import { SubNavigationItem } from '@lib/types/Navigation'
import { FC, useEffect, useState } from 'react'
import MegaNavigationColumn from './MegaNavigationColumn'
import SubNavigation from './SubNavigation'

interface Props {
  navItems: slice[]
  activeMegaTitle: string
  subNavigation: SubNavigationItem[]
  subMenuHoverIndex: number
  handleActiveSubNav: (a: number) => void
  handleSubNavOut: (activeIndex: number) => void
}

const MegaNavigation: FC<Props> = ({
  navItems,
  activeMegaTitle,
  subNavigation,
  subMenuHoverIndex,
  handleActiveSubNav,
  handleSubNavOut,
}) => {
  const [navColumns, setNavColumns] = useState<slice[][]>()
  const [subNavItems, setSubNavItems] = useState<slice[][]>()
  const [subNavTitle, setSubNavTitle] = useState<string | null>('')
  const groupNavItemsToColumn = (items: slice[] | undefined) => {
    if (!items) return []
    const result = []
    let tempGroup = []
    for (let i = 0; i < items.length; i++) {
      const obj = items[i]

      if (obj.primary.append_to_previous_group && tempGroup.length > 0) {
        tempGroup.push(obj)
      } else {
        tempGroup = [obj]
        result.push(tempGroup)
      }
    }

    return result
  }
  useEffect(() => {
    const navColumns = groupNavItemsToColumn(navItems)
    setNavColumns(navColumns)
  }, [navItems, subMenuHoverIndex])
  useEffect(() => {
    const subNavColumns = groupNavItemsToColumn(subNavigation[subMenuHoverIndex]?.body)
    setSubNavItems(subNavColumns)
    setSubNavTitle(subNavigation[subMenuHoverIndex]?.sub_navigation_title)
  }, [navItems, subMenuHoverIndex, subNavigation])
  return (
    <div className="w-full  z-50 bg-white megaNavigation relative">
      <SubNavigation
        navItem={subNavigation}
        activeMegaTitle={activeMegaTitle}
        navProps={{ subMenuHoverIndex, handleActiveSubNav, handleSubNavOut }}
      />
      <div className="flex gap-20 pt-32 pb-36 wrapper h-full min-h-252">
        <div className="flex gap-32 w-full flex-1">
          {navColumns &&
            navColumns.map((item, index) => {
              return <MegaNavigationColumn activeMegaTitle={activeMegaTitle} key={index} navItem={item} />
            })}
          {subNavItems &&
            subNavItems.map((item, index) => {
              return (
                <MegaNavigationColumn
                  activeMegaTitle={activeMegaTitle}
                  subNavTitle={subNavTitle}
                  key={index}
                  navItem={item}
                />
              )
            })}
        </div>
      </div>
      <style jsx>
        {`
          .megaNavigation {
            box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.08);
          }
        `}
      </style>
    </div>
  )
}

export default MegaNavigation
