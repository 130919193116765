/* eslint-disable react-hooks/exhaustive-deps */
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'

interface Props {
  cookieName: string,
  expires?: number,
}

export const useAnnouncementCookies = ({ cookieName, expires }: any) => {
  const [acceptedCookies, setAcceptedCookies] = useState(false)


  useEffect(() => {
    if (Cookies.get(cookieName)) {
      setAcceptedCookies(true)
    }
  })

  const hideAnnouncement = () => {
    setAcceptedCookies(true)
    Cookies.set(cookieName, 'true', { expires: expires || 365 })
  }

  return {
    acceptedCookies,
    hideAnnouncement,
  }
}
