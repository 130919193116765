import { Discount } from '@commerce/types/common'
import { BedBuilderProduct } from '@components/builder/declarations/types'
import { colorMap } from '@lib/colors'
import {
  COLOURWAY_TAG_PREFIX,
  COLOUR_TAG_PREFIX,
  FABRIC_TAG_PREFIX,
  RANGE_TAG_PREFIX,
  TYPE_TAG_PREFIX,
} from '@lib/product'
import { Hit } from 'react-instantsearch-core'
import {
  Checkout,
  CheckoutLineItemEdge,
  ImageConnection,
  Maybe,
  Metafield,
  MoneyV2,
  ProductOption,
  ProductVariantConnection,
  SelectedOption,
  Product as ShopifyProduct,
  StoreAvailabilityConnection,
} from '../schema'

import type { Cart, LineItem } from '../types/cart'
import type { Field, Product, SiblingProduct, Store } from '../types/product'

type Result = {
  [key: string]: Store
}

const money = ({ amount, currencyCode }: MoneyV2) => {
  return {
    value: +amount,
    currencyCode,
  }
}

const normalizeProductOption = ({ id, name: displayName, values }: ProductOption) => {
  return {
    __typename: 'MultipleChoiceOption',
    id,
    displayName: displayName.toLowerCase(),
    values: values.map((value) => {
      let output: any = {
        label: value,
      }
      if (displayName.match(/colou?r/gi)) {
        const mapedColor = colorMap[value.toLowerCase().replace(/ /g, '')]
        if (mapedColor) {
          output = {
            ...output,
            hexColors: [mapedColor],
          }
        }
      }
      return output
    }),
  }
}

const normalizeProductImages = ({ edges }: ImageConnection) =>
  edges?.map(({ node: { url, ...rest } }) => ({
    url,
    ...rest,
  }))

const normalizeStoreAvailability = ({ edges }: StoreAvailabilityConnection) => {
  const result = {} as Result
  edges?.forEach(({ node: { location, ...rest } }) => {
    result[location.name] = { location, ...rest }
  })

  return result
}

export const normalizeAvailability: (
  availableForSale: boolean,
  storeAvailability: Result,
  locations?: string[]
) => boolean = (
  availableForSale,
  storeAvailability,
  locations = ['Brunswick Warehouse', 'Brunswick Warehouse (Pre-Orders)']
) => {
    const storeAvailabilityStatus = storeAvailability

    if (storeAvailabilityStatus && Object.keys(storeAvailabilityStatus).length > 0) {
      const isAvailableInAtLeastOneLocation = locations.find((location) => {
        return storeAvailabilityStatus[location]?.available ? true : false
      })
      return isAvailableInAtLeastOneLocation ? true : false
    }

    return availableForSale
  }

const normalizeProductVariants = ({ edges }: ProductVariantConnection) => {
  return edges?.map(
    ({
      node: {
        id,
        selectedOptions,
        sku,
        title,
        price,
        compareAtPrice,
        requiresShipping,
        availableForSale,
        image,
        weight,
        weightUnit,
        storeAvailability,
      },
    }) => {
      const normalizedStoreAvailability = normalizeStoreAvailability(storeAvailability)
      return {
        id,
        name: title,
        sku: sku ?? id,
        price: +price.amount,
        listPrice: +compareAtPrice?.amount,
        requiresShipping,
        availableForSale: normalizeAvailability(availableForSale, normalizedStoreAvailability),
        weight: weight,
        weightUnit: weightUnit,
        storeAvailability: normalizedStoreAvailability,
        image: image
          ? {
            altText: image.altText,
            src: image.src,
          }
          : null,
        options: selectedOptions.map(({ name, value }: SelectedOption) => {
          const options = normalizeProductOption({
            id,
            name,
            values: [value],
          })

          return options
        }),
      }
    }
  )
}

export function normalizeMetafields(metafields: Maybe<Metafield>[]) {
  if (!metafields) return []
  const fields: Field[] = []
  metafields
    ?.filter((m: any) => m)
    .forEach((m: any) => {
      fields.push({
        key: m.key,
        value: m.value,
      })
    })
  return fields
}

export function normalizeProduct({
  id,
  title: name,
  vendor,
  images,
  variants,
  description,
  descriptionHtml,
  handle,
  priceRange,
  compareAtPriceRange,
  options,
  metafields,
  tags,
  ...rest
}: ShopifyProduct): Product {
  return {
    id,
    name,
    vendor,
    path: `/product/${handle}`,
    handle,
    slug: handle?.replace(/^\/+|\/+$/g, ''),
    price: money(priceRange?.minVariantPrice),
    listPrice: money(compareAtPriceRange?.minVariantPrice),
    images: normalizeProductImages(images),
    colour: tags?.filter((item) => item.startsWith(COLOUR_TAG_PREFIX))?.[0]?.replace(COLOUR_TAG_PREFIX, '') || '',
    colourway:
      tags?.filter((item) => item.startsWith(COLOURWAY_TAG_PREFIX))?.[0]?.replace(COLOURWAY_TAG_PREFIX, '') || '',
    fabric:
      tags?.filter((item) => item.startsWith(FABRIC_TAG_PREFIX))?.map((i) => i.replace(FABRIC_TAG_PREFIX, '')) || [],
    type: tags?.filter((item) => item.startsWith(TYPE_TAG_PREFIX))?.[0]?.replace(TYPE_TAG_PREFIX, '') || '',
    range: tags?.filter((item) => item.startsWith(RANGE_TAG_PREFIX))?.[0]?.replace(RANGE_TAG_PREFIX, '') || '',
    tags,
    variants: variants ? normalizeProductVariants(variants) : [],
    metafields: metafields ? normalizeMetafields(metafields) : [],
    options: options
      ? options
        .filter((o) => o.name !== 'Title') // By default Shopify adds a 'Title' name when there's only one option. We don't need it. https://community.shopify.com/c/Shopify-APIs-SDKs/Adding-new-product-variant-is-automatically-adding-quot-Default/td-p/358095
        .map((o) => normalizeProductOption(o))
      : [],
    ...(description && { description }),
    ...(descriptionHtml && { descriptionHtml }),
    ...rest,
  }
}

export function normalizeBedBuilderProduct({
  id,
  title: name,
  handle,
  productType,
  price,
  featuredImage,
  compareAtPrice,
  variants,
  tags,
  ...rest
}: BedBuilderProduct): any {
  return {
    id,
    name,
    vendor: null,
    path: `/product/${handle}`,
    handle,
    slug: handle?.replace(/^\/+|\/+$/g, ''),
    price: price,
    listPrice: compareAtPrice,
    images: [
      {
        url: featuredImage,
      },
    ],
    colour: tags?.filter((item) => item.startsWith(COLOUR_TAG_PREFIX))?.[0]?.replace(COLOUR_TAG_PREFIX, '') || '',
    colourway:
      tags?.filter((item) => item.startsWith(COLOURWAY_TAG_PREFIX))?.[0]?.replace(COLOURWAY_TAG_PREFIX, '') || '',
    fabric:
      tags?.filter((item) => item.startsWith(FABRIC_TAG_PREFIX))?.map((i) => i.replace(FABRIC_TAG_PREFIX, '')) || [],
    type: tags?.filter((item) => item.startsWith(TYPE_TAG_PREFIX))?.[0]?.replace(TYPE_TAG_PREFIX, '') || '',
    range: tags?.filter((item) => item.startsWith(RANGE_TAG_PREFIX))?.[0]?.replace(RANGE_TAG_PREFIX, '') || '',
    tags,
    variants,
    ...rest,
  }
}

export function normalizeColourSiblingProduct({
  handle,
  tags,
  availableForSale,
  productType,
  ...rest
}: ShopifyProduct): SiblingProduct {
  return {
    path: `/product/${handle}`,
    handle,
    slug: handle?.replace(/^\/+|\/+$/g, ''),
    colour: tags?.filter((item) => item.startsWith(COLOUR_TAG_PREFIX))?.[0]?.replace(COLOUR_TAG_PREFIX, '') || '',
    colourway:
      tags?.filter((item) => item.startsWith(COLOURWAY_TAG_PREFIX))?.[0]?.replace(COLOURWAY_TAG_PREFIX, '') || '',
    availableForSale,
    productType,
    ...rest,
  }
}

export function normalizeCart(checkout: Checkout): Cart {
  return {
    id: checkout.id,
    url: checkout.webUrl,
    customerId: '',
    email: '',
    createdAt: checkout.createdAt,
    currency: {
      code: checkout.totalPrice?.currencyCode,
    },
    taxesIncluded: checkout.taxesIncluded,
    lineItems: checkout.lineItems?.edges.map(normalizeLineItem),
    lineItemsSubtotalPrice: +checkout.subtotalPrice?.amount,
    subtotalPrice: +checkout.subtotalPrice?.amount,
    totalPrice: checkout.totalPrice?.amount,
    discounts: [],
  }
}

function normalizeLineItem({
  node: { id, title, variant, quantity, customAttributes, discountAllocations },
  node,
}: CheckoutLineItemEdge): LineItem {
  const discounts: Discount[] = []
  if (discountAllocations.length > 0) {
    discountAllocations.map((discountAllocation) => {
      discounts.push({
        allocatedAmount: Number(discountAllocation.allocatedAmount.amount),
        discountApplication: discountAllocation.discountApplication,
      })
    })
  }
  const preOrder = variant?.product.tags.find((tag) => tag.includes(`${variant.sku} SHIPS`))
  return {
    id,
    variantId: String(variant?.id),
    productId: String(variant?.product?.id),
    tags: variant?.product?.tags ? variant.product.tags : [],
    customAttributes: customAttributes ? customAttributes : [],
    preorder: preOrder ? preOrder : '',
    name: `${title}`,
    type: variant?.product.productType || '',
    quantity,
    variant: {
      id: String(variant?.id),
      sku: variant?.sku ?? '',
      name: variant?.title!,
      image: {
        url: variant?.image?.url || '/product-img-placeholder.svg',
      },
      requiresShipping: variant?.requiresShipping ?? false,
      price: variant?.price?.amount,
      listPrice: variant?.compareAtPrice?.amount,
    },
    range: variant?.product?.tags?.filter((item) => item.startsWith(RANGE_TAG_PREFIX))?.[0]?.replace(RANGE_TAG_PREFIX, '') || '',
    path: String(variant?.product?.handle),
    discounts: discounts,
    options: variant?.title == 'Default Title' ? [] : variant?.selectedOptions,
  }
}

export const normalizeHit = (hit: Hit) => {
  const { objectID, id, title, image, handle, tags, meta, compare_at_price, price, variants_min_price, named_tags, product_type, variant_title } =
    hit
  return { id, objectID, title, image, handle, tags, meta, compare_at_price, price, variants_min_price, named_tags, product_type, variant_title }
}
