const SheetSocietySymbol = ({ ...props }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14 0.000183105C11.2311 0.000183105 8.52432 0.821267 6.22203 2.35961C3.91974 3.89794 2.12532 6.08444 1.06569 8.64261C0.00606631 11.2008 -0.27118 14.0157 0.269011 16.7314C0.809201 19.4472 2.14257 21.9417 4.1005 23.8997C6.05843 25.8576 8.55299 27.191 11.2687 27.7312C13.9845 28.2714 16.7994 27.9941 19.3576 26.9345C21.9157 25.8749 24.1022 24.0805 25.6406 21.7782C27.1789 19.4759 28 16.7692 28 14.0002C28 12.1617 27.6379 10.3412 26.9343 8.64264C26.2308 6.94408 25.1995 5.40073 23.8995 4.1007C22.5995 2.80068 21.0561 1.76944 19.3576 1.06588C17.659 0.362308 15.8385 0.000184534 14 0.000183105ZM8.74706 9.56009C8.74706 9.49827 8.75924 9.43706 8.78289 9.37995C8.80654 9.32284 8.84121 9.27095 8.88492 9.22724C8.92863 9.18353 8.98052 9.14886 9.03763 9.1252C9.09474 9.10154 9.15596 9.08937 9.21777 9.08937H18.7823C18.8441 9.08937 18.9053 9.10154 18.9624 9.1252C19.0195 9.14886 19.0714 9.18353 19.1151 9.22724C19.1588 9.27095 19.1935 9.32284 19.2171 9.37995C19.2408 9.43706 19.253 9.49827 19.253 9.56009V12.3801H18.8684C18.8684 12.3801 18.3887 11.424 16.6678 11.424H11.3323C9.61138 11.424 9.13164 12.3801 9.13164 12.3801H8.74706V9.56009ZM21.5763 17.38V18.911H20.2889V17.38H7.71109V18.911H6.42376V14.2425C6.42375 14.0884 6.48497 13.9406 6.59393 13.8316C6.70289 13.7226 6.85069 13.6614 7.00479 13.6614H20.9952C21.0715 13.6614 21.1471 13.6764 21.2176 13.7056C21.2881 13.7348 21.3522 13.7776 21.4061 13.8316C21.4601 13.8856 21.5029 13.9496 21.5321 14.0201C21.5613 14.0906 21.5763 14.1662 21.5763 14.2425L21.5763 17.38Z"
        fill="black"
      />
    </svg>
  )
}

export default SheetSocietySymbol
