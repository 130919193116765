import * as React from "react"

function Email({ ...props }) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.125 2H1.875C.84 2 0 2.896 0 4v12c0 1.104.84 2 1.875 2h16.25C19.16 18 20 17.104 20 16V4c0-1.104-.84-2-1.875-2zM1.875 3.333h16.25c.344 0 .625.3.625.667v1.725c-.855.77-2.078 1.833-5.883 5.054-.66.559-1.96 1.904-2.867 1.888-.906.016-2.21-1.33-2.867-1.888C3.328 7.56 2.105 6.496 1.25 5.725V4c0-.367.281-.667.625-.667zm16.25 13.334H1.875c-.344 0-.625-.3-.625-.667V7.458c.89.78 2.297 1.984 5.105 4.363C7.156 12.504 8.57 14.008 10 14c1.422.012 2.824-1.48 3.645-2.18 2.808-2.378 4.214-3.582 5.105-4.362V16c0 .367-.281.667-.625.667z"
        fill="#000"
      />
    </svg>
  )
}

export default Email
