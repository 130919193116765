import commerceConfig from '@commerce/config'
import { Cart } from '@commerce/types/cart'
import { Product, ProductVariant } from '@commerce/types/product'
import { CloseWithBackground } from '@components/icons'
import { Button, WYSIWYG, useUI } from '@components/ui'
import { Addon } from '@lib/types/MiniCart'
import cn from 'classnames'
import Image from 'next/legacy/image'
import { useRouter } from 'next/router'
import React, { CSSProperties, FC, useCallback, useEffect, useRef, useState } from 'react'
import s from './Addon.module.css'
import AddonItemOptions from './AddonItemOptions'
import AddonItemSizeOnlyOptions from './AddonItemSizeOnlyOptions'
import AddonPrice from './AddonPrice'
import { getAddonCartLineItem } from './AddonUtils'
interface AddonProps {
  addToCart: (variant: SelectedVariantProps, addon: Addon, product: Product) => void
  removeFromCart: (addon: Addon) => void
  dismissAddon: (addon: Addon) => void
  loading: boolean
  data: Addon
  cart: Cart
}
export interface SelectedVariantProps {
  variant: ProductVariant
  quantity: number
  displayLabel: string
}

const AddonItem: FC<AddonProps> = ({ data, loading, addToCart, removeFromCart, dismissAddon, cart }) => {
  const {
    products,
    addon_title,
    addon_unselected_cta,
    addon_text_color,
    addon_bg_colour,
    thumbnail,
    product_url_path,
    show_all_colours,
    pre_select_product,
    show_size_options,
    pre_select_variant_id,
    allow_dismiss,
    index,
  } = data
  const {
    config: { defaultAlt },
  } = commerceConfig
  const { locale } = useRouter()
  const titleRef = useRef<HTMLDivElement>(null)
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>()
  const [selectedVariant, setSeletedVariant] = useState<SelectedVariantProps | undefined>()
  const [defaultProductImageUrl, setDefaultProductImageUrl] = useState<string>()
  const [defaultProduct, setDefaultProduct] = useState<Product | undefined>()
  const { closeSidebarIfPresent } = useUI()
  const lineItem = getAddonCartLineItem(data, cart)
  const added = lineItem ? true : false
  const isVideo = thumbnail?.url?.includes('.mp4')
  const handleRemoveFromCart = useCallback(
    (e: React.MouseEvent) => {
      removeFromCart(data)
      setSelectedProduct(undefined)
      setSeletedVariant(undefined)
    },
    [removeFromCart, data]
  )
  const handleAddToCart = useCallback(
    (e: React.MouseEvent) => {
      if (selectedVariant) {
        addToCart(selectedVariant, data, selectedProduct!)
      }
    },
    [addToCart, selectedVariant, data]
  )

  const handleDismiss = () => {
    dismissAddon(data)
  }

  useEffect(() => {
    if (addon_title && titleRef.current) {
      const links = titleRef.current.querySelectorAll('a')
      if (links.length > 0) {
        ;[...links].map((link) => {
          link.addEventListener('click', closeSidebarIfPresent)
        })
      }
    }
  }, [addon_title, titleRef, closeSidebarIfPresent])
  useEffect(() => {
    if (products && products?.length == 1) {
      setSelectedProduct(products?.[0])
      products?.[0].variants.length === 1 &&
        setSeletedVariant({ variant: products?.[0]?.variants?.[0], quantity: 1, displayLabel: '' })
    }
    return () => {
      setSelectedProduct(undefined)
      setSeletedVariant(undefined)
    }
  }, [products, lineItem])
  const addToCartReady = added || (selectedProduct && selectedVariant)

  return (
    <li
      className={cn(s.root, {
        [s.light]: !addon_text_color,
        [s.dark]: addon_text_color,
      })}
      style={
        {
          '--addon-bg': addon_bg_colour || 'rgba(255, 255, 255, 0)',
        } as CSSProperties
      }
    >
      {!!allow_dismiss && (
        <button onClick={handleDismiss} className="absolute -top-12 -right-3">
          <CloseWithBackground />
        </button>
      )}
      <div className={cn(s.content)}>
        <div className={cn(s.thumb)}>
          {isVideo ? (
            <video loop={true} muted autoPlay={true} playsInline={true}>
              <source src={thumbnail?.url} type="video/mp4" />
            </video>
          ) : (
            <Image
              src={
                lineItem?.variant?.image?.url ??
                thumbnail?.url ??
                selectedProduct?.images?.[0]?.url ??
                defaultProductImageUrl ??
                '/product-img-placeholder.svg'
              }
              alt={thumbnail?.alt ?? selectedProduct?.images?.[0]?.alt ?? defaultAlt}
              width={72}
              height={72}
              layout="responsive"
              placeholder="blur"
              blurDataURL="/product-img-placeholder.svg"
            />
          )}
        </div>
        <div className={cn(s.details)} ref={titleRef}>
          {addon_title && <WYSIWYG content={addon_title} />}
          <AddonPrice
            lineItem={lineItem}
            product={selectedProduct || defaultProduct}
            prefix={!selectedVariant && !lineItem}
            quantity={selectedVariant?.quantity || 1}
            variantID={(selectedVariant?.variant?.id as string) || (defaultProduct?.variants?.[0]?.id as string)}
          />
        </div>
      </div>
      {/* Colour & Size CTA: executes when ther is list of products avaialbe. */}
      <div className={cn(`${!added && products && products?.length > 1 ? 'block' : 'hidden'}`)}>
        <AddonItemOptions
          setDefaultProduct={setDefaultProduct}
          setDefaultImage={setDefaultProductImageUrl}
          textTheme={addon_text_color ? 'dark' : 'light'}
          setProduct={setSelectedProduct}
          setVariant={setSeletedVariant}
          products={products!}
          selectedProduct={selectedProduct}
          selectedVariant={selectedVariant}
          product_url_path={product_url_path!}
          show_all_colours={show_all_colours}
          pre_select_product={pre_select_product!}
          showSizeOptions={show_size_options}
          key={`addon-item-options-${index}`}
        />
      </div>
      {/* Sizeonly CTA and add to cart : one product but more variants */}
      <div
        className={cn(
          `${!added && products && products?.length === 1 && products[0]?.variants.length > 1 ? 'block' : 'hidden'}`
        )}
      >
        <AddonItemSizeOnlyOptions
          textTheme={addon_text_color ? 'dark' : 'light'}
          setVariant={setSeletedVariant}
          selectedProduct={selectedProduct}
          selectedVariant={selectedVariant}
          addon_unselected_cta={addon_unselected_cta!}
          addToCartReady={addToCartReady}
          handleAddToCart={handleAddToCart}
          loading={loading}
          showSizeOptions={show_size_options}
          preSelectVariantId={pre_select_variant_id}
          preSelectOption={pre_select_product!}
          key={`aisoo-${index}-${selectedProduct?.id}`}
        />
      </div>
      {/* fallback add to cart : does not execute when one product but more variants else this displays*/}
      {addon_unselected_cta && products && (products?.length > 1 || products?.[0].variants.length == 1) && (
        <Button
          onClick={added ? handleRemoveFromCart : handleAddToCart}
          type="button"
          size="small"
          disabled={!addToCartReady}
          loading={loading}
          className={cn(s.button)}
        >
          {addon_unselected_cta}
        </Button>
      )}
    </li>
  )
}

export default AddonItem
