const StickyLogo = ({ color = '#000', ...props }) => {
  return (
    <>
      {props.type === 'mobile' && (
        <svg width={32} height={32} fill="#000" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            d="M16 0a16 16 0 100 32 16 16 0 000-32zM9.997 10.926a.54.54 0 01.538-.538h10.93a.539.539 0 01.538.538v3.223h-.44s-.547-1.093-2.514-1.093H12.95c-1.967 0-2.515 1.093-2.515 1.093h-.44v-3.223zm14.662 8.937v1.75h-1.472v-1.75H8.813v1.75H7.34v-5.336a.664.664 0 01.664-.664h15.99a.664.664 0 01.664.664v3.586z"
            fill={color}
          />
        </svg>
      )}

      {props.type === 'desktop' && (
        <svg width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            d="M14 0a14 14 0 100 28 14 14 0 000-28zM8.747 9.56a.47.47 0 01.47-.47h9.565a.47.47 0 01.471.47v2.82h-.385s-.48-.956-2.2-.956h-5.336c-1.72 0-2.2.956-2.2.956h-.385V9.56zm12.83 7.82v1.53h-1.288v-1.53H7.71v1.53H6.424v-4.668a.581.581 0 01.58-.58h13.991a.581.581 0 01.581.58v3.138z"
            fill={color}
          />
        </svg>
      )}
    </>
  )
}
export default StickyLogo
