import fullClient from 'algoliasearch'
import algoliasearch from 'algoliasearch/lite'
import qs from 'qs'
import * as R from 'ramda'
const appId = process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID!
const apiKey = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY!
const serverApiKey = process.env.ALGOLIA_SERVER_API_KEY!

// create algolia search client
export const SearchClient = algoliasearch(appId, apiKey)
export const ServerSearchClient = algoliasearch(appId, serverApiKey)
export const algoliaClient = () => fullClient(appId, serverApiKey)

export const searchStateToURL = (searchState: any, page?: number) => {
  if (searchState) {
    const selectiveState: any = {}
    Object.keys(searchState).forEach((key) => {
      if (key === 'refinementList') {
        const refinements = R.reject(R.equals(''))(searchState[key])
        if (Object.keys(refinements).length) selectiveState[key] = refinements
      } else if (key === 'p' && searchState[key] > 1) {
        selectiveState[key] = searchState[key]
      } else if (key === 'q') {
        selectiveState[key] = searchState[key]
      }
    })
    if (page && page > 1) selectiveState['p'] = page

    return Object.keys(selectiveState).length ? `?${qs.stringify(selectiveState)}` : ''
  }

  return ''
}
export const debounceTime = 700
export const createURL = (state: any) => `?${qs.stringify(state)}`
export const pathToSearchState = (path: string) =>
  path.includes('?') ? qs.parse(path.substring(path.indexOf('?') + 1)) : {}

export default SearchClient
