import { CaretRight } from '@components/icons'
import { useNavDataLayer } from '@lib/hooks/useNavDataLayer'
import { SubNavigationItem, TopLevelNavigationItem } from '@lib/types/Navigation'
import cn from 'classnames'
import Link from 'next/link'
import { FC } from 'react'
import MobileNavItem from './MobileNavItem'
interface Props {
  navItems?: TopLevelNavigationItem
  handleOpenSubNav: (index: number) => void
  subNavItems?: SubNavigationItem
  activeMegaTitle: string
}

const MobileNavComponent: FC<Props> = ({ navItems, handleOpenSubNav, subNavItems, activeMegaTitle }) => {
  const { updateNavDataLayer } = useNavDataLayer()
  const { body, sub_navigation } = navItems || {}
  const { body: SubNavSlices, sub_navigation_title } = subNavItems || {}
  return (
    <div className="overflow-hidden bg-ecru z-0">
      {body && body.length > 0 && sub_navigation && sub_navigation.length == 0 && (
        <div className="wrapper pt-20 pb-32 flex flex-col gap-20">
          {body.map((item, index) => {
            const { primary, items, slice_type } = item || {}
            const { group_title } = primary || {}
            return (
              <div key={index} className="flex flex-col gap-8">
                {group_title && (
                  <div className=" text-newSage font-g-bold text-12 leading-16 uppercase tracking-spacingBitWider">
                    {group_title}
                  </div>
                )}
                {items && (
                  <div
                    className={cn('scrollbar-hide', {
                      smallTiles: slice_type === 'small_tiles_group',
                      mediumTiles: slice_type === 'medium_tiles_group',
                      textLinks: slice_type === 'text_links_group',
                      buttonLinks: slice_type === 'button_links_group',
                    })}
                  >
                    {items.map((item: any, index: number) => {
                      return (
                        <MobileNavItem
                          navItem={item}
                          slice_type={slice_type!}
                          key={index}
                          activeMegaTitle={activeMegaTitle}
                          groupTitle={group_title}
                        />
                      )
                    })}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )}
      {SubNavSlices && SubNavSlices.length > 0 && (
        <div className="wrapper pt-20 pb-32 flex flex-col gap-20">
          {SubNavSlices.map((item, index) => {
            const { primary, items, slice_type } = item || {}
            const { group_title, hide_on_mobile } = primary || {}
            if (hide_on_mobile) return <></>
            return (
              <div key={index} className="flex flex-col gap-8">
                {group_title && (
                  <div className=" text-newSage font-g-bold text-12 leading-16 uppercase tracking-spacingBitWider">
                    {group_title}
                  </div>
                )}
                {items && (
                  <div
                    className={cn('scrollbar-hide', {
                      smallTiles: slice_type === 'small_tiles_group',
                      mediumTiles: slice_type === 'medium_tiles_group',
                      textLinks: slice_type === 'text_links_group',
                      buttonLinks: slice_type === 'button_links_group',
                    })}
                  >
                    {items.map((item: any, index: number) => {
                      return (
                        <MobileNavItem
                          navItem={item}
                          slice_type={slice_type!}
                          key={index}
                          subNavTitle={sub_navigation_title}
                          activeMegaTitle={activeMegaTitle}
                          groupTitle={group_title}
                        />
                      )
                    })}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )}
      {sub_navigation && sub_navigation.length > 0 && body && body.length == 0 && (
        <div className="flex flex-col bg-ecru wrapper">
          {sub_navigation.map((item: SubNavigationItem, index: number) => {
            const { body, sub_navigation_link } = item || {}
            return (
              <div key={index}>
                {body && body.length > 0 ? (
                  <div
                    onClick={() => handleOpenSubNav(index)}
                    className="flex justify-between border-b border-warmWhite py-16 font-g-medium text-18 leading-24 tracking-spacingBitTigher text-black"
                  >
                    {item.sub_navigation_title}
                    <CaretRight />
                  </div>
                ) : (
                  <>
                    {sub_navigation_link && (
                      <Link href={sub_navigation_link} passHref>
                        <div
                          onClick={() =>
                            updateNavDataLayer(
                              item.sub_navigation_title!,
                              `Subcategory of ${activeMegaTitle}`,
                              activeMegaTitle,
                              sub_navigation_link
                            )
                          }
                          className="border-b border-warmWhite py-16 font-g-medium text-18 leading-24 tracking-spacingBitTigher text-black"
                        >
                          {item.sub_navigation_title}
                        </div>
                      </Link>
                    )}
                  </>
                )}
              </div>
            )
          })}
        </div>
      )}
      <style jsx>
        {`
          .smallTiles {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
          }
          .mediumTiles {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
          }
          .textLinks {
            display: flex;
            flex-wrap: wrap;
            column-gap: 12px;
          }
          .buttonLinks {
            gap: 4px;
            display: flex;
            flex-wrap: nowrap;
            overflow-x: scroll;
            width: 100vw;
          }
          .buttonLinks:last-child {
            padding-right: 40px;
          }
        `}
      </style>
    </div>
  )
}

export default MobileNavComponent
