import type { Cart } from '@commerce/types/cart'
import { getCurrenceCodeByLocale } from '@lib/locale'
import { EcommerceData, Impression } from '@lib/types/Elevar'
import { ElevarEvents, getUserPropertiesFromSessionStorage } from './elevarEvents'
import { EVENT_DEBUG, eventLog, getDataLayerProductIdByIds, getNumberPartFromId, infoLog } from './general'

declare global {
  interface Window {
    dataLayer: any
  }
}

export const cartView = (item: number, total: number, cart?: Cart, locale?: string) => {
  if (EVENT_DEBUG && !window?.dataLayer) {
    infoLog('CartView: No Data Layer found.')
  }

  if (window?.dataLayer) {
    const detail = {
      event: 'cartView',
      numberOfItems: item,
      totalValue: total,
    }

    window?.dataLayer?.push(detail)
    if (EVENT_DEBUG) eventLog(`Cart View 'gtm.cartView (custom)`, { detail })
  }

  const cart_total = cart?.totalPrice?.toString() || ""
  const userProperties = getUserPropertiesFromSessionStorage()

  const impressions: Impression[] = cart
    ? cart.lineItems?.map((item, index) => {
      const id = getDataLayerProductIdByIds(item.productId, item.variant?.id, locale)
      return {
        id,
        name: item.name || '',
        brand: item.range?.replace(/-/g, ' ')?.toUpperCase() || "",
        category: item.type || '',
        variant: item.variant?.name || '',
        price: item.variant?.price?.toString() || '',
        quantity: item.quantity?.toString() || '',
        position: index + 1,
        product_id: getNumberPartFromId(item.productId),
        variant_id: getNumberPartFromId(item.variant?.id),
        compare_at_price: item.variant?.listPrice?.toString() || "0.0",
      }
    })
    : []
  const ecommerceData: EcommerceData = {
    currencyCode: getCurrenceCodeByLocale(locale),
    actionField: {
      list: "Shopping Cart"
    },
    impressions: impressions
  }
  ElevarEvents('dl_view_cart', userProperties, ecommerceData, cart_total)
}
