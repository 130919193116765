const Close = ({ ...props }) => {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx="12" cy="12" r="12" fill="#AFB1B4" />
        <path d="M7.94922 7.95001L16.0492 16.05" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.94922 16.05L16.0492 7.95003" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </>
  )
}

export default Close
