const Close = ({ ...props }) => {
  return (
    <>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.57854 3.57869C3.96906 3.18816 4.60223 3.18816 4.99275 3.57869L16.4214 15.0073C16.8119 15.3978 16.8119 16.031 16.4214 16.4215C16.0308 16.812 15.3977 16.812 15.0071 16.4215L3.57854 4.9929C3.18801 4.60238 3.18801 3.96921 3.57854 3.57869Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.57869 16.4215C3.18816 16.0309 3.18816 15.3978 3.57869 15.0072L15.0073 3.57864C15.3978 3.18812 16.031 3.18812 16.4215 3.57864C16.812 3.96917 16.812 4.60233 16.4215 4.99285L4.9929 16.4215C4.60238 16.812 3.96921 16.812 3.57869 16.4215Z"
          fill="black"
        />
      </svg>
    </>
  )
}

export default Close
