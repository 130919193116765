import { slice } from '@lib/prismic'
import cn from 'classnames'
import { FC } from 'react'
import MegaNavigationGroup from './MegaNavigationGroup'
interface Props {
  navItem: slice[]
  subNavTitle?: string | null
  activeMegaTitle: string
}

const MegaNavigationColumn: FC<Props> = ({ navItem, subNavTitle, activeMegaTitle }) => {
  return (
    <div
      className={cn('flex flex-col gap-24 w-146', {
        'ml-auto pl-18 border-l border-newClay w-auto': navItem.find((item) => item.primary?.featured),
        'w-304': navItem.find((item) => item.primary?.span_two_columns),
      })}
    >
      {navItem.map((item, index) => {
        return (
          <MegaNavigationGroup activeMegaTitle={activeMegaTitle} subNavTitle={subNavTitle} key={index} navItem={item} />
        )
      })}
    </div>
  )
}

export default MegaNavigationColumn
