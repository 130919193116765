import { FC } from 'react'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import { customLink } from 'lib/prismic'
import classNames from 'classnames'

interface props {
  content: RichTextBlock[] | undefined
  resetStyling?: boolean
}

const WYSIWYG: FC<props> = ({ content, resetStyling = true }) => {
  return (
    <div className={classNames({ wysiwyg: resetStyling })}>
      <RichText render={content} serializeHyperlink={customLink} />
    </div>
  )
}

export default WYSIWYG
