import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react'

interface props {
  settings: {
    desktop_background_image: {
      url: string
      dimensions: {
        width: number
        height: number
      }
    }
    mobile_background_image: {
      url: string
      dimensions: {
        width: number
        height: number
      }
    }
    include_pages: string
    exclude_pages: string
    fixed_background: boolean
  }
  uid: string
  setBackgroundMatch: Dispatch<SetStateAction<boolean>>
}

const BackgroundImage: FC<props> = ({ settings, uid, setBackgroundMatch }) => {
  const { desktop_background_image, mobile_background_image, include_pages, exclude_pages, fixed_background } = settings
  const includePages = include_pages ? include_pages.split(',') : []
  const excludePages = exclude_pages ? exclude_pages.split(',') : []
  const imageRef = useRef<HTMLDivElement>(null)
  const includeMatch = includePages.find((item) => uid.includes(item))
  const excludeMatch = excludePages.find((item) => uid.includes(item))

  const desktopImageURL = desktop_background_image?.url.includes('svg')
    ? desktop_background_image?.url
    : `${desktop_background_image?.url}?q=98`
  const mobileImageURL = mobile_background_image?.url
    ? mobile_background_image.url.includes('svg')
      ? mobile_background_image.url
      : `${mobile_background_image?.url}?q=98`
    : desktopImageURL

  const updateScrollY = () => {
    const distance = window.scrollY

    if (imageRef.current) {
      imageRef.current.setAttribute('style', `top: ${distance * 0.1}px`)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', updateScrollY)

    return () => {
      window.removeEventListener('scroll', updateScrollY)
    }
  }, [])

  useEffect(() => {
    if (uid === excludeMatch) {
      setBackgroundMatch(false)
    } else if (includeMatch && uid.includes(includeMatch)) {
      setBackgroundMatch(true)
    } else {
      setBackgroundMatch(false)
    }
  }, [uid, excludeMatch, includeMatch, setBackgroundMatch])

  if (uid === excludeMatch) {
    return <></>
  }

  if (includeMatch && uid.includes(includeMatch)) {
    return (
      <div className="global-background-image-container">
        <div className="global-background-image"></div>
        <style jsx>{`
          .global-background-image-container {
            height: ${fixed_background ? '100vh' : '100%'};
            width: 100%;
            position: ${fixed_background ? 'fixed' : 'absolute'};
            z-index: -1;
            left: 0;
            transform: translateZ(0);
            overflow: hidden;
          }

          .global-background-image {
            background: url(${mobileImageURL});
            background-size: ${mobile_background_image?.url
                ? mobile_background_image?.dimensions.width / 2
                : desktop_background_image?.dimensions.width / 2}px,
              ${mobile_background_image?.url
                ? mobile_background_image?.dimensions.height / 2
                : desktop_background_image?.dimensions.height / 2}px;
            background-position: top center;
            background-repeat: repeat;
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            transition: top ease;
          }

          @media screen and (min-width: 1024px) {
            .global-background-image {
              background: url(${desktopImageURL});
              background-size: ${desktop_background_image?.dimensions.width / 2}px,
                ${desktop_background_image?.dimensions.height / 2}px;
              background-position: top center;
              background-repeat: repeat;
              height: 100%;
              width: 100%;
            }
          }
        `}</style>
      </div>
    )
  }

  return <></>
}

export default BackgroundImage
