import { useMutation } from '@apollo/client'
import {
  checkoutLineItemAddMutation,
  checkoutLineItemRemoveMutation,
  checkoutRemoveLineItemsVars,
} from '@commerce/utils'
import { Attribute } from '@commerce/schema'
import { checkoutAddMultipleLineItemVars } from '@commerce/utils/mutations/checkout-line-item-add'
import { useUI } from '@components/ui'
import type { Cart } from '@commerce/types/cart'
import { FC, useEffect } from 'react'
import { useRouter } from 'next/router'

interface props {
  cart: Cart
  setMyCartLoaded: (value: boolean) => void
}

const emptyCart: (cart: Cart, locale: string | undefined, removeItems: any) => Promise<any> = (
  cart,
  locale,
  removeItems
) => {
  const items =
    cart?.lineItems.map((item) => {
      return item.id
    }) || []
  if (items.length > 0) {
    return removeItems({
      variables: checkoutRemoveLineItemsVars({ locale, itemIds: items }),
      context: { locale },
    })
  }
  return Promise.resolve()
}

export const LoadSharedMyCartFromURL: FC<props> = ({ cart, setMyCartLoaded }) => {
  const { openSidebar } = useUI()
  const { locale } = useRouter()
  const [removeItems] = useMutation(checkoutLineItemRemoveMutation, {
    onCompleted() {
      openSidebar()
    },
    onError(error) {
      console.error(error)
    },
  })

  const [addItem] = useMutation(checkoutLineItemAddMutation, {
    onCompleted() {
      openSidebar()
      history.replaceState({}, document.title, '/')
    },
    onError(error) {
      console.error('error adding item', error)
    },
  })

  useEffect(() => {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(decodeURI(url.search))
    const productsInURL = params.getAll('smc')

    if (productsInURL.length > 0 && cart) {
      emptyCart(cart, locale, removeItems).then(() => {
        const products = productsInURL.map((productData) => {
          const parts = productData.split('|')
          const propertiesString = parts[2]
          let customAttributes: Array<Attribute> = []
          if (propertiesString) {
            propertiesString.split('||').map((propertyData) => {
              const propertyParts = propertyData.split(':')
              customAttributes.push({
                key: propertyParts[0],
                value: propertyParts[1],
              })
            })
          }
          return {
            variantId: btoa(`gid://shopify/ProductVariant/${Number(parts[0])}`),
            quantity: Number(parts[1]),
            customAttributes,
          }
        })
        products.reverse()

        addItem({
          variables: checkoutAddMultipleLineItemVars(products, locale),
          context: { locale },
        })
      })
      setMyCartLoaded(true)
    }
  }, [cart, locale, addItem, removeItems, setMyCartLoaded])
  return <></>
}
