import { gql } from '@apollo/client'
import { checkoutDetailsFragment } from '../fragements'

const getCheckoutQuery = /* GraphQL */ gql`
  query getCheckout($checkoutId: ID!, $country: CountryCode!) @inContext(country: $country) {
    node(id: $checkoutId) {
      ...checkoutDetails
    }
  }
  ${checkoutDetailsFragment}
`
export default getCheckoutQuery
