import { LoadingDots } from '@components/ui'
import cn from 'classnames'
import React, { ButtonHTMLAttributes, JSXElementConstructor, forwardRef, useRef } from 'react'
import mergeRefs from 'react-merge-refs'
import s from './Button.module.css'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  href?: string
  className?: string
  variant?: 'primary' | 'secondary' | 'tertiary' | 'buttonLink' | 'textLink' | 'login' | string
  bg?: 'light' | 'dark'
  size?: 'regular' | 'small'
  active?: boolean
  type?: 'submit' | 'reset' | 'button'
  Component?: string | JSXElementConstructor<any>
  width?: string | number
  loading?: boolean
  disabled?: boolean
  noPadding?: boolean
  colorSchema?: 'dark' | 'light'
}

// eslint-disable-next-line react/display-name 
const Button: React.FC<ButtonProps> = forwardRef((props, buttonRef) => {
  const {
    className,
    variant = 'primary',
    size = 'regular',
    bg = 'light',
    children, 
    active,
    width,
    loading = false,
    disabled = false,
    noPadding,
    colorSchema,
    style = {},
    Component = 'button',
    ...rest
  } = props
  const ref = useRef<typeof Component>(null)

  const rootClassName = cn(
    s.root,
    {
      [s.primary]: variant === 'primary' && bg === 'light',
      [s.secondary]: variant === 'secondary' && bg === 'light',
      [s.tertiary]: variant === 'tertiary' && bg === 'light',
      [s.buttonLink]: variant === 'buttonLink' && bg === 'light',
      [s.textLink]: variant === 'textLink' && bg === 'light',
      [s.primaryDark]: variant === 'primary' && bg === 'dark',
      [s.secondaryDark]: variant === 'secondary' && bg === 'dark',
      [s.tertiaryDark]: variant === 'tertiary' && bg === 'dark',
      [s.buttonLinkDark]: variant === 'buttonLink' && bg === 'dark',
      [s.textLinkDark]: variant === 'textLink' && bg === 'dark',
      [s.small]: size === 'small',
      [s.loading]: loading,
      [s.disabled]: disabled,
      [s.primaryDisabled]: disabled && variant === 'primary' && bg === 'light',
      [s.secondaryDisabled]: disabled && variant === 'secondary' && bg === 'light',
      [s.tertiaryDisabled]: disabled && variant === 'tertiary' && bg === 'light',
      [s.buttonLinkDisabled]: disabled && variant === 'buttonLink' && bg === 'light',
      [s.textLinkDisabled]: disabled && variant === 'textLink' && bg === 'light',
      [s.primaryDarkDisabled]: disabled && variant === 'primary' && bg === 'dark',
      [s.secondaryDarkDisabled]: disabled && variant === 'secondary' && bg === 'dark',
      [s.tertiaryDarkDisabled]: disabled && variant === 'tertiary' && bg === 'dark',
      [s.buttonLinkDarkDisabled]: disabled && variant === 'buttonLink' && bg === 'dark',
      [s.textLinkDarkDisabled]: disabled && variant === 'textLink' && bg === 'dark',
      [s.login]: variant === 'login' && bg === 'light',
      [s.noPad]: noPadding === true,
      [s.secondaryLight]: variant === 'secondary' && colorSchema === 'light',
      [s.buttonLinkLight]: variant === 'buttonLink' && colorSchema === 'light',
    },
    className
  )

  return (
    <Component
      aria-pressed={active}
      data-variant={variant}
      ref={mergeRefs([ref, buttonRef])}
      className={rootClassName}
      disabled={disabled}
      style={{
        width,
        ...style,
      }}
      {...rest}
    >
      {loading ? (
        <i className="pl-2 m-0 flex">
          <LoadingDots />
        </i>
      ) : (
        <>{children}</>
      )}
    </Component>
  )
})

export default Button
