import client, { customerAccessTokenVar } from '@commerce/client'
import commerceConfig from '@commerce/config'
import { Button, useUI } from '@components/ui'
import DropDown from '@components/ui/DropDown'
import processLocale, { LOCALES_MAP } from '@lib/locale'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { memo, useState } from 'react'

interface Props {
  onClose?: () => void
  suggestion?: string
  applyScrollLock?: boolean
}

const CountrySelector = ({ onClose, suggestion, applyScrollLock = false }: Props) => {
  const { locale, locales, asPath: currentPath, push, reload } = useRouter()
  const currentLocale = suggestion || processLocale(locale)
  const { closeModal } = useUI()
  const {
    config: { context },
  } = commerceConfig
  const [changingCountry, setChanginCountry] = useState(false)
  const options: any = locales
    ?.filter((val) => LOCALES_MAP[val] && context[val] && context[val].enabled)
    .map((v) => LOCALES_MAP[v])
  const [selectedCountry, setSelectedCountry] = useState(LOCALES_MAP[currentLocale || 'en'])

  const handleCountrySelection = (option: any) => {
    setSelectedCountry(option)
  }
  const handleCurrencySelection = () => {}

  const changeCountry = () => {
    if (locale !== selectedCountry.value) {
      setChanginCountry(true)
      Cookies.set('NEXT_LOCALE', selectedCountry.value)
      customerAccessTokenVar('')
      client.clearStore()
      push(currentPath, currentPath, { locale: selectedCountry.value })
    }
    setChanginCountry(false)
    closeModal()
    if (onClose) onClose()
  }

  return (
    <>
      <div className="px-48 pt-28 pb-0 laptop:pb-48 flex justify-center mobileCountrySelector">
        <div className="selectorContainer flex justify-center">
          <div>
            <div className="text-center title-small capitalize mb-32">
              {suggestion ? 'There might be better store for you' : 'Select Your Country'}
            </div>
            <DropDown
              options={options}
              extraTitle="Shop in"
              arialLabel="Language selector"
              className="mb-12 max-w-342 tablet:max-w-394"
              selectedOption={selectedCountry}
              handleOptionSelection={handleCountrySelection}
              key="country-selector"
              applyScrollLock={applyScrollLock}
            />
            <DropDown
              options={selectedCountry?.currency || []}
              extraTitle="Currency"
              arialLabel="Currency selector"
              className="mb-32 max-w-342 tablet:max-w-394"
              selectedOption={selectedCountry?.currency?.[0] || { name: '$ AUD', value: 'AU' }}
              handleOptionSelection={handleCurrencySelection}
              key="currency-selector"
              disabled
            />

            {selectedCountry?.value === 'en' && (
              <p className="body mt-34 mb-32 block laptop:hidden">Australian and United states sizing available.</p>
            )}

            <div className="hidden laptop:block">
              <Button width="100%" disabled={changingCountry} onClick={changeCountry}>
                go to {selectedCountry?.name} store
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-12 pb-24 fixed-component-shadow laptop:hidden flex justify-center">
        <div>
          <Button className="max-w-342" width="100%" disabled={changingCountry} onClick={changeCountry}>
            go to {selectedCountry?.name} store
          </Button>
        </div>
      </div>

      <style jsx>
        {`
          .selectorContainer {
            width: 425px;
          }
          @media (max-width: 375px) {
            .selectorContainer {
              width: 375px;
            }
          }
          @media (max-width: 325px) {
            .selectorContainer {
              width: 325px;
            }
          }
        `}
      </style>
    </>
  )
}
export default memo(CountrySelector)
