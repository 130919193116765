import { useNavDataLayer } from '@lib/hooks/useNavDataLayer'
import { SubNavigationItem } from '@lib/types/Navigation'
import cn from 'classnames'
import Link from 'next/link'
import { FC } from 'react'
interface Props {
  navItem: SubNavigationItem[]
  activeMegaTitle: string
  navProps: {
    subMenuHoverIndex: number
    handleActiveSubNav: (a: number) => void
    handleSubNavOut: (activeIndex: number) => void
  }
}

const SubNavigation: FC<Props> = ({ navItem, navProps, activeMegaTitle }) => {
  const { subMenuHoverIndex, handleActiveSubNav, handleSubNavOut } = navProps
  const { updateNavDataLayer } = useNavDataLayer()

  if (navItem.length == 0) return <></>

  return (
    <div className="w-full h-42 bg-ecru">
      <div className="wrapper flex gap-24  h-full">
        {navItem.map((item, index) => {
          const handleMouseOver = () => {
            if (item?.body && item?.body?.length > 0) {
              handleActiveSubNav(index)
            }
          }

          const handleMouseOut = () => {
            if (item?.body && item?.body?.length > 0) {
              handleSubNavOut(index)
            }
          }

          const { sub_navigation_link, sub_navigation_title } = item || {}
          return (
            <div key={index}>
              <div
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                className={cn('body-small-medium pt-10 cursor-pointer pb-10 text-charcoal  ', {
                  'border-b-2 text-newSage': subMenuHoverIndex == index && item?.body && item?.body?.length > 0,
                })}
              >
                {sub_navigation_link ? (
                  <Link href={sub_navigation_link} passHref>
                    <div
                      onClick={() =>
                        updateNavDataLayer(
                          sub_navigation_title!,
                          `Subcategory of ${activeMegaTitle}`,
                          activeMegaTitle,
                          sub_navigation_link
                        )
                      }
                    >
                      {sub_navigation_title}
                    </div>
                  </Link>
                ) : (
                  <>{sub_navigation_title}</>
                )}
              </div>
            </div>
          )
        })}
      </div>
      <style jsx>
        {`
          .selectorHover {
            display: none;
          }
        `}
      </style>
    </div>
  )
}

export default SubNavigation
