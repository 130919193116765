import CommerceConfig from '@commerce/config'
import { toggleChat } from '@lib/gorgias'
import Prismic from '@prismicio/client'
import { ApiOptions } from '@prismicio/client/types/Api'
import { Document } from '@prismicio/client/types/documents'
import Link from 'next/link'
import processLocale from './locale'
import {
  Footer,
  FooterQuickLinks,
  MainNavigationData,
  ScrollingAnnouncementBar,
  ServiceUSP,
  SocialAndPayments,
} from './types/Navigation'

export const apiEndpoint = 'https://sheetsociety.cdn.prismic.io/api/v2'
export const accessToken = process.env.NEXT_PUBLIC_PRISMIC_TOKEN
export const cacheOption = 's-maxage=31536000, stale-while-revalidate' //1 year
export type slice = {
  primary: any
  items: any
  slice_label?: string | undefined
  slice_type?: string | undefined
}

export type Swatch = {
  swatch?: PrismicImage
  name: string
  svg_fill_colour?: string
  svg_border_colour?: string
}

export type FacetLabelMapping = {
  dimension: string
  display_label: string
  hidden: boolean
  value: string
}

export interface PrismicImage {
  url: string
  alt?: string
  copyright?: string
  dimensions: {
    width: number
    height: number
  }
}

export interface PrismicMedia {
  link_type: string
  name: string
  kind: string
  url: string
  size: string
}

export interface prismicPreviewData {
  preview: boolean
  previewData: any
  params: any
  locale?: string
  locales: string[]
}

export const linkResolver = (doc: Document | any) => {
  if (doc && doc?.link_type === 'Web') {
    return doc?.url || `/${doc.lang}`
  }

  if (!doc || !doc?.uid || doc?.uid === 'homepage') return `/${doc.lang}`

  if (doc.type === 'general') {
    return `/${doc.lang}/${doc.uid.split('.').join('/')}`
  }

  if (doc.type === 'plp') {
    return `/${doc.lang}/products-old/${doc.uid.replace('plp-', '')}`
  }

  // if (doc.type === 'page') {
  //   return `/${doc.lang}/pages-old/${doc.uid.replace('page-', '')}`
  // }

  return '/'
}

// Helper function to convert Prismic Rich Text links to Next/Link components
export const customLink = (type: string, element: any, content: any, children: any) => {
  const url = element?.data?.url
  const href = url?.startsWith('https:///') ? url.replace('https:///', '/') : linkResolver(element.data)

  if (href === '/:chat') {
    return (
      <button onClick={toggleChat} key={`${element.type}-${element.start}`} className="body-link-small underline">
        {content}
      </button>
    )
  }

  return (
    <Link key={`${element.type}-${element.start}`} href={`${href}`} passHref target={element?.data?.target || '_self'}>
      {children || content}
    </Link>
  )
}

const createClientOptions = (req: ApiOptions | undefined | null, prismicAccessToken: string | undefined) => {
  const reqOption = req ? { req } : {}
  const accessTokenOption = prismicAccessToken ? { accessToken: prismicAccessToken } : {}
  return {
    ...reqOption,
    ...accessTokenOption,
  }
}

export const findCollectonHandles = (sliceList: any) => {
  const handles = sliceList
    ?.filter((slice: any) => slice.slice_type === 'products_grid')
    .map((slice: any) => slice?.primary?.shopify_collection_handle)

  return handles || []
}

// Client method to query documents from the Prismic repo
export const Client = (req = null) => Prismic.client(apiEndpoint, createClientOptions(req, accessToken))

export const getDocsByType = async (type: string) => {
  const client = Client()
  const docs = await client.query(Prismic.Predicates.at('document.type', type)).then((response) => response.results)
  return docs
}

export const getStaticPathsByPrismicType = async (type: string, paramName: string, prefix: string) => {
  const docs = await getDocsByType(type)
  return docs
    .filter((doc) => doc && doc?.uid)
    .map((doc) => ({
      params: {
        [paramName]: doc.uid?.replace(`${prefix}-`, ''),
      },
    }))
}

export const filterDocsByLocale = (docs: Document[], locale: string | undefined) => {
  if (!docs || !Array.isArray(docs) || docs.length <= 1) return docs

  const { prismicDefaultLocale, defaultLocale } = CommerceConfig
  // check for default locale
  const defaultDoc = docs.filter((doc) => doc.lang === prismicDefaultLocale)
  // check for the current one
  const docByLocale = docs.filter((doc) => doc.lang === locale || defaultLocale)

  return docByLocale.length === 1 ? docByLocale : defaultDoc
}

export const findProductBlocksPrismicUids = (tags: string[], startswith: string) => {
  const productInfoUids = tags
    .filter((tag) => tag.startsWith(startswith))
    .map((tag) => {
      const parts = tag.split(':')
      return parts?.[1] || ''
    })
  return productInfoUids || []
}

export const getDocByUid = async (doctype: string, uid: string | null, ref: any, locale: string | undefined) => {
  if (!uid) return null
  const client = Client()
  const currentLocale = processLocale(locale)
  const refObjectWithLocale = ref ? { ref, lang: currentLocale } : { lang: currentLocale, ref: null }
  const refObject = ref ? { ref } : { ref: null }

  let doc = await client.getByUID(doctype, uid, refObjectWithLocale)
  if (!doc) doc = await client.getByUID(doctype, uid, refObject)

  return doc
}

export const getSingleDocByType = async (doctype: string, ref: any, locale: string | undefined, fallBack = true) => {
  const client = Client()
  const currentLocale = processLocale(locale)
  const refObjectWithLocale = ref ? { ref, lang: currentLocale } : { lang: currentLocale, ref: null }
  // if default locale need to be added manually then we can add here.
  const refObject = ref ? { ref } : { ref: null }

  let doc = await client.getSingle(doctype, refObjectWithLocale)
  if (fallBack && !doc) doc = await client.getSingle(doctype, refObject)

  return doc
}

export const getDocById = async (
  id: string,
  ref: any,
  locale: string | undefined,
  fetchLinks?: string[],
  fallBack = true
) => {
  const client = Client()
  const currentLocale = processLocale(locale)
  const refObjectWithLocale = ref
    ? { ref, lang: currentLocale, fetchLinks: fetchLinks ? fetchLinks : [] }
    : { lang: currentLocale, ref: null, fetchLinks: fetchLinks ? fetchLinks : [] }
  const refObject = ref
    ? { ref, fetchLinks: fetchLinks ? fetchLinks : [] }
    : { ref: null, fetchLinks: fetchLinks ? fetchLinks : [] }

  let doc = await client.getByID(id, refObjectWithLocale)
  if (fallBack && !doc) doc = await client.getByID(id, refObject)

  return doc
}

export const findPLPDocId = (sliceList: any) => {
  const brandListIds = sliceList
    ?.filter((slice: any) => slice.slice_type === 'product_list')
    .map((slice: any) => slice?.primary?.plp_config?.id)

  return brandListIds?.[0] || null
}

export const getNestedNavigationDoc = async (
  doctype: string,
  uid: string | null,
  ref: any,
  locale: string | undefined
) => {
  if (!uid) return null

  try {
    const client = Client()
    const currentLocale = processLocale(locale)
    const refObjectWithLocale = ref ? { ref, lang: currentLocale } : { lang: currentLocale, ref: null }
    const refObject = ref ? { ref } : { ref: null }

    let doc = await client.getByUID(doctype, uid, refObjectWithLocale)

    if (!doc) {
      doc = await client.getByUID(doctype, uid, refObject)
    }
    if (!doc) return null
    const { data } = doc || {}
    const {
      account_action_image,
      account_action_login_link,
      account_action_login_text,
      account_action_signup_link,
      account_action_signup_text,
      account_action_title,
      chat_action_title,
      chat_action_link,
      chat_action_image,
      chat_action_text,
      mobile_extra_links,
      top_level_navigation,
      announcement_items,
      text_colour,
      background_colour,
      facebook_icon,
      facebook_link,
      instagram_icon,
      instagram_link,
      youtube_icon,
      youtube_link,
      pinterest_icon,
      pinterest_link,
      body,
      title,
      copyright_text,
      klaviyo_list_id,
      body1,
      quick_links_title,
      quick_links_background_colour,
      show_quick_links,
      quick_links_text_colour,
      body2,
      body3,
    } = data || {}
    const navigationData: MainNavigationData = {
      account_action: {},
      chat_action: {},
      mobile_extra_links: [],
      top_level_navigation: [],
    }
    const scrollingAnnouncementBar: ScrollingAnnouncementBar = {
      background_colour: background_colour,
      text_colour: text_colour,
      announcement_items: announcement_items,
    }
    const socialAndPayments: SocialAndPayments = {
      facebook_icon: facebook_icon,
      facebook_link: facebook_link,
      instagram_icon: instagram_icon,
      instagram_link: instagram_link,
      youtube_icon: youtube_icon,
      youtube_link: youtube_link,
      pinterest_icon: pinterest_icon,
      pinterest_link: pinterest_link,
      body: body,
    }
    const footer: Footer = {
      title: title,
      copyright_text: copyright_text,
      klaviyo_list_id: klaviyo_list_id,
      body: body1,
    }
    const serviceUSP: ServiceUSP = {
      body: body3,
    }
    const footerQuickLinks: FooterQuickLinks = {
      quick_links_title: quick_links_title,
      quick_links_background_colour: quick_links_background_colour,
      show_quick_links: show_quick_links,
      quick_links_text_colour: quick_links_text_colour,
      body: body2,
    }
    const account_action = {
      account_action_image: account_action_image ? account_action_image : null,
      account_action_login_link: account_action_login_link ? account_action_login_link : null,
      account_action_login_text: account_action_login_text ? account_action_login_text : null,
      account_action_signup_link: account_action_signup_link ? account_action_signup_link : null,
      account_action_signup_text: account_action_signup_text ? account_action_signup_text : null,
      account_action_title: account_action_title ? account_action_title : null,
    }
    const chat_action = {
      chat_action_title: chat_action_title ? chat_action_title : null,
      chat_action_link: chat_action_link ? chat_action_link : null,
      chat_action_image: chat_action_image ? chat_action_image : null,
      chat_action_text: chat_action_text ? chat_action_text : null,
    }
    const subNavigationFetchLinks = [
      'sub_navigation.sub_navigation_title',
      'sub_navigation.sub_navigation_link',
      'sub_navigation.body',
      'sub_navigation.body1',
    ]

    if (Array.isArray(top_level_navigation)) {
      const topLevelNavigationDocsIds = top_level_navigation.map((item) => item?.top_level_navigation_item?.id)
      const { results: topLevelNavigation } = await client.getByIDs(topLevelNavigationDocsIds, {
        ...refObjectWithLocale,
        fetchLinks: subNavigationFetchLinks,
      })
      const topLevelNavigationDocs = topLevelNavigation.map((topLevelNavigationDoc) => {
        const { data: topLevelNavigationData } = topLevelNavigationDoc || {}
        const subNavigationData =
          topLevelNavigationData?.sub_navigation
            ?.map((item: any) => item.sub_navigation_item && item.sub_navigation_item.data)
            .filter(Boolean) || []
        topLevelNavigationData.sub_navigation = subNavigationData
        return topLevelNavigationData
      })
      navigationData.top_level_navigation = topLevelNavigationDocs.filter(Boolean)
    }
    navigationData.account_action = account_action
    navigationData.chat_action = chat_action
    navigationData.mobile_extra_links = mobile_extra_links || []
    return {
      navigationData,
      scrollingAnnouncementBar,
      socialAndPayments,
      footer,
      footerQuickLinks,
      serviceUSP,
    }
  } catch (error) {
    console.error('An error occurred while fetching nested navigation docs:', error)
    return null
  }
}

export default Client
