import { useQuery, useReactiveVar } from '@apollo/client'
import { customerAccessTokenVar } from '@commerce/client'
import usePrice from '@commerce/product/use-price'
import type { Cart } from '@commerce/types/cart'
import { LineItem } from '@commerce/types/cart'
import {
  checkoutToCart,
  getCheckoutId,
  getCheckoutQuery,
  getCurrencyCountryCode,
  getCustomerQuery,
} from '@commerce/utils'
import DispatchMessage from '@components/common/DispatchMessage'
import { Check, Cross, Lock } from '@components/icons'
import { Button } from '@components/ui'
import { useUI } from '@components/ui/context'
import { cartView } from '@lib/events/cartView'
import { fetcher, swrOptions } from '@lib/helpers/swr'
import { normalizeDispatchRules } from '@lib/product'
import { MiniCart } from '@lib/types/MiniCart'
import cn from 'classnames'
import { useRouter } from 'next/router'
import { RichText } from 'prismic-reactjs'
import { FC, Fragment, useEffect, useRef, useState } from 'react'
import useSWR from 'swr'
import AddonError from '../Addon/AddonError'
import { useAddons } from '../Addon/AddonHooks'
import { getAddonCartLineItem } from '../Addon/AddonUtils'
import AddonsList from '../Addon/AddonsList'
import CartItem from '../CartItem'
import LoopReturns, { LoopReturnsCreditBar } from '../LoopReturns'
import ShareMyCart from '../ShareMyCart'
import { LoadSharedMyCartFromURL } from '../ShareMyCart/ShareMyCartHelper'
import { SpendAndSaveWidget } from '../SpendAndSave/SpendAndSaveWidget'
import s from './CartSidebarView.module.css'

interface CartSidebarViewProps {
  isOpened: boolean
  miniCart: MiniCart
  spendAndSave?: {
    enabled: boolean
    message: string
    excludeProductTypes: string
    tiers: string
    subMessage: string
  }
  shareMyCart?: {
    title: string
    body: string
    utmSource: string
    utmMedium: string
    utmCampaign: string
  }
  prismicRef: any
}

const countItem = (count: number, item: LineItem) => count + item.quantity

//To determine if the content of the minicart is overflowing
const checkOverflow = (el: HTMLElement) => {
  const curOverflow = el.style.overflow

  if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden'

  const isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight

  el.style.overflow = curOverflow

  return isOverflowing
}

const CartSidebarView: FC<CartSidebarViewProps> = ({ isOpened, miniCart, shareMyCart, prismicRef, spendAndSave }) => {
  const { message, loop_returns_enabled, addons } = miniCart
  const { locale } = useRouter()
  const { closeSidebar } = useUI()
  const itemsContent = useRef<HTMLDivElement>(null)
  const [cart, setCart] = useState<Cart | null>(null)
  const [myCartLoaded, setMyCartLoaded] = useState(false)
  const [lastFocusedProduct, setLastFocusedProduct] = useState('')
  const [loopReturnsCredit, setLoopReturnsCredit] = useState(0)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const [isShareMyCartOpen, setIsShareMyCartOpen] = useState(false)
  const { getIncompatibleError, hasIncompatibleItems } = useAddons(
    locale || null,
    addons,
    cart,
    miniCart.addons_locations_inventory_validation
  )
  const { data: dispatchRules } = useSWR(`/api/prismic/dispatch-message?locale=${locale}`, fetcher, swrOptions)
  const customerAccessToken = useReactiveVar(customerAccessTokenVar)
  const { data: customerData } = useQuery(getCustomerQuery, {
    variables: { customerAccessToken },
    context: { locale },
    skip: !(customerAccessToken && customerAccessToken.length > 0),
  })

  const { data, loading, refetch } = useQuery(getCheckoutQuery, {
    variables: { checkoutId: getCheckoutId(locale), country: getCurrencyCountryCode(locale) },
    context: { locale },
  })
  useEffect(() => {
    const cartData = data?.node ? checkoutToCart({ checkout: data.node }) : null
    cartData && isOpened && cartView(cartData.lineItems.length, Number(cartData.totalPrice), cartData, locale)
    setCart(cartData)
  }, [data, isOpened, locale])
  // Check the cart items and validate the rules for addons, it adds or remove items depending on the rules criteria

  // useEffect(() => {
  //   if (cart !== null) {
  //     updateCartAddonsOnLoad(cart)
  //   }
  // }, [cart, updateCartAddonsOnLoad])

  const empty = !cart?.lineItems.length
  const itemsCount = cart?.lineItems.reduce(countItem, 0) ?? 0
  const { price: total } = usePrice(
    cart && {
      amount: Number(cart.totalPrice),
      currencyCode: cart.currency.code,
    }
  )

  //Set the overflowing variable in charge of adding the drop shadow when the items list is scrollable
  const onResize = () => {
    if (itemsContent && itemsContent.current instanceof HTMLElement) {
      setIsOverflowing(checkOverflow(itemsContent.current))
    }
  }
  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])
  useEffect(() => {
    if (itemsContent.current !== null) {
      setIsOverflowing(checkOverflow(itemsContent.current))
    }
  }, [itemsContent, cart])

  const totalSavingAmount = cart?.lineItems.reduce((acum, item) => {
    if (Number(item.variant.listPrice) > Number(item.variant.price)) {
      acum = acum + (Number(item.variant.listPrice) - Number(item.variant.price)) * item.quantity
      item.discounts.map((discount) => {
        acum = acum + discount.allocatedAmount
      })
    } else if (item.discounts.length > 0) {
      item.discounts.map((discount) => {
        acum = acum + discount.allocatedAmount
      })
    }
    return acum
  }, 0)

  const { price: totalSaving } = usePrice(
    cart && {
      amount: Number(totalSavingAmount),
      currencyCode: cart.currency.code,
    }
  )

  const handleClose = () => {
    closeSidebar()
    setIsShareMyCartOpen(false)
  }

  const onRemovedCartItem = (newFocus: string) => {
    setLastFocusedProduct(newFocus)
  }

  const onUpdatedCartItem = (newFocus: string) => {
    setLastFocusedProduct(newFocus)
    refetch()
  }

  const error = null
  const success = null

  let checkoutUrl = cart?.url

  if (typeof window !== 'undefined') {
    const impactIrclickid = window?.localStorage.getItem(`irclickid${locale}`)
    if (impactIrclickid) {
      checkoutUrl = `${checkoutUrl}${checkoutUrl?.includes('?') ? '&' : '?'}irclickid=${impactIrclickid}`
    }
  }

  const getFilteredLineItems = () => {
    let filteredLineItems = cart?.lineItems
    // Hide any addon items added to the cart

    // Display the addon items in the cart

    // if (addons?.length > 0 && filteredLineItems) {
    //   filteredLineItems = filteredLineItems.filter((item) => {
    //     const isAddonItem = item.customAttributes?.find((attribute) => attribute.key === addonProperty)
    //     return isAddonItem ? false : true
    //   })
    // }
    return filteredLineItems?.map((item: LineItem, index: number, items: LineItem[]) => {
      let nextItemId = ''
      if (index + 1 < items.length) {
        nextItemId = items[index + 1].id
      }
      let discount_amount = 0
      item.discounts.map((discount) => {
        discount_amount += discount.allocatedAmount
      })
      return (
        <CartItem
          key={`${item.id}_${item.quantity}_${item.discounts.length}_${discount_amount}`}
          position={index + 1}
          item={item}
          nextItemId={nextItemId}
          isFocused={lastFocusedProduct === item.id}
          onUpdated={onUpdatedCartItem}
          onRemoved={onRemovedCartItem}
          currencyCode={cart!.currency.code}
          addonError={getIncompatibleError(item)}
        />
      )
    })
  }

  if (customerData && customerData?.customer)
    checkoutUrl = `/checkout?locale=${locale}&email=${customerData?.customer?.email}`

  return (
    <>
      {handleClose && (
        <button
          onClick={handleClose}
          aria-label="Close"
          className={cn(
            s.closeButton,
            'hover:text-light transition ease-in-out duration-150 flex items-center focus:outline-none'
          )}
        >
          <Cross className="h-6 w-6 hover:text-light" />
        </button>
      )}
      <div
        data-cart-content
        className={cn(s.container, {
          [s.empty]: error || success || loading || empty,
        })}
      >
        <header className={s.header}>
          <div className="m-0 flex flex-wrap items-baseline">
            <span className="font-g-regular text-20 leading-28 mr-8">{empty ? 'Your cart is empty' : 'Your Cart'}</span>
            {itemsCount > 0 && (
              <span className={s.bagCount}>
                {itemsCount}
                {itemsCount > 1 ? ' items' : ' item'}
              </span>
            )}
          </div>
        </header>
        <ShareMyCart
          {...shareMyCart}
          cart={cart}
          open={isShareMyCartOpen}
          onClose={(isOpen) => setIsShareMyCartOpen(isOpen)}
        />
        <div ref={itemsContent} className={cn(s.content)}>
          {spendAndSave?.enabled && cart && (
            <SpendAndSaveWidget
              cart={cart}
              message={spendAndSave?.message || ''}
              subMessage={spendAndSave?.subMessage || ''}
              tiers={spendAndSave.tiers}
              excludeProductTypes={spendAndSave.excludeProductTypes}
              miniCart={miniCart}
            />
          )}
          {cart && loop_returns_enabled && (
            <LoopReturnsCreditBar credit={loopReturnsCredit} currencyCode={cart.currency.code} />
          )}
          {loading || empty ? (
            <></>
          ) : error ? (
            <div className="flex-1 px-4 flex flex-col justify-center items-center">
              <span className="border border-white rounded-full flex items-center justify-center w-16 h-16">
                <Cross width={24} height={24} />
              </span>
              <div className="pt-6 text-32 font-g-light text-center">
                We couldn’t process the purchase. Please check your card information and try again.
              </div>
            </div>
          ) : success ? (
            <div className="flex-1 px-4 flex flex-col justify-center items-center">
              <span className="border border-white rounded-full flex items-center justify-center w-16 h-16">
                <Check />
              </span>
              <div className="pt-6 text-32 font-g-light text-center">Thank you for your order.</div>
            </div>
          ) : (
            <>
              <div className="flex flex-col gap-4 addonError-container">
                {addons.map((addon, index) => {
                  const added = getAddonCartLineItem(addon, cart)
                  if (hasIncompatibleItems(addon) && added) {
                    return (
                      <Fragment key={index}>
                        {addon?.incompatible_product_tags_addon_error_message && (
                          <div>
                            <AddonError
                              error={addon?.incompatible_product_tags_addon_error_message!}
                              textColour={addon?.incompatible_product_tags_addon_error_colour!}
                              bgColour={addon?.incompatible_product_tags_addon_error_bg_colour!}
                            />
                          </div>
                        )}
                      </Fragment>
                    )
                  }
                })}
              </div>

              <ul className={s.lineItemsList}>{getFilteredLineItems()}</ul>
              {!loading && addons?.length > 0 && cart && (
                <AddonsList
                  addons={addons}
                  cart={cart}
                  addonInventoryLocations={miniCart.addons_locations_inventory_validation}
                />
              )}
            </>
          )}
        </div>
        {!loading ? (
          <div
            className={cn(s.footer, {
              [s.overflowing]: isOverflowing,
            })}
          >
            <div className={`pt-12 pb-16 border-t ${isOverflowing ? 'border-transparent' : 'border-lightGrey'}`}>
              {totalSavingAmount && totalSavingAmount > 0 ? (
                <div className="flex flex-row justify-between text-sale pb-8 text-12 leading-20">
                  <span>Total saving</span>
                  <span>{totalSaving}</span>
                </div>
              ) : (
                <></>
              )}
              {message && message?.length > 0 ? (
                <div className="pb-8 leading-20">{RichText.asText(message)}</div>
              ) : (
                <></>
              )}
              {cart && loop_returns_enabled && locale !== undefined ? (
                <LoopReturns cart={cart} locale={locale} total={total} updateCredit={setLoopReturnsCredit}>
                  <Button href={checkoutUrl} Component="a" width="100%" disabled={empty}>
                    <div className="tracking-8 flex">
                      <Lock className="mr-8" />
                      <span>Checkout</span>
                      <span className="px-4">-</span>
                      <span> {total}</span>
                    </div>
                  </Button>
                </LoopReturns>
              ) : (
                <Button href={checkoutUrl} Component="a" width="100%" disabled={empty}>
                  <div className="tracking-8 flex">
                    <Lock className="mr-8" />
                    <span>Checkout</span>
                    <span className="px-4">-</span>
                    <span> {total}</span>
                  </div>
                </Button>
              )}
              {dispatchRules && (
                <DispatchMessage
                  context={normalizeDispatchRules(dispatchRules)}
                  styles={`${message && message?.length > 0 ? 'text-left' : 'text-center'} pt-8`}
                />
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {cart && !myCartLoaded && <LoadSharedMyCartFromURL setMyCartLoaded={setMyCartLoaded} cart={cart} />}
      <style jsx>
        {`
          .addonError-container div:last-child {
            padding-bottom: 30px;
          }
        `}
      </style>
    </>
  )
}
export default CartSidebarView
