import commerceConfig from '@commerce/config'
import { Product, ProductVariant } from '@commerce/types/product'
import { ChevronDown, SwatchIcon } from '@components/icons'
import ClickOutside from '@lib/click-outside/click-outside'
import { fetcher, swrOptions } from '@lib/helpers/swr'
import { Swatch } from '@lib/prismic'
import { COMPONENTS, slugify } from '@lib/product'
import cn from 'classnames'
import Image from 'next/legacy/image'
import { useRouter } from 'next/router'
import { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react'
import useSWR from 'swr'
import s from './Addon.module.css'
import { SelectedVariantProps } from './AddonItem'

interface AddonItemOptionsProps {
  textTheme: string
  products: Product[]
  setProduct: React.Dispatch<React.SetStateAction<Product | undefined>>
  setVariant: React.Dispatch<React.SetStateAction<SelectedVariantProps | undefined>>
  selectedProduct: Product | undefined
  selectedVariant: SelectedVariantProps | undefined
  setDefaultImage: React.Dispatch<React.SetStateAction<string | undefined>>
  setDefaultProduct: React.Dispatch<React.SetStateAction<Product | undefined>>
  product_url_path: string
  show_all_colours: boolean
  pre_select_product: boolean
  showSizeOptions: boolean
}

const AddonItemOptions: FC<AddonItemOptionsProps> = ({
  products,
  setProduct,
  setVariant,
  selectedProduct,
  selectedVariant,
  textTheme,
  setDefaultImage,
  setDefaultProduct,
  product_url_path,
  show_all_colours,
  pre_select_product,
  showSizeOptions = true,
}) => {
  const {
    config: { defaultAlt },
  } = commerceConfig

  const { locale } = useRouter()
  const [productSelectActive, setProductSelectActive] = useState<boolean>(false)
  const [variantSelectActive, setVariantSelectActive] = useState<boolean>(false)
  const [selectedSwatchIcon, setSelectedSwatchIcon] = useState<'' | JSX.Element | undefined>()
  const [selectedSwatchName, setSelectedSwathName] = useState<string>()
  const [isProductOptionsOverflowed, setIsProductOptionsOverflowed] = useState(false)
  const [isVariantOptionsOverflowed, setIsVariantOptionsOverflowed] = useState(false)
  const [defaultValueSet, setDefaultValueSet] = useState(false)
  const productOptionsRef = useRef<HTMLDivElement>(null)
  const variantOptionsRef = useRef<HTMLDivElement>(null)
  const { data: colourSwatchDoc } = useSWR(`/api/prismic/colour-swatches?locale=${locale}`, fetcher, swrOptions)
  const colourSwatches: Swatch[] = colourSwatchDoc?.data?.colours
  const productMap = useMemo(() => {
    return products?.reduce(
      (acc, product) => ({ ...acc, [product.path || '']: product }),
      {} as Record<string, Product>
    )
  }, [products])

  const handleProductSelection = (path: string, swatchIcon: '' | JSX.Element | undefined, swatchName: string) => {
    setProductSelectActive(false)
    const selectedProduct = productMap[path]
    setProduct(selectedProduct)
    setSelectedSwatchIcon(swatchIcon)
    setSelectedSwathName(swatchName)
    setVariantSelectActive(false)
    setVariant(undefined)
  }

  useEffect(() => {
    let defaultSet = false
    colourSwatches?.forEach((colourSwatch) => {
      const { name, svg_fill_colour, svg_border_colour, swatch } = colourSwatch
      if (!name || (!svg_fill_colour && !swatch?.url)) return null

      const siblingProduct = products
        ?.filter((i) => i.colourway.toLowerCase() === slugify(name.toLowerCase()))
        .filter((i) => i.availableForSale)
      if (siblingProduct?.length === 0) return null
      if (!defaultSet && !defaultValueSet) {
        defaultSet = true
        setDefaultImage(siblingProduct?.[0].images?.[0]?.url)
        setDefaultProduct(siblingProduct?.[0])
        setDefaultValueSet(true)
      }

      const swatchIcon = svg_fill_colour ? (
        <SwatchIcon
          fill={svg_fill_colour}
          withBorder={svg_border_colour ? true : false}
          svg_border_color={svg_border_colour}
          className="w-16 h-16"
        />
      ) : (
        swatch?.url && (
          <Image
            width={16}
            height={16}
            src={swatch.url}
            alt={swatch?.alt || defaultAlt}
            objectFit="cover"
            className="rounded-full"
          />
        )
      )

      if (pre_select_product && `${siblingProduct?.[0].path}` === product_url_path) {
        handleProductSelection(siblingProduct?.[0]?.path!, swatchIcon, colourSwatch.name)
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pre_select_product, colourSwatches, product_url_path])

  const handleVariantSelection = (variant: ProductVariant, quantity: number, displayLabel: string) => {
    setVariantSelectActive(false)
    setVariant({ variant, quantity, displayLabel })
  }

  const handleSelectSize = () => {
    if (selectedProduct) {
      setVariantSelectActive(!variantSelectActive)
    }
  }
  const observeOverflow = (element: HTMLElement, setIsOverflowed: (isOverflowed: boolean) => void) => {
    const checkOverflow = () => {
      const isElementOverflowed = element.scrollHeight > element.clientHeight
      setIsOverflowed(isElementOverflowed)
    }

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        checkOverflow()
      }
    })

    observer.observe(element)

    return () => {
      observer.unobserve(element)
    }
  }

  useEffect(() => {
    const productOptionsElement = productOptionsRef.current
    const variantOptionsElement = variantOptionsRef.current

    if (productOptionsElement) {
      return observeOverflow(productOptionsElement, setIsProductOptionsOverflowed)
    }

    if (variantOptionsElement) {
      return observeOverflow(variantOptionsElement, setIsVariantOptionsOverflowed)
    }
  }, [])

  const colourOptions = () => {
    return (
      <>
        {colourSwatches &&
          colourSwatches?.map((colourSwatch, index) => {
            const { name, svg_fill_colour, svg_border_colour, swatch } = colourSwatch
            if (!name || (!svg_fill_colour && !swatch?.url)) return null
            const siblingProduct = products
              ?.filter((i) => i.colourway.toLowerCase() === slugify(name.toLowerCase()))
              .filter((i) => i.availableForSale)
            if (siblingProduct?.length === 0) return null
            if (!show_all_colours && `${siblingProduct?.[0].path}` !== product_url_path) return null

            const swatchIcon = svg_fill_colour ? (
              <SwatchIcon
                fill={svg_fill_colour}
                withBorder={svg_border_colour ? true : false}
                svg_border_color={svg_border_colour}
                className="w-16 h-16"
              />
            ) : (
              swatch?.url && (
                <Image
                  width={16}
                  height={16}
                  src={swatch.url}
                  alt={swatch?.alt || defaultAlt}
                  objectFit="cover"
                  className="rounded-full"
                />
              )
            )

            const handleColourSelection = () => {
              handleProductSelection(siblingProduct?.[0]?.path!, swatchIcon, colourSwatch.name)
            }

            return (
              <button
                onClick={handleColourSelection}
                className="p-8 hover:bg-white flex gap-5 items-center border-b border-lightestGrey cursor-pointer"
                key={index}
              >
                {swatchIcon}
                <div className="body-small text-black text-left">{colourSwatch.name}</div>
              </button>
            )
          })}
      </>
    )
  }

  const sizeOptions = () => {
    return (
      <>
        {selectedProduct &&
          selectedProduct?.variants?.map((variant) => {
            if (!variant?.availableForSale) return <></>
            const label = `${variant?.name}`
            const staticConfig = COMPONENTS.filter((c) => c.type === selectedProduct?.productType?.toLowerCase())?.[0]
            const excludeList = staticConfig?.excludeQtyConversion || []
            const quantity = staticConfig?.qty || []
            const sizeOption = variant?.options.filter((o) => o.displayName === 'size')?.[0]
            let optionLabel = sizeOption?.values?.[0]?.label || variant?.name || ''
            if (selectedProduct?.productType?.toLowerCase() === 'pillowcases') {
              optionLabel === 'Standard Size' ? (optionLabel = 'Standard Pillowcase') : ''
              optionLabel === 'King Size' ? (optionLabel = 'King Pillowcase') : ''
            }
            const multiplyBy =
              selectedProduct?.productType?.toLowerCase() === 'pillowcases' &&
              variant.name !== 'Standard Size / Single' &&
              variant.name !== 'King Size / Single'
                ? 2
                : 1
            const displayLabel = (quantity: number) => {
              return `${quantity ? Number(quantity) * multiplyBy : ''} ${optionLabel}${
                quantity && Number(quantity) * multiplyBy > 1 ? 's' : ''
              }`
            }

            return (
              <Fragment key={variant.id}>
                {quantity && Array.isArray(quantity) && quantity.length > 0 && !excludeList.includes(label) ? (
                  quantity.map((i) => {
                    return (
                      <button
                        key={`${variant.id}-${i}`}
                        onClick={() => handleVariantSelection(variant, i, displayLabel(i))}
                        className="p-8 hover:bg-white flex gap-5 items-center border-b border-lightestGrey"
                      >
                        <div className="body-small text-black text-left">{displayLabel(i)}</div>
                      </button>
                    )
                  })
                ) : (
                  <button
                    key={`${variant.id}-${displayLabel(1)}`}
                    onClick={() => handleVariantSelection(variant, 1, displayLabel(1))}
                    className="p-8 hover:bg-white flex gap-5 items-center border-b border-lightestGrey"
                  >
                    <div className="body-small text-black text-left">{displayLabel(1)}</div>
                  </button>
                )}
              </Fragment>
            )
          })}
      </>
    )
  }

  if (!show_all_colours && !showSizeOptions) return <></>

  return (
    <div className={s.optionContainer}>
      <ClickOutside active={productSelectActive} onClick={() => setProductSelectActive(false)}>
        <div className="flex flex-col flex-1 relative">
          <div
            onClick={() => setProductSelectActive(!productSelectActive)}
            className={cn(
              'p-8  flex justify-between items-center border border-midGrey  cursor-pointer relative h-34',
              {
                'bg-ecru border-midGrey': productSelectActive,
                border: !productSelectActive,
                'bg-ecru': selectedProduct,
              }
            )}
          >
            {selectedProduct ? (
              <div className="flex gap-5 items-center">
                {selectedSwatchIcon}
                <div className="line-clamp-1 text-black text-left">{selectedSwatchName}</div>
              </div>
            ) : (
              <div
                className={cn('body-small ', {
                  'text-black': textTheme == 'dark' || productSelectActive,
                  'text-white': textTheme == 'light' && !productSelectActive,
                })}
              >
                Select colour
              </div>
            )}
            <ChevronDown
              width={18}
              height={18}
              stroke={textTheme === 'light' && !selectedProduct ? '#FFFFFF' : '#6A6A6A'}
            />
          </div>
          <div
            ref={productOptionsRef}
            className={cn(
              'max-h-206 top-34 z-20 absolute border border-t-0 border-midGrey bg-ecru w-full overflow-y-scroll',
              {
                'flex flex-col': productSelectActive,
                hidden: !productSelectActive,
                'scrollbar-hide': !isProductOptionsOverflowed,
              }
            )}
          >
            {colourOptions()}
          </div>
        </div>
      </ClickOutside>
      <ClickOutside active={variantSelectActive} onClick={() => setVariantSelectActive(false)}>
        <div className={cn('flex flex-col flex-1 relative', { 'cursor-not-allowed': !selectedProduct })}>
          <button
            onClick={handleSelectSize}
            className={cn(
              'p-8  flex justify-between items-center border border-midGrey  cursor-pointer relative h-34',
              {
                'bg-ecru cursor-pointer': variantSelectActive,
                'cursor-not-allowed opacity-25': !selectedProduct,
                'bg-ecru': selectedVariant,
              }
            )}
          >
            {selectedVariant ? (
              <div className="body-small text-black line-clamp-1 text-left">
                {selectedVariant?.displayLabel?.trim()}
              </div>
            ) : (
              <div
                className={cn('body-small ', {
                  'text-black': textTheme == 'dark' || variantSelectActive,
                  'text-white': textTheme == 'light' && !variantSelectActive,
                })}
              >
                Select size
              </div>
            )}
            <ChevronDown
              width={18}
              height={18}
              stroke={textTheme === 'light' && !selectedVariant ? '#FFFFFF' : '#6A6A6A'}
            />
          </button>
          <div
            ref={variantOptionsRef}
            className={cn(
              'max-h-206 top-34 z-20 translate-y-18 absolute border border-t-0 border-midGrey bg-ecru w-full overflow-y-scroll',
              {
                'flex flex-col': variantSelectActive,
                hidden: !variantSelectActive,
                'scrollbar-hide': !isVariantOptionsOverflowed,
              }
            )}
          >
            {sizeOptions()}
          </div>
        </div>
      </ClickOutside>
    </div>
  )
}

export default AddonItemOptions
