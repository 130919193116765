const CaretRight = ({ ...props }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_8538_15188)">
        <path
          d="M14.638 10.4696C14.638 10.7196 14.5368 10.9796 14.3446 11.1696L9.34763 16.1696C8.95312 16.5596 8.31585 16.5696 7.92135 16.1696C7.52685 15.7796 7.51673 15.1496 7.92135 14.7596L12.2204 10.4596L7.91124 6.16956C7.51673 5.77956 7.52685 5.13956 7.91124 4.75956C8.30574 4.36956 8.95312 4.37956 9.33751 4.75956L14.3345 9.75956C14.5267 9.94956 14.6279 10.2096 14.6279 10.4596L14.638 10.4696Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_8538_15188">
          <rect width="12" height="7.0201" fill="white" transform="translate(7.61719 16.4697) rotate(-90)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CaretRight
