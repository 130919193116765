export const allowedTimeZones = [
  'Australia/ACT',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Broken_Hill',
  'Australia/Canberra',
  'Australia/Currie',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Hobart',
  'Australia/LHI',
  'Australia/Lindeman',
  'Australia/Lord_Howe',
  'Australia/Melbourne',
  'Australia/NSW',
  'Australia/North',
  'Australia/Perth',
  'Australia/Queensland',
  'Australia/South',
  'Australia/Sydney',
  'Australia/Tasmania',
  'Australia/Victoria',
  'Australia/West',
  'Australia/Yancowinna',
  'Pacific/Auckland',
];

export const countDownTimer = (countDownDate: Date) => {
  const now = new Date().getTime();
  const distance = countDownDate.getTime() - now;

  if (distance > 0) {
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    const hoursString = `${hours <= 0 ? '' : hours < 10 ? `0${hours}:` : `${hours}:`}`;
    const minutesString = `${minutes < 10 ? `0${minutes}:` : `${minutes}:`}`;
    const secondsString = `${seconds < 10 ? `0${seconds}` : `${seconds}`}`;

    const timer = `${hoursString}${minutesString}${secondsString}`;

    return timer;
  }

  return null;
};
