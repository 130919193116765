import { useUI } from '@components/ui'
import { handleChatMouseOver } from '@lib/gorgias'
import debounce from 'lodash.debounce'
import throttle from 'lodash.throttle'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useWindowSize } from 'usehooks-ts'
export const useNavigation = () => {
  const { pathname } = useRouter()
  const { width } = useWindowSize()
  const isMobile = width < 1024
  const [navigationHovered, setNavigationHovered] = useState<boolean>(false)
  const [navigationCondensed, setNavigationCondensed] = useState<boolean>(false)
  const [subMenuHoverIndex, setSubMenuHoverIndex] = useState<number>(0)
  const [activeMegaTitle, setActiveMegaTitle] = useState<string>('')
  const onHomePage = pathname === '/'
  const navBarTheme = {
    normal: {
      navElementColour: '#000000',
      navBarBackgroundColour: '#FBF9F6',
      navBarHeight: isMobile ? '72px' : '78px',
    },
    hovered: {
      navElementColour: '#000000',
      navBarBackgroundColour: '#FFFFFF',
      navBarHeight: isMobile ? '72px' : '78px',
    },
    condensed: {
      navElementColour: '#000000',
      navBarBackgroundColour: '#FFFFFF',
      navBarHeight: isMobile ? '52px' : '60px',
    },
    transparent: {
      navElementColour: '#FFFFFF',
      navBarBackgroundColour: 'transparent',
      navBarHeight: isMobile ? '72px' : '78px',
    },
  }
  const [navElementColour, setNavElementColour] = useState(navBarTheme.normal.navElementColour)
  const [navBarBackgroundColour, setNavBarBackgroundColour] = useState(navBarTheme.normal.navBarBackgroundColour)
  const [navBarHeight, setNavBarHeight] = useState(navBarTheme.normal.navBarHeight)
  const [anBarHeight, setAnBarHeight] = useState<number>(0);
  const { activeChat, setActiveChat } = useUI()
  const updateAnBarHeight = () => {
    const oldAnBar: any = document.getElementsByClassName('announcementBar')?.[0];
    const scrollingAnBar: any = document.getElementsByClassName('scrollingAnnouncementBar')?.[0];
    const anBarHeight = (oldAnBar?.offsetHeight || 0) + (scrollingAnBar?.offsetHeight || 0);
    setAnBarHeight(anBarHeight);
  };
  useEffect(() => {
    updateAnBarHeight()
    const handleUpdateAnBarHeight = debounce(() => {
      updateAnBarHeight()
    }, 300)
    window.addEventListener('resize', handleUpdateAnBarHeight);
    document.addEventListener('scroll', handleUpdateAnBarHeight)
    return () => {
      window.removeEventListener('resize', handleUpdateAnBarHeight);
      document.addEventListener('scroll', handleUpdateAnBarHeight)
    };
  }, []);
  useEffect(() => {
    const handleScroll = throttle(() => {
      const offset = navigationCondensed ? 0 : anBarHeight ? anBarHeight : 18
      const { scrollTop } = document.documentElement
      const scrolled = scrollTop > offset

      if (navigationCondensed !== scrolled) {
        setNavigationCondensed(scrolled)
      }
    }, 100)

    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [anBarHeight, navigationCondensed])
  useEffect(() => {
    if (onHomePage) {
      if (navigationCondensed) {
        setNavElementColour(navBarTheme.condensed.navElementColour)
        setNavBarBackgroundColour(navBarTheme.condensed.navBarBackgroundColour)
        setNavBarHeight(navBarTheme.condensed.navBarHeight)
      } else {
        if (navigationHovered) {
          setNavElementColour(navBarTheme.hovered.navElementColour)
          setNavBarBackgroundColour(navBarTheme.hovered.navBarBackgroundColour)
          setNavBarHeight(navBarTheme.hovered.navBarHeight)
        } else {
          setNavElementColour(navBarTheme.transparent.navElementColour)
          setNavBarBackgroundColour(navBarTheme.transparent.navBarBackgroundColour)
          setNavBarHeight(navBarTheme.transparent.navBarHeight)
        }
      }
    } else {
      if (navigationCondensed) {
        setNavElementColour(navBarTheme.condensed.navElementColour)
        setNavBarBackgroundColour(navBarTheme.condensed.navBarBackgroundColour)
        setNavBarHeight(navBarTheme.condensed.navBarHeight)
      } else {
        if (navigationHovered && activeMegaTitle !== '') {
          setNavElementColour(navBarTheme.hovered.navElementColour)
          setNavBarBackgroundColour(navBarTheme.hovered.navBarBackgroundColour)
          setNavBarHeight(navBarTheme.hovered.navBarHeight)
        } else {
          setNavElementColour(navBarTheme.normal.navElementColour)
          setNavBarBackgroundColour(navBarTheme.normal.navBarBackgroundColour)
          setNavBarHeight(navBarTheme.normal.navBarHeight)
        }
      }
    }
  }, [
    activeMegaTitle,
    navBarTheme.condensed.navBarBackgroundColour,
    navBarTheme.condensed.navBarHeight,
    navBarTheme.condensed.navElementColour,
    navBarTheme.hovered.navBarBackgroundColour,
    navBarTheme.hovered.navBarHeight,
    navBarTheme.hovered.navElementColour,
    navBarTheme.normal.navBarBackgroundColour,
    navBarTheme.normal.navBarHeight,
    navBarTheme.normal.navElementColour,
    navBarTheme.transparent.navBarBackgroundColour,
    navBarTheme.transparent.navBarHeight,
    navBarTheme.transparent.navElementColour,
    navigationCondensed,
    navigationHovered,
    onHomePage,
  ])

  let duration = 100
  let navTimer = setTimeout(() => { })
  let hoverTimer = setTimeout(() => { })
  let subNavTimer = setTimeout(() => { })
  let closeTimer = setTimeout(() => { })
  const handleActiveTitle = (nav_title: string) => {
    clearTimeout(closeTimer)
    clearTimeout(navTimer)
    if (activeMegaTitle !== '') {
      navTimer = setTimeout(() => {
        setActiveMegaTitle(nav_title)
      }, duration)
    } else {
      setActiveMegaTitle(nav_title)
    }
  }
  const handleActiveSubNav = (index: number, noDelay?: boolean) => {
    if (noDelay) {
      setSubMenuHoverIndex(index)
    } else {
      if (index !== 0 || subMenuHoverIndex !== 0) {
        subNavTimer = setTimeout(() => {
          setSubMenuHoverIndex(index)
        }, duration)
      }
    }
  }

  const closeNavigation = (nav_title: string) => {
    closeTimer = setTimeout(() => {
      setActiveMegaTitle('')
      setSubMenuHoverIndex(0)
    }, duration)
    if (activeMegaTitle !== nav_title) {
      clearTimeout(closeTimer)
    }
  }
  const handleSubNavOut = (activeIndex: number) => {
    if (subMenuHoverIndex !== activeIndex) clearTimeout(subNavTimer)
  }

  const handleNavigationHover = () => {
    clearTimeout(hoverTimer)
    setNavigationHovered(true)
    handleChatMouseOver(activeChat, setActiveChat)
  }
  const handleNavigationHoverOut = () => {
    hoverTimer = setTimeout(() => {
      setNavigationHovered(false)
    }, 200)
  }

  return {
    navigationHovered,
    activeMegaTitle,
    navigationCondensed,
    navElementColour,
    navBarBackgroundColour,
    navBarHeight,
    subMenuHoverIndex,
    anBarHeight,
    setSubMenuHoverIndex,
    handleActiveSubNav,
    setActiveMegaTitle,
    setNavigationHovered,
    handleActiveTitle,
    handleSubNavOut,
    handleNavigationHoverOut,
    handleNavigationHover,
    closeNavigation,
  }
}
