const GorgiasChatButton = ({ mobile = false, opened = false, ...props }) => {
  return (
    <>
      {mobile ? (
        <>
          <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_8949_8321)">
              <circle cx="33" cy="29" r="25" fill="black" />
              <g clipPath="url(#clip0_8949_8321)">
                <g clipPath="url(#clip1_8949_8321)">
                  <path
                    d="M42.7227 35.8931L42.7318 35.9027L42.7333 35.904L42.7394 35.9103C43.0287 36.2172 43.1079 36.6648 42.9411 37.0511C42.7742 37.4374 42.3926 37.6873 41.9717 37.6873C39.5937 37.6873 37.7135 36.7084 36.5708 35.9095C35.4582 36.2536 34.2592 36.4498 32.9998 36.4498C27.488 36.4498 22.9748 32.8165 22.9748 28.2811C22.9748 23.7456 27.488 20.1123 32.9998 20.1123C38.5115 20.1123 43.0248 23.7456 43.0248 28.2811C43.0248 30.1323 42.2613 31.8263 40.9988 33.1933C41.2817 33.9131 41.7024 34.5787 42.0612 35.0728C42.2462 35.3277 42.414 35.5358 42.5364 35.6809C42.5977 35.7535 42.6475 35.8101 42.6823 35.8489C42.6998 35.8684 42.7134 35.8832 42.7227 35.8931ZM36.1796 34.4731L36.1796 34.4731L36.7674 34.2875L36.8266 34.2688L36.8773 34.3046L37.38 34.6604L37.3801 34.6605C38.2067 35.2463 39.4529 35.921 40.9942 36.1348C40.5412 35.5353 39.9592 34.6509 39.5808 33.6465C39.5808 33.6465 39.5808 33.6464 39.5808 33.6464L39.3063 32.9235L39.2786 32.8505L39.332 32.7936L39.8618 32.229L39.8621 32.2287C40.9606 31.0655 41.5373 29.6993 41.5373 28.2811C41.5373 24.6253 37.7365 21.5998 32.9998 21.5998C28.2631 21.5998 24.4623 24.6253 24.4623 28.2811C24.4623 31.9368 28.2631 34.9623 32.9998 34.9623C34.0815 34.9623 35.1516 34.798 36.1796 34.4731ZM37.9498 27.2279C38.5331 27.2279 39.0029 27.6977 39.0029 28.2811C39.0029 28.8644 38.5331 29.3342 37.9498 29.3342C37.3664 29.3342 36.8967 28.8644 36.8967 28.2811C36.8967 27.6977 37.3664 27.2279 37.9498 27.2279ZM32.9998 27.2279C33.5831 27.2279 34.0529 27.6977 34.0529 28.2811C34.0529 28.8644 33.5831 29.3342 32.9998 29.3342C32.4164 29.3342 31.9467 28.8644 31.9467 28.2811C31.9467 27.6977 32.4164 27.2279 32.9998 27.2279ZM28.0498 27.2279C28.6331 27.2279 29.1029 27.6977 29.1029 28.2811C29.1029 28.8644 28.6331 29.3342 28.0498 29.3342C27.4664 29.3342 26.9967 28.8644 26.9967 28.2811C26.9967 27.6977 27.4664 27.2279 28.0498 27.2279Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.25"
                  />
                </g>
              </g>
            </g>
            <defs>
              <filter
                id="filter0_d_8949_8321"
                x="0"
                y="0"
                width="66"
                height="66"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="4" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8949_8321" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8949_8321" result="shape" />
              </filter>
              <clipPath id="clip0_8949_8321">
                <rect width="22" height="22" fill="white" transform="matrix(-1 0 0 1 44 19)" />
              </clipPath>
              <clipPath id="clip1_8949_8321">
                <rect width="22" height="22" fill="white" transform="matrix(-1 0 0 1 44 19)" />
              </clipPath>
            </defs>
          </svg>
        </>
      ) : (
        <>
          {opened ? (
            <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_9013_8011)">
                <circle cx="36" cy="32" r="28" fill="black" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M29.578 25.5787C29.9686 25.1882 30.6017 25.1882 30.9923 25.5787L42.4209 37.0073C42.8114 37.3978 42.8114 38.031 42.4209 38.4215C42.0303 38.812 41.3972 38.812 41.0067 38.4215L29.578 26.9929C29.1875 26.6024 29.1875 25.9692 29.578 25.5787Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M29.5782 38.4215C29.1877 38.0309 29.1877 37.3978 29.5782 37.0072L41.0068 25.5786C41.3973 25.1881 42.0305 25.1881 42.421 25.5786C42.8115 25.9692 42.8115 26.6023 42.421 26.9929L30.9924 38.4215C30.6019 38.812 29.9687 38.812 29.5782 38.4215Z"
                  fill="white"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_9013_8011"
                  x="0"
                  y="0"
                  width="72"
                  height="72"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="4" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9013_8011" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9013_8011" result="shape" />
                </filter>
              </defs>
            </svg>
          ) : (
            <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_8949_8328)">
                <circle cx="36" cy="32" r="28" fill="black" />
                <g clipPath="url(#clip0_8949_8328)">
                  <g clipPath="url(#clip1_8949_8328)">
                    <path
                      d="M45.7227 38.8931L45.7318 38.9027L45.7333 38.904L45.7394 38.9103C46.0287 39.2172 46.1079 39.6648 45.9411 40.0511C45.7742 40.4374 45.3926 40.6873 44.9717 40.6873C42.5937 40.6873 40.7135 39.7084 39.5708 38.9095C38.4582 39.2536 37.2592 39.4498 35.9998 39.4498C30.488 39.4498 25.9748 35.8165 25.9748 31.2811C25.9748 26.7456 30.488 23.1123 35.9998 23.1123C41.5115 23.1123 46.0248 26.7456 46.0248 31.2811C46.0248 33.1323 45.2613 34.8263 43.9988 36.1933C44.2817 36.9131 44.7024 37.5787 45.0612 38.0728C45.2462 38.3277 45.414 38.5358 45.5364 38.6809C45.5977 38.7535 45.6475 38.8101 45.6823 38.8489C45.6998 38.8684 45.7134 38.8832 45.7227 38.8931ZM39.1796 37.4731L39.1796 37.4731L39.7674 37.2875L39.8266 37.2688L39.8773 37.3046L40.38 37.6604L40.3801 37.6605C41.2067 38.2463 42.4529 38.921 43.9942 39.1348C43.5412 38.5353 42.9592 37.6509 42.5808 36.6465C42.5808 36.6465 42.5808 36.6464 42.5808 36.6464L42.3063 35.9235L42.2786 35.8505L42.332 35.7936L42.8618 35.229L42.8621 35.2287C43.9606 34.0655 44.5373 32.6993 44.5373 31.2811C44.5373 27.6253 40.7365 24.5998 35.9998 24.5998C31.2631 24.5998 27.4623 27.6253 27.4623 31.2811C27.4623 34.9368 31.2631 37.9623 35.9998 37.9623C37.0815 37.9623 38.1516 37.798 39.1796 37.4731ZM40.9498 30.2279C41.5331 30.2279 42.0029 30.6977 42.0029 31.2811C42.0029 31.8644 41.5331 32.3342 40.9498 32.3342C40.3664 32.3342 39.8967 31.8644 39.8967 31.2811C39.8967 30.6977 40.3664 30.2279 40.9498 30.2279ZM35.9998 30.2279C36.5831 30.2279 37.0529 30.6977 37.0529 31.2811C37.0529 31.8644 36.5831 32.3342 35.9998 32.3342C35.4164 32.3342 34.9467 31.8644 34.9467 31.2811C34.9467 30.6977 35.4164 30.2279 35.9998 30.2279ZM31.0498 30.2279C31.6331 30.2279 32.1029 30.6977 32.1029 31.2811C32.1029 31.8644 31.6331 32.3342 31.0498 32.3342C30.4664 32.3342 29.9967 31.8644 29.9967 31.2811C29.9967 30.6977 30.4664 30.2279 31.0498 30.2279Z"
                      fill="white"
                      stroke="white"
                      strokeWidth="0.25"
                    />
                  </g>
                </g>
              </g>
              <defs>
                <filter
                  id="filter0_d_8949_8328"
                  x="0"
                  y="0"
                  width="72"
                  height="72"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="4" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8949_8328" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8949_8328" result="shape" />
                </filter>
                <clipPath id="clip0_8949_8328">
                  <rect width="22" height="22" fill="white" transform="matrix(-1 0 0 1 47 22)" />
                </clipPath>
                <clipPath id="clip1_8949_8328">
                  <rect width="22" height="22" fill="white" transform="matrix(-1 0 0 1 47 22)" />
                </clipPath>
              </defs>
            </svg>
          )}
        </>
      )}
    </>
  )
}

export default GorgiasChatButton
