import { useQuery } from '@apollo/client'
import commerceConfig from '@commerce/config'
import { LineItem } from '@commerce/types/cart'
import { checkoutToCart, getCheckoutId, getCheckoutQuery, getCurrencyCountryCode } from '@commerce/utils'
import { TranslatingAnnouncementBar } from '@components/common'
import { AccountV2, CaretLeft, CartV2, Close, Logo, MenuV2, Search, StickyLogo } from '@components/icons'
import { useUI } from '@components/ui'
import ClickOutside from '@lib/click-outside'
import { handleChatMouseOver } from '@lib/gorgias'
import { useNavDataLayer } from '@lib/hooks/useNavDataLayer'
import { useNavigation } from '@lib/hooks/useNavigation'
import { MainNavigationData, ScrollingAnnouncementBar, TopLevelNavigationItem } from '@lib/types/Navigation'
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import debounce from 'lodash.debounce'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, useEffect, useRef, useState } from 'react'
import CountrySelector from '../CountrySelector'
import SearchBar from '../SearchBar'

import MobileExtraLinks from './MobileExtraLinks'
import MobileFeaturedTiles from './MobileFeaturedTiles'
import MobileNavComponent from './MobileNavComponent'

interface Props {
  navData: MainNavigationData
  bannerProps: ScrollingAnnouncementBar
  countryFlags?: {
    [key: string]: any
  }[]
}
const countItem = (count: number, item: LineItem) => count + item.quantity
// oldBannerProps
const MobileNavV2: FC<Props> = ({ navData, bannerProps, countryFlags }) => {
  const { locale, events } = useRouter()
  const checkoutId = getCheckoutId(locale)
  const { data } = useQuery(getCheckoutQuery, {
    variables: { checkoutId, country: getCurrencyCountryCode(locale) },
    context: { locale },
    skip: !checkoutId,
  })
  const {
    config: { defaultAlt },
  } = commerceConfig
  const { updateNavDataLayer } = useNavDataLayer()
  const cart = data?.node ? checkoutToCart({ checkout: data.node }) : null
  const itemsCount = cart?.lineItems.reduce(countItem, 0) ?? 0
  const [showSearchBar, setShowSearchBar] = useState(false)
  const [openNav, setOpenNav] = useState(false)
  const [displayCountrySelector, setDisplayCountrySelector] = useState(false)
  const [navItems, setNavItems] = useState<TopLevelNavigationItem>(navData.top_level_navigation[0])
  const [openSubNav, setOpenSubNav] = useState(false)
  const [scrollTopValue, setScrollTopValue] = useState<number>(0)
  const [subMenuScrollTopValue, setSubMenuScrollTopValue] = useState<number>(0)
  const scrollRef = useRef<HTMLDivElement>(null)
  const mobileNavRef = useRef<HTMLDivElement>(null)
  const searchBarRef = useRef<HTMLDivElement>(null)
  const countrySelectorRef = useRef<HTMLDivElement>(null)
  const subNavRef = useRef<HTMLDivElement>(null)
  const { top_level_navigation, mobile_extra_links } = navData || {}

  const {
    openSearch,
    closeSearch,
    arPopupView,
    openSidebar,
    activeChat,
    setActiveChat,
    openMobileNav,
    closeMobileNav,
    displaySearch,
    displayMobileNav,
  } = useUI()

  const {
    navigationCondensed,
    navBarHeight,
    navBarBackgroundColour,
    navElementColour,
    activeMegaTitle,
    subMenuHoverIndex,
    anBarHeight,
    setSubMenuHoverIndex,
    setActiveMegaTitle,
    handleActiveTitle,
    handleActiveSubNav,
  } = useNavigation()

  useEffect(() => {
    const handleRouteChange = () => {
      handleCloseNav()
    }
    events.on('routeChangeStart', handleRouteChange)
    return () => {
      events.off('routeChangeStart', handleRouteChange)
    }
  }, [events, setActiveMegaTitle, setSubMenuHoverIndex])

  const handleOpenSearchBar = () => {
    if (openNav) {
      setOpenNav(false)
    }
    setShowSearchBar(true)
    openSearch()
    window.setTimeout(() => {
      searchBarRef.current?.focus()
    }, 200)
  }

  const handleCloseSearchBar = () => {
    closeSearch()
    const sidebar = scrollRef.current
    if (showSearchBar && sidebar) {
      setShowSearchBar(false)
    }
  }

  const handleOpenCart = () => {
    handleCloseNav()
    openSidebar()
  }

  const handleOpenNav = () => {
    setOpenNav(true)
    handleChatMouseOver(activeChat, setActiveChat)
  }

  const handleCloseNav = () => {
    setOpenNav(false)
    closeSearch()
    setShowSearchBar(false)
  }

  const handleOpenSubNav = (index: number) => {
    handleActiveSubNav(index, true)
    setOpenSubNav(true)
  }

  const handleCloseSubNav = () => {
    handleActiveSubNav(-1)
    setOpenSubNav(false)
    setSubMenuScrollTopValue(0)
  }

  const showCountrySelector = () => {
    setOpenNav(false)
    setDisplayCountrySelector(true)
  }

  const hideCountrySelector = () => {
    setDisplayCountrySelector(false)
  }

  const debounceSetScrollTop = debounce((scrollTop: number) => {
    setScrollTopValue(scrollTop)
  }, 200)
  const debounceSetSubMenuScrollTop = debounce((scrollTop: number) => {
    setSubMenuScrollTopValue(scrollTop)
  }, 200)

  useEffect(() => {
    const mobileNav = mobileNavRef.current
    const subMenu = subNavRef.current
    const handleScroll = () => {
      if (mobileNav && !subMenu) {
        const scrollTop = mobileNav.scrollTop
        debounceSetScrollTop(scrollTop)
      }
    }
    mobileNav?.addEventListener('scroll', handleScroll)
    return () => {
      mobileNav?.removeEventListener('scroll', handleScroll)
      debounceSetScrollTop.cancel()
    }
  }, [debounceSetScrollTop])

  useEffect(() => {
    const mobileNav = mobileNavRef.current
    const subMenu = subNavRef.current
    const handleScroll = () => {
      if (mobileNav && subMenu) {
        const scrollTop = mobileNav.scrollTop
        debounceSetSubMenuScrollTop(scrollTop)
      }
    }
    mobileNav?.addEventListener('scroll', handleScroll)
    return () => {
      mobileNav?.removeEventListener('scroll', handleScroll)
      debounceSetSubMenuScrollTop.cancel()
    }
  }, [debounceSetSubMenuScrollTop])

  useEffect(() => {
    const mobileNav = mobileNavRef.current
    const subMenu = subNavRef.current
    if (mobileNav && !subMenu) {
      mobileNav.scrollTo({ top: scrollTopValue })
    }
    if (mobileNav && subMenu) {
      mobileNav.scrollTo({ top: subMenuScrollTopValue })
    }
  }, [openNav, openSubNav, scrollTopValue, subMenuScrollTopValue])
  useEffect(() => {
    const mobileNav = mobileNavRef.current
    if (mobileNav) {
      mobileNav.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [activeMegaTitle])
  useEffect(() => {
    setNavItems(navData?.top_level_navigation[0] || null)
    handleActiveTitle(top_level_navigation[0]?.title!)
  }, [])

  useEffect(() => {
    if (openNav) {
      openMobileNav()
    } else {
      closeMobileNav()
    }
  }, [closeMobileNav, openMobileNav, openNav])

  useEffect(() => {
    const mobileNav = mobileNavRef.current
    if (openNav && mobileNav) {
      disableBodyScroll(mobileNav, { reserveScrollBarGap: true })
    }
    if (!openNav && mobileNav) {
      enableBodyScroll(mobileNav)
      clearAllBodyScrollLocks()
    }
  }, [openNav])

  useEffect(() => {
    const countrySelector = countrySelectorRef.current
    if (displayCountrySelector && countrySelector) {
      disableBodyScroll(countrySelector, { reserveScrollBarGap: true })
    }
    if (!displayCountrySelector && countrySelector) {
      enableBodyScroll(countrySelector)
      clearAllBodyScrollLocks()
    }
  }, [displayCountrySelector])

  const rootVariants = {
    open: {
      x: 0,
    },
    closed: {
      x: '-100%',
    },
  }

  const backdropVariant = {
    open: {
      opacity: 1,
    },
    closed: {
      opacity: 0,
    },
  }

  const countrySelectorVariant = {
    open: {
      y: 0,
    },
    closed: {
      y: '100%',
    },
  }

  return (
    <>
      <div
        className={cn(
          `navigationHeader block laptop:hidden sticky z-50 top-0 w-full transition-all duration-300 ease-in`,
          {
            'z-40': !arPopupView,
            'z-50': showSearchBar,
          }
        )}
        ref={scrollRef}
      >
        <div className="wrapper laptop:hidden grid w-full h-full grid-cols-3 z-50">
          <div className="flex items-center">
            <button aria-label="Menu" onClick={handleOpenNav}>
              <MenuV2 color={navElementColour} />
            </button>
          </div>
          <div className={cn(`${navigationCondensed ? 'py-10' : 'py-16'} flex justify-center items-center`)}>
            {!navigationCondensed ? (
              <Link aria-label={`${defaultAlt}`} href={`/${locale || ''}`} passHref>
                <div className="flex col-span-1 justify-center relative top-2">
                  <Logo color={navElementColour} />
                </div>
              </Link>
            ) : (
              <Link aria-label={`${defaultAlt}`} href={`/${locale || ''}`} passHref>
                <div className="flex col-span-1 justify-center">
                  <StickyLogo type="desktop" />
                </div>
              </Link>
            )}
          </div>
          <div className="flex items-center gap-20 justify-end">
            <button aria-label="Search" onClick={handleOpenSearchBar}>
              <Search color={navElementColour} />
            </button>
            <button aria-label="Cart" onClick={openSidebar} className="relative">
              <CartV2 color={navElementColour} />
              {itemsCount > 0 && itemsCount < 10 && (
                <div className="w-20 h-20 absolute rounded-full itemNumber text-10 font-bold text-white flex items-center justify-center">
                  {itemsCount}
                </div>
              )}
              {itemsCount > 0 && itemsCount >= 10 && (
                <div className="w-20 h-20 absolute rounded-full itemNumber text-10 font-bold text-white flex items-center justify-center">
                  <div className="w-3 h-3 bg-white mx-auto rounded-full" />
                </div>
              )}
            </button>
          </div>
        </div>
        <AnimatePresence>
          {displaySearch && !displayMobileNav && (
            <motion.div
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ opacity: { duration: 0.2 } }}
              className={cn('inset-0 w-full h-screen', {
                'absolute top-52': navigationCondensed,
                'absolute topNonScroll': !navigationCondensed,
              })}
            >
              <ClickOutside active={displaySearch && !displayMobileNav} onClick={handleCloseSearchBar}>
                <div className="bg-lightBg">
                  <SearchBar
                    handleCloseSearchBar={handleCloseSearchBar}
                    iconType="mobile"
                    showSearchBar={displaySearch && !displayMobileNav}
                  />
                </div>
              </ClickOutside>
              <div className="bg-black bg-opacity-50 h-full w-full overlay z-50" />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {displayCountrySelector && (
            <motion.div
              variants={backdropVariant}
              initial="closed"
              animate="open"
              exit="closed"
              transition={{ opacity: { duration: 0.3 } }}
              className="fixed inset-0 bg-black bg-opacity-50 w-screen h-full"
            >
              <ClickOutside active={displayCountrySelector} onClick={hideCountrySelector}>
                <motion.div
                  variants={countrySelectorVariant}
                  initial="closed"
                  animate="open"
                  exit="closed"
                  transition={{ y: { duration: 0.3, type: 'tween' } }}
                  className=" bg-white absolute bottom-0 w-full rounded-t-16"
                >
                  <div ref={countrySelectorRef}>
                    <CountrySelector onClose={hideCountrySelector} applyScrollLock />
                  </div>
                  <button onClick={hideCountrySelector} className="absolute top-14 right-14">
                    <Close />
                  </button>
                </motion.div>
              </ClickOutside>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence>
        {openNav && (
          <motion.div
            variants={rootVariants}
            animate="open"
            initial="closed"
            exit="closed"
            transition={{ x: { duration: 0.3, type: 'tween' } }}
            className="fixed inset-0 z-50 bg-ecru"
          >
            <div ref={mobileNavRef} className="bg-ercu h-full overflow-y-scroll relative">
              <div className="sticky top-0 z-40">
                {/* {oldBannerProps && <AnnouncementBar bannerData={oldBannerProps} />} */}
                {bannerProps && <TranslatingAnnouncementBar bannerProps={bannerProps} />}
              </div>
              <div
                className={`sticky mobileNavHeader wrapper z-40 grid w-full grid-cols-3 pt-20 pb-19 bg-white border-b border-warmWhite`}
              >
                <div className="flex items-center gap-20">
                  <button onClick={handleCloseNav}>
                    <Close />
                  </button>
                  <button onClick={handleCloseNav}>
                    <Link href="/me" passHref>
                      <AccountV2 />
                    </Link>
                  </button>
                </div>
                <div className="flex justify-center items-center">
                  <Link href={`/${locale || ''}`} passHref>
                    <div className="flex col-span-1 justify-center">
                      <StickyLogo type="mobile" />
                    </div>
                  </Link>
                </div>
                <div className="flex items-center gap-20 justify-end">
                  <button onClick={handleOpenSearchBar}>
                    <Search />
                  </button>
                  <button onClick={handleOpenCart} className="relative">
                    <CartV2 />
                    {itemsCount > 0 && itemsCount < 10 && (
                      <div className="w-20 h-20 absolute rounded-full itemNumber text-10 font-bold text-white flex items-center justify-center">
                        {itemsCount}
                      </div>
                    )}
                    {itemsCount > 0 && itemsCount >= 10 && (
                      <div className="w-20 h-20 absolute rounded-full itemNumber text-10 font-bold text-white flex items-center justify-center">
                        <div className="w-3 h-3 bg-white mx-auto rounded-full"></div>
                      </div>
                    )}
                  </button>
                </div>
              </div>
              {openSubNav ? (
                <>
                  <div className={`z-40 wrapper bg-white w-full sticky subNavHeader`} ref={subNavRef}>
                    <div className="grid grid-cols-3 h-52">
                      <button onClick={handleCloseSubNav}>
                        <CaretLeft />
                      </button>
                      <div className="flex items-center justify-center font-g-bold text-12 leading-16 uppercase tracking-spacingBitWider whitespace-nowrap">
                        {navItems?.sub_navigation?.[subMenuHoverIndex]?.sub_navigation_title}
                      </div>
                      <div />
                    </div>
                  </div>
                  <div className="z-40">
                    <MobileNavComponent
                      activeMegaTitle={activeMegaTitle}
                      handleOpenSubNav={handleOpenSubNav}
                      subNavItems={navItems?.sub_navigation?.[subMenuHoverIndex]}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={`overflow-x-auto z-40 w-full bg-white scrollbar-hide sticky subNavHeader `}>
                    <div className="topNavContainer">
                      {top_level_navigation &&
                        top_level_navigation.map((navItem: TopLevelNavigationItem, index: number) => {
                          const { title, link, highlight_title, highlight_colour, body, sub_navigation } = navItem || {}
                          const isDirectLink =
                            link && body && body.length == 0 && sub_navigation && sub_navigation.length == 0
                          const handleTopNavClick = () => {
                            if (!isDirectLink) {
                              setNavItems(navItem)
                              handleActiveTitle(title!)
                              handleActiveSubNav(-1, true)
                            }
                          }
                          return (
                            <div
                              style={
                                {
                                  '--highlight-colour': highlight_title
                                    ? highlight_colour
                                    : activeMegaTitle === title
                                    ? '#6C704A'
                                    : '#000000',
                                } as React.CSSProperties
                              }
                              onClick={handleTopNavClick}
                              key={index}
                              className={cn('topNavItem', { active: activeMegaTitle === title })}
                            >
                              {isDirectLink ? (
                                <Link href={link || '/'} passHref>
                                  <div
                                    className="flex flex-col"
                                    onClick={() => updateNavDataLayer(title!, 'Mobile Menu', 'Mobile Menu', link!)}
                                  >
                                    {title &&
                                      title.split('/').map((linkString, index) => {
                                        return (
                                          <div
                                            className="font-g-bold text-12 leading-16 tracking-spacingBitWider uppercase"
                                            key={index}
                                          >
                                            {linkString}
                                          </div>
                                        )
                                      })}
                                  </div>
                                </Link>
                              ) : (
                                <div className="flex flex-col">
                                  {title &&
                                    title.split('/').map((linkString, index) => {
                                      return (
                                        <div
                                          className="font-g-bold text-12 leading-16 tracking-spacingBitWider uppercase"
                                          key={index}
                                        >
                                          {linkString}
                                        </div>
                                      )
                                    })}
                                </div>
                              )}
                            </div>
                          )
                        })}
                    </div>
                  </div>
                  <div className="z-40">
                    <MobileNavComponent
                      activeMegaTitle={activeMegaTitle}
                      handleOpenSubNav={handleOpenSubNav}
                      navItems={navItems}
                    />
                    <MobileFeaturedTiles activeMegaTitle={activeMegaTitle} navItems={navItems.body1} />
                    <MobileExtraLinks
                      countryFlags={countryFlags}
                      showCountrySelector={showCountrySelector}
                      mobile_extra_links={mobile_extra_links}
                      handleCloseNav={handleCloseNav}
                    />
                  </div>
                </>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <style jsx>
        {`
          .itemNumber {
            background: #be4d35;
            top: -10px;
            right: -10px;
          }
          .navigationHeader {
            height: ${navBarHeight};
            background-color: ${navBarBackgroundColour};
          }
          .topNonScroll {
            top: 0;
          }
          .topNavContainer {
            display: flex;
            flex-wrap: nowrap;
            position: relative;
            height: 52px;
          }
          .topNavContainer:last-child {
            padding-right: 20px;
          }
          .topNavItem {
            flex: 0 0 calc(25%);
            text-align: center;
            display: flex;
            justify-content: center; /* Horizontally centers the content */
            align-items: center;
            color: var(--highlight-colour);
            border-bottom-width: 2px;
            border-color: #ffffff;
          }
          .topNavItem.active {
            border-bottom-width: 2px;
            border-color: #6c704a;
          }
          .mobileNavHeader {
            top: ${anBarHeight}px;
          }
          .subNavHeader {
            top: ${anBarHeight + 72}px;
          }
        `}
      </style>
    </>
  )
}
export default MobileNavV2
