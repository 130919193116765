import cn from 'classnames'
import { FC, useEffect, useRef, useState } from 'react'
import s from './SlideInSidebar.module.css'
//import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { Cross } from '@components/icons'
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

interface SidebarProps {
  children: any
  onClose: () => void
  orientation?: 'left' | 'right' | 'bottom'
  className?: string
  display: boolean
  closeButton?: boolean
  applyScrollLock?: boolean
  overlay?: boolean
  popup?: boolean
}

const SlideInSideBar: FC<SidebarProps> = ({
  children,
  onClose,
  orientation = 'bottom',
  className,
  display,
  closeButton = false,
  applyScrollLock = true,
  overlay = true,
  popup = false,
}) => {
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>
  const [scrollLock, setScrollLock] = useState(false)
  const handleOnClose = () => {
    onClose()
    clearAllBodyScrollLocks()
  }

  useEffect(() => {
    const sidebar = ref.current

    if (display && applyScrollLock) {
      disableBodyScroll(sidebar, {
        reserveScrollBarGap: true,
        allowTouchMove: (el) => {
          while (el && el !== document.body) {
            if (el.getAttribute('data-cart-content') !== null) {
              return true
            }

            el = el.parentElement as HTMLElement
          }
        },
      })
      setScrollLock(true)
    }

    if (!display && scrollLock) {
      enableBodyScroll(sidebar)
      clearAllBodyScrollLocks()
      setScrollLock(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [display])

  const orientationClasses = cn({
    'transform right-0 h-full': orientation === 'right',
    'transform left-0 h-full': orientation === 'left',
    'transform bottom-0 rounded-t-10 w-full ': orientation === 'bottom',
  })

  return (
    <>
      <div
        className={cn(s.root, orientation, {
          visible: display,
          invisible: !display,
          'bg-black': !popup,
          'inset-0': !popup,
          'inset-x-0 bottom-0 top-4/5': popup,
        })}
      >
        <div className={cn({ [`${s.overlay}`]: overlay })} onClick={handleOnClose}></div>
        <div className={cn(s.sidebar, orientationClasses, className, { 'bg-white': !popup })} ref={ref}>
          {closeButton && (
            <button onClick={handleOnClose} aria-label="Close panel" className={s.close}>
              <Cross className="h-14 w-14 " />
            </button>
          )}
          {children}
        </div>
      </div>
    </>
  )
}

export default SlideInSideBar
