import React, { FC, useState, useRef, useEffect } from 'react'
import s from './Quantity.module.css'
import { Cross, Plus, Minus } from '@components/icons'
import cn from 'classnames'
export interface QuantityProps {
  value: number
  id: string
  isUpdating: boolean
  isFocused: boolean
  increase: () => any
  decrease: () => any
  handleRemove?: React.MouseEventHandler<HTMLButtonElement>
  handleChange: (e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>, id: string) => void
  max?: number
}

const Quantity: FC<QuantityProps> = ({
  value,
  id,
  isUpdating,
  isFocused,
  increase,
  decrease,
  handleChange,
  handleRemove,
  max = 100,
}) => {
  const [currentValue, setCurrentValue] = useState(value)
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>

  useEffect(() => {
    if (inputRef.current && isFocused) {
      inputRef.current.focus()
    }
  }, [inputRef, isFocused])

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (value !== Number(e.target.value)) {
      handleChange(e, id)
    }
    setCurrentValue(Number(e.target.value))
  }
  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    const element = e.target as HTMLInputElement

    if (e.key == 'Backspace') {
      if (element.value.length == 1) {
        element.value = '0'
        element.select()
        e.preventDefault()
      }
    } else if (e.key == 'Enter') {
      if (value !== Number(element.value)) {
        handleChange(e, id)
      }
      setCurrentValue(Number(element.value))
    }
  }

  const onBlur: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    let newValue = value
    if (e.target.value == '0') {
      if (!confirm('Do you really want to delete this product?')) {
        e.preventDefault()
        e.target.value = String(value)
      }
    }
    if (e.target.value != '') {
      newValue = Number(e.target.value)
    }
    if (newValue < max + 1) {
      onChange(e)
    }
  }
  return (
    <div className={cn(s.quantity, 'flex flex-row mr-10 h-20 border-lightGrey border')}>
      {handleRemove && (
        <button className={s.actions} onClick={handleRemove}>
          <Cross width={20} height={20} />
        </button>
      )}
      <button
        type="button"
        onClick={decrease}
        className={s.actions}
        style={{ marginLeft: '-1px' }}
        disabled={isUpdating || value <= 1}
      >
        <Minus width={18} height={18} />
      </button>
      <label className="w-full ml-2">
        <input
          style={isUpdating ? { opacity: 0.2 } : {}}
          key={`quantity_${id}`}
          ref={inputRef}
          className={s.input}
          disabled={isUpdating}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          onChange={(e) => setCurrentValue(Number(e.target.value) < max + 1 ? Number(e.target.value) : max)}
          value={currentValue}
          type="number"
          max={max}
          min="0"
        />
      </label>
      <button
        type="button"
        onClick={increase}
        className={cn(s.actions)}
        style={{ marginLeft: '-1px' }}
        disabled={isUpdating || value < 1 || value >= max}
      >
        <Plus width={18} height={18} />
      </button>
    </div>
  )
}

export default Quantity
