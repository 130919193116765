import { Button } from '@components/ui'
import Link from 'next/link'
import { useAcceptCookies } from '@lib/hooks/useAcceptCookies'
import cn from 'classnames'
import s from './GDPR.module.css'

interface GDPRProps {
  className?: string
  buttonText: string
}

const GDPR: React.FC<GDPRProps> = ({ className, buttonText }) => {
  const { acceptedCookies, onAcceptCookies } = useAcceptCookies()

  const handleClick = () => {
    onAcceptCookies()
  }

  if (acceptedCookies) {
    return <></>
  }

  return (
    <div className={cn(s.gdpr, 'fixed inset-x-0 bottom-0 bg-black text-14 leading-24', className)}>
      <div className="wrapper gap-8 flex flex-wrap text-center text-white items-center justify-stretch tablet:text-left tablet:flex-nowrap tablet:justify-between py-16">
        <span className="block w-full tablet:w-auto">
          Cookies improve your experience - by browsing our site you agree to our{' '}
          <Link href="/help/privacy-policy">privacy policy</Link>.
        </span>
        <Button
          className={'w-full tablet:w-auto'}
          style={{ paddingTop: 8, paddingBottom: 8 }}
          type="button"
          variant="secondary"
          bg="dark"
          onClick={handleClick}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  )
}

export default GDPR
