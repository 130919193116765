import { customLink } from '@lib/prismic'
import { AnnouncementItem as AnnouncementItemType} from '@lib/types/Navigation'
import cn from 'classnames'
import Link from 'next/link'
import { RichText } from 'prismic-reactjs'
import { FC, useEffect, useState } from 'react'
import CountdownTimer from '../CountdownTimer'

interface Props {
  announcementItem: AnnouncementItemType
  text_colour: string
}

const AnnouncementItem: FC<Props> = ({ announcementItem, text_colour }) => {
  const [countdownFinished, setCountdownFinished] = useState(true)

  const {
    announcement_title,
    announcement_title_mobile,
    item_link,
    show_countdown,
    countdown_end_date_and_time,
    countdown_running_text,
  } = announcementItem

  const desktopContents = announcement_title && RichText.asText(announcement_title)
  const mobileContents = announcement_title_mobile && RichText.asText(announcement_title_mobile)

  useEffect(() => {
    if (show_countdown && new Date(countdown_end_date_and_time) > new Date()) {
      setCountdownFinished(false)
    }
  }, [show_countdown, countdown_end_date_and_time])

  const blockLink = item_link?.url?.startsWith('https:///') ? item_link?.url.replace('https:///', '/') : item_link?.url

  const renderContent = () => (
    <>
      {countdownFinished ? (
        <>
          <div className="hidden tablet:block announcementItem font-g-medium text-12 leading-20">
            <RichText render={announcement_title} serializeHyperlink={customLink} />
          </div>
          <div className="announcementItem font-g-medium text-12 leading-20 tablet:hidden">
            {mobileContents ? (
              <RichText render={announcement_title_mobile} serializeHyperlink={customLink} />
            ) : (
              <RichText render={announcement_title} serializeHyperlink={customLink} />
            )}
          </div>
        </>
      ) : (
        <CountdownTimer
          date={countdown_end_date_and_time}
          text={countdown_running_text}
          textColour={text_colour}
          setCountdownFinished={setCountdownFinished}
        />
      )}
    </>
  )

  return (
    <div>
      {desktopContents && (
        <div className={cn('w-full flex justify-center items-center', { 'cursor-pointer': blockLink })}>
          {blockLink ? (
            <Link href={blockLink} passHref legacyBehavior>
              <div>{renderContent()}</div>
            </Link>
          ) : (
            renderContent()
          )}
        </div>
      )}
      <style jsx global>
        {`
          .announcementItem a {
            text-decoration: underline;
          }
        `}
      </style>
    </div>
  )
}

export default AnnouncementItem
