const CaretDown = ({ color = '#6A6A6A', ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_8538_18751)">
        <path
          d="M8.00013 11.0248C7.80013 11.0248 7.59213 10.9457 7.44014 10.7954L3.44013 6.88852C3.12813 6.58008 3.12013 6.08183 3.44013 5.77339C3.75213 5.46495 4.25613 5.45704 4.56813 5.77339L8.00813 9.1346L11.4401 5.76548C11.7521 5.45704 12.2641 5.46495 12.5681 5.76548C12.8801 6.07392 12.8721 6.58008 12.5681 6.88061L8.56813 10.7875C8.41613 10.9378 8.20814 11.0169 8.00813 11.0169L8.00013 11.0248Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_8538_18751">
          <rect width="9.6" height="5.48865" fill="white" transform="translate(3.2002 5.53613)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CaretDown
