import { LineItem } from '@commerce/types/cart'
import { Product } from '@commerce/types/product'
import { Price } from '@components/common'
import cn from 'classnames'
import { FC } from 'react'
import s from './Addon.module.css'
interface AddonPriceProps {
  product: Product | undefined
  lineItem: LineItem | null
  variantID: string | null
  prefix: boolean
  quantity: number
}

const AddonPrice: FC<AddonPriceProps> = ({ lineItem, product, variantID, prefix, quantity }) => {
  if (!product) return <></>
  let price = product.price.value
  let listPrice = product.listPrice.value
  const currencyCode = product.price.currencyCode

  if (lineItem) {
    price = lineItem.variant.price * lineItem.quantity
    listPrice = lineItem.variant.listPrice * lineItem.quantity
    if (lineItem.discounts.length > 0) {
      let discounts = 0
      lineItem.discounts.map((discount) => {
        price -= discount.allocatedAmount
        discounts += discount.allocatedAmount
      })
      if (lineItem.variant.listPrice) {
        let compareAtPrice = lineItem.variant.listPrice * lineItem.quantity
        let originalDiscount = compareAtPrice - lineItem.quantity * lineItem.variant.price
        let totalDiscount = originalDiscount + discounts
        // If an item has a compare at price value, we reduce the discounts to that value so the total savings matches any handmade calculations with the visuals in the mini cart
        listPrice = price + totalDiscount
      }
    }
  } else {
    if (variantID) {
      const selectedVariant = product.variants.find((variant) => {
        return variant.id === variantID
      })
      if (selectedVariant) {
        price = selectedVariant.price * quantity
        listPrice = selectedVariant.listPrice * quantity
      }
    }
  }
  return (
    <>
      {price > 0 ? (
        <Price
          className={cn(s.price)}
          price={price}
          listPrice={listPrice}
          prefix={prefix}
          currencyCode={currencyCode}
        />
      ) : (
        <div className={cn(s.price)}>Free</div>
      )}
    </>
  )
}

export default AddonPrice
