import { Product } from '@commerce/types'
import { getCurrenceCodeByLocale } from '@lib/locale'
import { EcommerceData, Product as ElevarProduct } from '@lib/types/Elevar'
import { ElevarEvents, getUserPropertiesFromSessionStorage } from './elevarEvents'
import { EVENT_DEBUG, eventLog, getDataLayerProductIdForAlgolia, getProductTieredBreadCrumb, infoLog } from './general'

declare global {
  interface Window {
    dataLayer: any
  }
}

const getImpressions = (products: Product.Hit[], type: String, locale?: string) => {
  return products.map((p, index: number) => {
    let breadcrumbs = []
    try {
      breadcrumbs = JSON.parse(p?.meta?.my_fields?.breadcrumbs)
    } catch (e) {
      // known error if data is missing or invalid
    }
    const id = getDataLayerProductIdForAlgolia(p, locale)
    return {
      name: p.title,
      id,
      brand: p.named_tags?.range,
      category: getProductTieredBreadCrumb(breadcrumbs),
      price: p.compare_at_price > p.price ? p.compare_at_price : p.price,
      dimension2: p.named_tags.type === 'bundle' ? 'Bundle' : 'Product', // Single Product or Bundle
      dimension3: p.named_tags.colourway, // Product Colour (comma separated if more than one) (if determined)
      dimension4: Array.isArray(p.named_tags.colour) ? p.named_tags.colour.join(',') : p.named_tags.colour,
      dimension5: p.options?.size, // Size (if determined - if not default to Queen (Sheets & Quilt Covers), Standard (Pillowcases)
      dimension6: Array.isArray(p.named_tags.fabric) ? p.named_tags.fabric.join(',') : p.named_tags.fabric, // Fabric (if determined)
      dimension7: p.compare_at_price > p.price ? 'Sale' : 'Full Price', // Price Category - 'Full Price', 'Sale' etc.	 (if determined)
      list: type, // 'Category Page' or 'Search Results'
      position: index + 1,
    }
  })
}

const getElevarProducts = (products: Product.Hit[], type: String, locale?: string): ElevarProduct[] => {
  return products.map((p, index: number) => {
    const id = getDataLayerProductIdForAlgolia(p, locale)
    return {
      id,
      name: p.title || "",
      brand: p.named_tags?.range?.replace(/-/g, ' ')?.toUpperCase() || "",
      category: p.product_type?.toString(),
      variant: p.variant_title || "",
      price: p.price?.toString(),
      position: index + 1,
      list: `/${locale}/collections/${p.named_tags?.range}`,
      product_id: p.id?.toString() || "",
      variant_id: p.objectID?.toString() || "",
      compare_at_price: p.compare_at_price?.toString() || "",
      image: p.image?.replace(/https?:\/\//, '//') || "",
      dimension2: p.product_type.toLowerCase()?.includes('bundle') ? 'Bundle' : 'Product', // Single Product or Bundle
      dimension3: p.named_tags.colourway, // Product Colour (comma separated if more than one) (if determined)
      dimension4: Array.isArray(p.named_tags.colour) ? p.named_tags.colour?.[0] : p.named_tags.colour,
      dimension5: p.variant_title || "", // Size (if determined - if not default to Queen (Sheets & Quilt Covers), Standard (Pillowcases)
      dimension6: Array.isArray(p.named_tags.fabric) ? p.named_tags.fabric.join(',') : p.named_tags.fabric, // Fabric (if determined)
      dimension7: p.compare_at_price > p.price ? 'Sale' : 'Full Price', // Price Category - 'Full Price', 'Sale' etc.	 (if determined)
    }
  })
}

export const productClick = (product: Product.Hit, type: string, locale?: string) => {
  if (EVENT_DEBUG && !window?.dataLayer) {
    infoLog('ProductListClick: No Data Layer found.')
  }
  const products = getImpressions([product], type, locale)
  if (window?.dataLayer) {
    const detail = {
      event: 'ecommerce',
      ecommerceEvent: 'Product List Click',
      ecommerceEventLabel: product.title,
      ecommerce: {
        currencyCode: getCurrenceCodeByLocale(locale),
        click: {
          actionField: {
            list: type,
          },
          products,
        },
      },
    }
    window?.dataLayer?.push(detail)
    if (EVENT_DEBUG) eventLog(`${type} Page 'gtm.ecommerce.ProductListClick (custom)`, { detail })
  }

  const elevarProducts = getElevarProducts([product], type, locale)
  const userProperties = getUserPropertiesFromSessionStorage()
  const ecommerceData: EcommerceData = {
    currencyCode: getCurrenceCodeByLocale(locale),
    click: {
      actionField: {
        list: `/${locale}/collections/${product.named_tags?.range}`,
        action: 'click'
      },
      products: elevarProducts
    }
  }
  ElevarEvents('dl_select_item', userProperties, ecommerceData)
}
