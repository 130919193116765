import { gql } from '@apollo/client'
import { Attribute } from '@commerce/schema'
import { LineItemAdd } from '@commerce/types/cart'
import { getCurrencyCountryCode } from '..'
import { checkoutDetailsFragment } from '../fragements'
import getCheckoutId from '../get-checkout-id'

const checkoutLineItemAddMutation = /* GraphQL */ gql`
  mutation checkoutLineItemAdd($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!, $country: CountryCode!)
  @inContext(country: $country) {
    checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkoutUserErrors {
        code
        field
        message
      }
      checkout {
        ...checkoutDetails
      }
    }
  }

  ${checkoutDetailsFragment}
`

type MutationVars = {
  locale: string | undefined
  variantId: string
  quantity: number
  customAttributes?: Array<Attribute>
}

export const checkoutAddLineItemVars = ({ locale, variantId, quantity, customAttributes }: MutationVars) => ({
  checkoutId: getCheckoutId(locale),
  lineItems: [
    {
      variantId,
      quantity,
      customAttributes,
    },
  ],
  country: getCurrencyCountryCode(locale),
})

export const checkoutAddMultipleLineItemVars = (lineItems: LineItemAdd[], locale: string | undefined) => ({
  checkoutId: getCheckoutId(locale),
  lineItems,
  country: getCurrencyCountryCode(locale),
})

export default checkoutLineItemAddMutation
