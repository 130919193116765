const AccountV2 = ({ color = '#000', ...props }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_8538_48146)">
        <g clipPath="url(#clip1_8538_48146)">
          <path
            d="M13 10.9165C12.5588 10.9165 12.2151 11.0224 11.8503 11.1348C11.799 11.1505 11.7474 11.1665 11.695 11.1822C11.2693 11.3103 10.7722 11.4373 10 11.4373C9.22777 11.4373 8.73158 11.3103 8.30628 11.1822C8.25428 11.1666 8.20301 11.1508 8.15215 11.1351C7.78711 11.0226 7.4431 10.9165 7 10.9165C4.44993 10.9165 2.375 12.9288 2.375 15.4165V16.7707C2.375 17.7056 3.15417 18.4582 4.10714 18.4582H15.8929C16.8458 18.4582 17.625 17.7056 17.625 16.7707V15.4165C17.625 12.9288 15.5501 10.9165 13 10.9165ZM16.3036 16.7707C16.3036 16.9848 16.1218 17.1665 15.8929 17.1665H4.10714C3.87819 17.1665 3.69643 16.9848 3.69643 16.7707V15.4165C3.69643 13.6496 5.17395 12.2082 7 12.2082C7.14783 12.2082 7.29932 12.2375 7.47509 12.2852C7.57223 12.3116 7.66852 12.341 7.77326 12.373C7.86598 12.4014 7.96532 12.4317 8.07775 12.4638C8.54246 12.5963 9.14019 12.729 10 12.729C10.8582 12.729 11.4559 12.5963 11.921 12.4638C12.0332 12.4318 12.1325 12.4015 12.2252 12.3732C12.3304 12.3411 12.427 12.3116 12.5244 12.2852C12.7004 12.2375 12.8522 12.2082 13 12.2082C14.826 12.2082 16.3036 13.6496 16.3036 15.4165V16.7707ZM10 10.1248C12.4329 10.1248 14.4107 8.20693 14.4107 5.83317C14.4107 3.45941 12.4329 1.5415 10 1.5415C7.56712 1.5415 5.58929 3.45941 5.58929 5.83317C5.58929 8.20693 7.56712 10.1248 10 10.1248ZM10 2.83317C11.7055 2.83317 13.0893 4.1835 13.0893 5.83317C13.0893 7.48284 11.7055 8.83317 10 8.83317C8.29449 8.83317 6.91071 7.48284 6.91071 5.83317C6.91071 4.1835 8.29449 2.83317 10 2.83317Z"
            fill={color}
            stroke={color}
            strokeWidth="0.25"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_8538_48146">
          <rect width="20" height="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_8538_48146">
          <rect width="16" height="18" fill="white" transform="translate(2 1)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AccountV2
