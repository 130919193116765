import { checkoutDetailsFragment } from '../fragements'
import { gql } from '@apollo/client'
import getCheckoutId from '../get-checkout-id'

const checkoutLineItemRemoveMutation = /* GraphQL */ gql`
  mutation checkoutLineItemRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
      checkoutUserErrors {
        code
        field
        message
      }
      checkout {
        ...checkoutDetails
      }
    }
  }
  ${checkoutDetailsFragment}
`

type MutationMultipleVars = {
  locale: string | undefined
  itemIds: string[]
}

export const checkoutRemoveLineItemsVars = ({ locale, itemIds }: MutationMultipleVars) => ({
  checkoutId: getCheckoutId(locale),
  lineItemIds: itemIds,
})

export default checkoutLineItemRemoveMutation
