import { useEffect, useState } from 'react'

export const useNavDataLayer = () => {
  const [deviceType, setDeviceType] = useState('')

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
      ; /Mobi/.test(navigator.userAgent) ? setDeviceType('mobile') : setDeviceType('desktop')
    }
  }, [])

  const updateNavDataLayer = (
    menuClickItem: string | undefined,
    menuClickSection: string | undefined,
    menuClickParent: string | undefined,
    menuClickUrl: string
  ) => {
    if (menuClickItem && menuClickSection && menuClickParent && menuClickUrl) {
      const data = {
        event: 'menuClick',
        menuClickDevice: deviceType,
        menuClickItem,
        menuClickSection,
        menuClickParent,
        menuClickUrl,
      }

      window?.dataLayer?.push(data)
    }
  }

  return {
    updateNavDataLayer,
  }
}
